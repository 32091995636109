import { DeviceTypes, type JsonGeometry, ModuleType } from '@/common/types';
import { ExtendedMap } from '@/generic/components/BasicMap';
import LuminaireIcon from '@/generic/components/LuminaireIcon';
import Map from '@/generic/components/Map';
import PopupBody from '@/generic/components/layout/PopupBody';
import BaseLayer from '@/generic/layers/BaseLayer';
import GeometryLayer from '@/generic/layers/GeometryLayer';
import type TypedFeature from '@/generic/layers/TypedFeature';
import type {
  FloorMapFeaturesQuery,
  OrganizationOverviewQuery,
} from '@/graphql/types';
import { beaconStyle } from '@/pages/AdminView/BuildingView/components/FloorList/components/FloorRoomView/components/FloorRoomMap/mapElements';
import { getOutlinedBeacons } from '@/pages/AdminView/BuildingView/components/FloorList/components/FloorRoomView/components/FloorRoomMap/utils/helpers';
import { getStatus } from '@/pages/ReportingView/components/DefectiveBeacons/components/ReportingMap';
import { FormattedMessage } from '@/translations/Intl';
import getColor from '@/utils/getColor';
import { TooltipWithBounds } from '@visx/tooltip';
import { unByKey } from 'ol/Observable';
import type { Geometry, Polygon } from 'ol/geom';
import type Point from 'ol/geom/Point';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import Text from 'ol/style/Text';
import { useEffect, useMemo, useState } from 'react';

type Beacons =
  OrganizationOverviewQuery['Buildings'][number]['Floors'][number]['MqttBeacons'][number];

type BeaconsType = TypedFeature<Beacons, Point>;

type ReportingMapProps = {
  floor: OrganizationOverviewQuery['Buildings'][number]['Floors'][number];
};

export default function ReportingMap({ floor }: ReportingMapProps) {
  const [map] = useState(new ExtendedMap());
  const [baseLayer] = useState(new BaseLayer());
  const [beaconsLayer] = useState(
    new GeometryLayer<FloorMapFeaturesQuery['MqttBeacons'][number], Point>({
      style: (
        feat,
        _,
        hoveredFeature,
        showLabel,
        selectedFeatureId,
        showClimateSensors,
      ) =>
        beaconStyle(
          feat,
          true,
          showLabel,
          hoveredFeature,
          selectedFeatureId,
          showClimateSensors,
        ),
    }),
  );
  const [beaconsModuleLayer] = useState(
    new GeometryLayer<FloorMapFeaturesQuery['MqttBeacons'][number], Point>({
      style: (
        feat,
        _,
        hoveredFeature,
        showLabel,
        selectedFeatureId,
        showClimateSensors,
      ) =>
        beaconStyle(
          feat,
          false,
          showLabel,
          hoveredFeature,
          selectedFeatureId,
          showClimateSensors,
        ),
    }),
  );
  const [outlineLayer] = useState(
    new GeometryLayer<{ Geometry: JsonGeometry }, Geometry | Polygon>({
      name: 'outline',
      style: (feature) => {
        const geometry = feature.getGeometry()?.clone() as Geometry;

        return [
          new Style({
            geometry,
            stroke: new Stroke({
              color: getColor('NEUTRAL600'),
              width: 2,
            }),
          }),
        ];
      },
    }),
  );
  const [desksLayer] = useState(
    new GeometryLayer<FloorMapFeaturesQuery['Desks'][number], Point>({
      style: (
        feat,
        resolution,
        hoveredFeature,
        showLabel,
        selectedFeatureId,
      ) =>
        feat.getProperties().Sensor?.MqttBeacon.Id === (selectedFeatureId ?? 0)
          ? // Hide desks when they are being moved
            undefined
          : [
              new Style({
                image: new Circle({
                  radius: feat.getProperties().Radius / resolution,
                  fill: new Fill({
                    color: getColor(
                      'PRIMARY400',
                      feat === hoveredFeature ? '.9' : '.5',
                    ),
                  }),
                  stroke: new Stroke({
                    color: getColor('PRIMARY400', '.5'),
                    width: 3,
                  }),
                }),
                text: showLabel
                  ? new Text({
                      text: feat.getProperties().Sensor.Index.toString(),
                      font: 'bold 15px Calibri,sans-serif',
                    })
                  : undefined,
              }),
            ],
    }),
  );

  const [layers] = useState([
    baseLayer,
    beaconsLayer,
    beaconsModuleLayer,
    desksLayer,
    outlineLayer,
  ]);
  const [hoveredFeature, setHoveredFeature] = useState<BeaconsType | null>(
    null,
  );

  const smartModules = useMemo(
    () =>
      floor.MqttBeacons.filter((b) =>
        b.Sensors.find((s) =>
          Object.values(ModuleType).includes(s.SensorType.Name as ModuleType),
        ),
      ),
    [floor.MqttBeacons],
  );

  const beacons = useMemo(
    () =>
      floor.MqttBeacons.filter(
        (b) => !smartModules.map((b) => b.Id).includes(b.Id),
      ),
    [floor.MqttBeacons, smartModules],
  );

  useEffect(() => {
    if (floor.Image) {
      baseLayer.setImage(floor.Image);
    }
  }, [baseLayer, floor.Image]);

  useEffect(() => beaconsLayer.setFeatures(beacons), [beacons, beaconsLayer]);

  useEffect(
    () => beaconsModuleLayer.setFeatures(smartModules),
    [smartModules, beaconsModuleLayer],
  );

  // Using a listener on desks as the outline layer depends on the desks being added
  useEffect(() => {
    const listener = desksLayer.olLayer.on('change', () => {
      if (
        desksLayer.getFeatures().length > 0 &&
        outlineLayer.getFeatures().length === 0
      ) {
        outlineLayer.setFeatures(
          getOutlinedBeacons(beacons, desksLayer.getFeatures()),
        );
      }
    });

    return () => {
      unByKey(listener);
    };
  }, [outlineLayer, desksLayer, beacons]);

  useEffect(
    () =>
      desksLayer.setFeatures(
        beacons.flatMap((b) => b.Sensors.map((s) => s.Desk)).filter((d) => !!d),
      ),
    [desksLayer, beacons],
  );

  useEffect(() => {
    desksLayer.setShowLabel(true);
    desksLayer.olLayer.changed();
  }, [desksLayer]);

  useEffect(() => {
    beaconsLayer.setShowClimateSensors(true);
    beaconsLayer.olLayer.changed();
    beaconsModuleLayer.setShowClimateSensors(true);
    beaconsModuleLayer.olLayer.changed();
  }, [beaconsLayer, beaconsModuleLayer]);

  return (
    <Map<BeaconsType>
      enableZoomButton={false}
      map={map}
      className="h-96"
      layers={layers}
      onFeaturesHover={(hoveredFeatures) => {
        const [feat] = hoveredFeatures.map((hF) => hF.feature);

        if (feat?.getProperties().__typename === 'MqttBeacons') {
          beaconsLayer.hoveredFeature = feat;
          beaconsLayer.olLayer.changed();
          beaconsModuleLayer.hoveredFeature = feat;
          beaconsModuleLayer.olLayer.changed();
          setHoveredFeature(feat);
        } else {
          setHoveredFeature(null);
          beaconsLayer.hoveredFeature = undefined;
          beaconsLayer.olLayer.changed();
          beaconsModuleLayer.hoveredFeature = undefined;
          beaconsModuleLayer.olLayer.changed();
        }
      }}
      renderTooltip={(props) => {
        if (!hoveredFeature) return undefined;

        return (
          <TooltipWithBounds {...props} data-test-id="reporting-map-tooltip">
            <PopupBody
              title={hoveredFeature.getProperties().Name}
              renderIcon={() => (
                <div className="dark:bg-neutral-600 rounded-full relative size-fit">
                  <LuminaireIcon
                    device={{
                      deviceType:
                        (hoveredFeature.getProperties().DeviceType?.Name as
                          | DeviceTypes
                          | undefined) ?? DeviceTypes.NODE,
                      desksInUse:
                        hoveredFeature.getProperties().NumberOfAvailableDesks ??
                        0,
                    }}
                  />
                </div>
              )}
            >
              <div className="flex space-x-1">
                <div>
                  <FormattedMessage id="Status" />:
                </div>
                <div>
                  {hoveredFeature.getProperties().IsOffline ? (
                    <FormattedMessage id="Offline" />
                  ) : (
                    getStatus(
                      hoveredFeature.getProperties().StatusCode,
                      hoveredFeature.getProperties().DeviceType
                        ?.Name as DeviceTypes,
                    )
                  )}
                </div>
              </div>
            </PopupBody>
          </TooltipWithBounds>
        );
      }}
    />
  );
}
