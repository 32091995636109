import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';

import { RoomTypes } from '@/common/types';
import Loader from '@/generic/components/layout/BarLoader';
import type { OrganizationTopologyQuery } from '@/graphql/types';
import { Fragment, useState } from 'react';
import {
  FaDesktop,
  FaEye,
  FaEyeSlash,
  FaHandSparkles,
  FaLock,
  FaLockOpen,
} from 'react-icons/fa';
import { HiOutlineArrowDown, HiOutlineArrowUp } from 'react-icons/hi2';

import Tooltip from '../Tooltip';
import OverviewRow, {
  type BuildingNeeds,
  Order,
  Types,
  typeColor,
} from './OverviewRow';
import OverviewSkeleton from './OverviewSkeleton';

type DeskSensor = Exclude<
  OrganizationTopologyQuery['Buildings'][number]['Floors'][number]['Rooms'][number]['deskSensors'],
  undefined
>[number];

export const findValue = (object: BuildingNeeds, orderProperty: Types) =>
  object.floors.reduce(
    (a, b) =>
      a + (b.total.filter((t) => t.id === orderProperty)[0]?.value ?? 0),
    0,
  );

export const desksPerBuilding = (
  buildingsTopology?: OrganizationTopologyQuery,
) =>
  buildingsTopology?.Buildings.map((b) => ({
    id: b.Id,
    name: b.Name,
    floors: b.Floors.map((f) => {
      const deskRooms = f.Rooms.flatMap((room) => room?.deskSensors).filter(
        (s): s is DeskSensor => !!s,
      );

      return {
        id: f.Id,
        number: f.Number,
        places: deskRooms
          .map((deskRoom) => deskRoom.Sensor.Desk?.Id)
          .filter((value, index, array) => array.indexOf(value) === index)
          .length,
        placesMeeting: f.Rooms.filter(
          (r) => r.RoomType.Name === RoomTypes.MEETING,
        ).length,
        offline: [
          // Set to avoid duplicates per desk
          ...new Set(
            deskRooms
              .map((deskRoom) =>
                deskRoom.Sensor.MqttBeacon.IsOffline
                  ? deskRoom.Sensor.Desk?.Id
                  : false,
              )
              .filter((feat) => feat),
          ),
        ].length,
        offlineMeeting: f.Rooms.filter(
          (r) => r.RoomType.Name === RoomTypes.MEETING,
        )
          .map((r) =>
            r.roomSensors?.every((rs) => rs.Sensor.MqttBeacon.IsOffline),
          )
          .filter((r) => r).length,
        cleaningDuration: f.CleaningDuration,
      };
    }),
  })) ?? [];

const renderIcon = (type: Types) => {
  if (type === Types.UNUSED) {
    return <FaLockOpen className="size-5 mr-2" />;
  }
  if (type === Types.RESERVED) {
    return <FaLock className="size-5 mr-2" />;
  }
  if (type === Types.TOCLEAN) {
    return <FaHandSparkles className="size-5 mr-2" />;
  }
  if (type === Types.CHECK) {
    return <FaEye className="size-5 mr-2" />;
  }
  if (type === Types.CLEAN) {
    return <FaEyeSlash className="size-5 mr-2" />;
  }
  return <FaDesktop className="size-5 mr-2" />;
};

export const renderOrderIcon = (order: Order) =>
  order === Order.UP ? (
    <HiOutlineArrowUp className="size-5" />
  ) : (
    <HiOutlineArrowDown className="size-5" />
  );

interface OverviewProps {
  data: BuildingNeeds[];
  columns: Types[];
  pieChartColors: string[];
  title: IntlMessageKeys;
  defaultSorting?: Types;
  dataLoading?: boolean;
  map?: React.JSX.Element;
  noCard?: boolean;
}

export default function Overview({
  columns,
  pieChartColors,
  defaultSorting,
  data,
  title,
  dataLoading = false,
  map,
  noCard,
}: OverviewProps): React.JSX.Element {
  const [orderProperty, setOrderProperty] = useState<Types>(
    defaultSorting ?? Types.BUILDING,
  );
  const [order, setOrder] = useState<Order>(Order.DOWN);

  const setOrderPropertyValues = (type: Types) => {
    if (type === orderProperty) {
      setOrder(order === 1 ? Order.DOWN : Order.UP);
    } else {
      setOrderProperty(type);
      setOrder(Order.UP);
    }
  };

  return (
    <div
      key="building-cleaning-needs"
      className={`bg-primary-0 dark:bg-neutral-800 ${
        noCard ? 'md:-mx-6' : 'rounded-lg'
      } flex flex-col relative`}
    >
      <Loader isCard={!noCard} loading={dataLoading} />
      <div
        className={`flex flex-col w-full  ${
          noCard
            ? 'border-y border-dashed'
            : 'border rounded-lg border-neutral-200 dark:border-neutral-700'
        }`}
      >
        <div
          className={`w-full grid grid-cols-12 gap-2 ${noCard ? '' : ''} py-4`}
        >
          <div
            className={`col-span-2 ${
              noCard ? 'justify-start pl-6' : 'justify-center'
            } items-center flex`}
          >
            <p className="md:block hidden">
              <FormattedMessage id={title} />
            </p>
          </div>
          <div className="md:col-span-10 col-span-12 pl-2 md:pl-0 items-center grid grid-cols-5">
            <div className="col-span-2 flex">
              <button
                className="flex justify-center items-center col-span-1 cursor-pointer"
                onClick={() => setOrderPropertyValues(Types.BUILDING)}
                type="button"
              >
                <FormattedMessage id="buildings" />
                {orderProperty === Types.BUILDING && renderOrderIcon(order)}
              </button>
            </div>
            <div
              className={`col-span-3 grid grid-cols-${columns.length} justify-items-center`}
            >
              {columns.map((type) => (
                <Fragment key={type}>
                  {/* Mobile */}
                  <div className="block md:hidden">
                    <Tooltip
                      className="whitespace-pre w-min"
                      content={
                        <button
                          className={`flex justify-center items-center col-span-1 cursor-pointer ${typeColor(
                            type,
                          )}`}
                          onClick={() => setOrderPropertyValues(type)}
                          type="button"
                          key={type}
                        >
                          {renderIcon(type)}

                          {orderProperty === type && renderOrderIcon(order)}
                        </button>
                      }
                    >
                      <FormattedMessage id={type as IntlMessageKeys} />
                    </Tooltip>
                  </div>
                  {/* Desktop */}
                  <button
                    className={`hidden md:flex justify-center items-center col-span-1 cursor-pointer ${typeColor(
                      type,
                    )}`}
                    onClick={() => setOrderPropertyValues(type)}
                    type="button"
                    key={type}
                  >
                    {renderIcon(type)}
                    <FormattedMessage id={type as IntlMessageKeys} />

                    {orderProperty === type && renderOrderIcon(order)}
                  </button>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
        {data.length > 0 ? (
          <>
            {data
              .sort((a, b) => {
                if (orderProperty === Types.BUILDING) {
                  if (order === Order.DOWN) {
                    return b.name.localeCompare(a.name);
                  }
                  return a.name.localeCompare(b.name);
                }
                if (order === Order.DOWN) {
                  return (
                    findValue(b, orderProperty) - findValue(a, orderProperty)
                  );
                }
                return (
                  findValue(a, orderProperty) - findValue(b, orderProperty)
                );
              })
              .map((buildingData) => (
                <OverviewRow
                  key={buildingData.id}
                  order={order}
                  orderProperty={orderProperty}
                  data={buildingData}
                  columns={columns}
                  pieChartColors={pieChartColors}
                  map={map}
                  noCard={noCard}
                />
              ))}
          </>
        ) : (
          <OverviewSkeleton
            loading={dataLoading}
            numberOfColumns={columns.length}
            noCard={noCard}
          />
        )}
      </div>
    </div>
  );
}
