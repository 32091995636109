import useStore from 'model/store';
import { FormattedMessage } from 'translations/Intl';

import Select from '@/generic/components/Form/Select';
import type { RoomPartsFragment } from '@/graphql/types';

interface RoomSelectProps {
  rooms?: RoomPartsFragment[];
  isDeselectable?: boolean;
  onRoomSelect?: (r: RoomPartsFragment | null) => void;
  roomName?: string | null;
  onOptionHover?: (option: RoomPartsFragment | null, enter: boolean) => void;
}

export default function RoomSelect({
  rooms = [],
  isDeselectable,
  onRoomSelect,
  roomName,
  onOptionHover,
}: RoomSelectProps): React.JSX.Element {
  const room = useStore((state) => state.userSettings.room);
  const setRoom = useStore((state) => state.userApi.setRoom);

  return (
    <Select
      dataTestId="select-room"
      value={
        rooms.find((r) =>
          roomName !== undefined ? r.Name === roomName : r.Id === room?.Id,
        ) || null
      }
      label="Room"
      onOptionHover={onOptionHover}
      onChangeSelected={(selected) => {
        if (onRoomSelect) {
          onRoomSelect(selected);
        } else if (selected) {
          setRoom({
            Id: selected.Id,
            Name: selected.Name,
            RoomType: selected.RoomType,
          });
        } else {
          setRoom(undefined);
        }
      }}
      options={rooms}
      isDeselectable={isDeselectable}
      renderValue={(r) => r?.Name ?? ''}
      keyParameter="Id"
      defaultValue={<FormattedMessage id="All" />}
    />
  );
}
