import { type RefObject, useState } from 'react';
import { HiOutlineClipboard } from 'react-icons/hi2';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';
import Tooltip from '../Tooltip';

interface ClipboardButtonProps {
  elementRef: RefObject<HTMLElement | null>;
}

export default function ClipboardButton({ elementRef }: ClipboardButtonProps) {
  const [copyText, setCopyText] = useState<IntlMessageKeys>('Copy');

  return (
    <Tooltip
      content={
        <div>
          <HiOutlineClipboard
            onClick={() => {
              navigator.clipboard.writeText(
                elementRef.current?.innerHTML ?? '',
              );
              setCopyText('Copied');
            }}
            onMouseOver={() => setCopyText('Copy')}
            className="size-5 cursor-pointer hover:text-primary-500 transition-colors"
          />
        </div>
      }
    >
      <FormattedMessage id={copyText} />
    </Tooltip>
  );
}
