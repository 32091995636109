import { RAINBOW } from '@/constants';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Line, Polygon } from '@visx/shape';
import { type MarginProps, Themes } from 'common/types';
import { motion } from 'framer-motion';
import AnimatedRect from 'generic/components/Chart/AnimatedRect';
import Axis from 'generic/components/Chart/Axis';
import useStore from 'model/store';
import { AnimatedLine } from 'pages/AnalyticsView/components/BrushCart/Components/AreaChart';
import { useIntl } from 'translations/Intl';
import getColor, { primaryColorToRGB } from 'utils/getColor';
import { REPORTING_RIGHT_MARGIN } from '../../Reports';

interface ResponsiveBarChartProps<T> {
  margin?: MarginProps;
  data: T[];
  colors?: string[];
  yScaleAccessor: (d: T) => number;
  groupAccessor: (d: T) => string;
  y1Accessor?: (d: T) => number;
  y2Accessor?: (d: T) => number;
  averageValue?: number;
  averageLabel?: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
}

interface BarChartProps<T> extends ResponsiveBarChartProps<T> {
  height: number;
  width: number;
}

function BarChart<T>({
  height,
  width,
  margin = {
    top: 40,
    left: 70,
    right: REPORTING_RIGHT_MARGIN,
    bottom: 50,
  },
  data,
  colors,
  yScaleAccessor,
  groupAccessor,
  y1Accessor,
  y2Accessor,
  averageValue,
  averageLabel,
  xAxisLabel,
  yAxisLabel,
}: BarChartProps<T>) {
  const intl = useIntl();
  const theme = useStore((state) => state.userSettings.theme);
  const groups = data.map(groupAccessor);
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  const colorScale = scaleOrdinal({
    domain: groups,
    range: colors ?? RAINBOW,
  });

  const xScale = scaleBand<string>({
    range: [0, xMax],
    domain: groups,
    paddingInner: 0.2,
  });

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [0, 100],
    nice: true,
  });

  const variants = {
    initial: {
      fill: '#fff',
      pathLength: 0,
    },
    animate: {
      transition: {
        ease: 'easeInOut',
        duration: 1,
      },
      pathLength: 1,
    },
  };

  const shapeScale = scaleOrdinal<string, React.FC | React.ReactNode>({
    domain: [
      y1Accessor ? intl.formatMessage({ id: 'Max hourly occupancy' }) : '',
      y2Accessor ? intl.formatMessage({ id: 'Max daily occupancy' }) : '',
      averageValue
        ? (averageLabel ??
          intl.formatMessage({ id: 'Average daily occupancy' }))
        : '',
    ].filter((f) => f !== ''),
    range: [
      y1Accessor && (
        <Polygon
          key={intl.formatMessage({ id: 'Max hourly occupancy' })}
          sides={6}
          center={{ x: 6, y: 7 }}
          rotate={90}
          size={6}
          fill={primaryColorToRGB(500, 1)}
        />
      ),
      y2Accessor && (
        <Polygon
          key={intl.formatMessage({ id: 'Max daily occupancy' })}
          sides={4}
          center={{ x: 6, y: 7 }}
          rotate={90}
          size={6}
          fill={primaryColorToRGB(500, 1)}
        />
      ),
      averageValue && (
        <Line
          key={
            averageLabel ??
            intl.formatMessage({ id: 'Average daily occupancy' })
          }
          from={{ x: 0, y: 8 }}
          to={{ x: 10, y: 8 }}
          strokeWidth={2}
          stroke={
            theme.color === Themes.LIGHT
              ? getColor('NEUTRAL600')
              : getColor('NEUTRAL300')
          }
        />
      ),
    ].filter(Boolean),
  });

  return (
    <div className="relative">
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            numTicks={10}
            scale={yScale}
            width={xMax}
            height={yMax}
            strokeDasharray="1,3"
            stroke={getColor('NEUTRAL600')}
            strokeOpacity={0.6}
          />
          <g>
            {data.map((d) => {
              const group = groupAccessor(d);
              const barWidth = xScale.bandwidth();
              const barHeight = yMax - (yScale(yScaleAccessor(d)) ?? 0);
              const barX = xScale(group) ?? 0;
              const barY = yMax - barHeight;
              return (
                <g key={`bar-${group}`}>
                  <AnimatedRect
                    bar={{
                      width: barWidth,
                      height: barHeight,
                      y: barY,
                      x: barX,
                      color: colorScale(group),
                    }}
                  />
                  {y1Accessor && (
                    <Polygon
                      sides={4}
                      center={{
                        x: barX + barWidth / 2,
                        y: yScale(y1Accessor(d)),
                      }}
                      rotate={90}
                      size={6}
                    >
                      {({ points }) => (
                        <motion.polygon
                          initial="initial"
                          animate="animate"
                          variants={{
                            initial: variants.initial,
                            animate: {
                              fill: colorScale(group),
                              ...variants.animate,
                            },
                          }}
                          points={`${points}`}
                          fillOpacity={0.8}
                        />
                      )}
                    </Polygon>
                  )}
                  {y2Accessor && (
                    <Polygon
                      sides={6}
                      center={{
                        x: barX + barWidth / 2,
                        y: yScale(y2Accessor(d)),
                      }}
                      rotate={90}
                      size={6}
                    >
                      {({ points }) => (
                        <motion.polygon
                          initial="initial"
                          animate="animate"
                          variants={{
                            initial: variants.initial,
                            animate: {
                              fill: colorScale(group),
                              ...variants.animate,
                            },
                          }}
                          points={`${points}`}
                          fillOpacity={0.8}
                        />
                      )}
                    </Polygon>
                  )}
                </g>
              );
            })}
            {averageValue && (
              <AnimatedLine xMax={xMax} y={yScale(averageValue)} />
            )}
          </g>
          <Axis
            lowLevelChart
            orientation="left"
            scale={yScale}
            tickFormat={(y) => `${y}%`}
            label={yAxisLabel ?? intl.formatMessage({ id: 'Occupancy' })}
          />
          {xAxisLabel && (
            <Axis
              lowLevelChart
              orientation="bottom"
              top={yMax}
              scale={xScale}
              label={xAxisLabel}
            />
          )}
        </Group>
      </svg>
      <div className="flex items-center justify-evenly absolute top-0 w-full space-y-2">
        <LegendOrdinal
          direction="row"
          labelMargin="0 15px 0 0"
          scale={shapeScale}
        >
          {(groups) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {groups.map((label, i) => {
                const shape = shapeScale(label.datum);
                return (
                  <LegendItem margin="0 5px" key={`legend-quantile-${i}`}>
                    <svg width="16" height="16" viewBox="0 0 16 16">
                      {shape as React.ReactElement}
                    </svg>
                    <LegendLabel align="left" className="text-xs">
                      {label.text}
                    </LegendLabel>
                  </LegendItem>
                );
              })}
            </div>
          )}
        </LegendOrdinal>
      </div>
    </div>
  );
}

export default function ResponsiveBarChart<T>(
  props: ResponsiveBarChartProps<T>,
) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <BarChart {...props} height={height} width={width} />
      )}
    </ParentSize>
  );
}
