import Accordion from 'generic/components/Accordion';
import { FormattedMessage } from 'translations/Intl';

import {
  type SensorsStatusQuery,
  useSensorsStatusQuery,
} from '@/graphql/types';
import useStore from '@/model/store';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import usePolling from '@/utils/graphql/usePolling';
import { useMemo, useState } from 'react';

import RemoveSensorModal from '../../../RemoveSensorModal';
import SensorCard from './components/SensorCard';

interface SensorsStatusProps {
  beaconName: string;
}

export default function SensorsStatus({ beaconName }: SensorsStatusProps) {
  const hasuraHeader = useHasuraHeader();
  const userRoles = useStore((state) => state.user)?.roles;

  const [isDeleteLoading, setIsDeleteLoading] = useState<number | null>(null);
  const [sensorToRemove, setSensorToRemove] = useState<
    SensorsStatusQuery['Sensors'][number] | null
  >(null);

  const [{ data: sensors, fetching: loadingSensors }, reexecuteQuery] =
    useSensorsStatusQuery({
      variables: {
        BeaconName: beaconName,
      },
      context: useMemo(
        () =>
          hasuraHeader(
            userRoles?.includes(HasuraPermissions.READ_ALL)
              ? HasuraPermissions.READ_ALL
              : HasuraPermissions.READ,
          ),
        [hasuraHeader, userRoles],
      ),
    });
  usePolling(loadingSensors, reexecuteQuery, 1000 * 5);

  return (
    <>
      {loadingSensors && !sensors && (
        <div className="flex max-width-screen gap-4 py-4">
          {[1, 2].map((idx) => (
            <div
              key={`sensor-loading-${idx}`}
              className="w-1/3 animate-pulse flex"
            >
              <div className="w-full bg-neutral-200 h-24 rounded-md" />
            </div>
          ))}
        </div>
      )}
      {(sensors?.Sensors ?? []).length > 0 && (
        <div className="flex flex-col max-width-screen">
          <Accordion initialStateOpen title={<FormattedMessage id="Sensors" />}>
            <div
              data-test-id={`sensor-card-wrapper-${beaconName}`}
              className="w-full grid grid-cols-2 gap-4"
            >
              {sensors?.Sensors.sort(
                (a, b) =>
                  a.SensorType.Name.localeCompare(b.SensorType.Name) ||
                  a.Index - b.Index,
              ).map((sensor) => {
                return (
                  <SensorCard
                    key={sensor.Id}
                    isDeleteLoading={isDeleteLoading}
                    sensor={sensor}
                    setSensorToRemove={setSensorToRemove}
                  />
                );
              })}
            </div>
          </Accordion>
        </div>
      )}
      <RemoveSensorModal
        beaconName={beaconName}
        sensorToRemove={sensorToRemove}
        setSensorToRemove={setSensorToRemove}
        setIsDeleteLoading={setIsDeleteLoading}
      />
    </>
  );
}
