import { LS_SHOW_ROOM_SENSOR } from '@/constants';
import useStore from '@/model/store';
import {
  ModuleType,
  isAssetAccuracyFeature,
  isAssetFeature,
  isDeskInUseFeature,
  isRoomFeature,
} from 'common/types';
import { subDays } from 'date-fns';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import type { AccuracyFeatureType } from 'generic/layers/AccuracyLayer';
import type { AssetFeatureType } from 'generic/layers/AssetLayer';
import type { DeskInUseFeatureType } from 'generic/layers/DeskInUseLayer';
import type { RoomBeaconFeatureType } from 'generic/layers/RoomBeaconLayer';
import type { RoomInUseFeatureType } from 'generic/layers/RoomInUseLayer';
import { useRoomOccupancySensorsQuery } from 'graphql/types';
import ThresholdChart from 'pages/FindMyPlaceView/components/OccupancyMap/components/OccupancyMapPopupBody/components/ThresholdChart';
import { useEffect, useMemo, useState } from 'react';
import { HiOutlineLockClosed } from 'react-icons/hi2';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';
import { endOfDayUTC, startOfDayUTC } from 'utils/date';
import usePolling from 'utils/graphql/usePolling';
import { uuidv4 } from 'utils/uuid';
import AssetPopup from '../AssetPopup';
import OccupancyPopup from '../OccupancyPopup';

export type OccupancyMapFeature =
  | RoomBeaconFeatureType
  | AssetFeatureType
  | AccuracyFeatureType
  | DeskInUseFeatureType
  | RoomInUseFeatureType;

interface OccupancyMapPopupBodyProps {
  hoveredFeature: OccupancyMapFeature;
  isTouchedEvt: boolean;
  setHoveredFeature: (feat: OccupancyMapFeature | null) => void;
  openReservationPopup: (
    feat: RoomBeaconFeatureType | DeskInUseFeatureType | RoomInUseFeatureType,
  ) => void;
}

function LoadingSkeleton() {
  return (
    <div className="flex flex-col space-y-2 animate-pulse">
      <div className="h-4 w-12 bg-neutral-200 rounded-md " />
      <div className="flex flex-col space-y-1">
        <div className="flex items-center">
          <div className="h-4 w-12 bg-neutral-200 rounded-md " />
          <div className="ml-2 w-20 bg-neutral-200 h-4 rounded-md" />
        </div>
        <div className="flex items-center">
          <div className="h-4 w-12 bg-neutral-200 rounded-md " />
          <div className="ml-2 w-20 bg-neutral-200 h-4 rounded-md" />
        </div>
      </div>
    </div>
  );
}

export default function OccupancyMapPopupBody({
  hoveredFeature,
  isTouchedEvt,
  setHoveredFeature,
  openReservationPopup,
}: OccupancyMapPopupBodyProps) {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [start] = useState(subDays(new Date(), 7));
  const [end] = useState(new Date());
  const warmMinutesPolicy = useStore(
    (state) => state.organizationSettings.warmMinutesPolicy,
  );
  const [showRoomSensor] = useState(
    localStorage.getItem(LS_SHOW_ROOM_SENSOR) === 'true',
  );

  const roomId = useMemo(() => {
    if (isRoomFeature(hoveredFeature)) {
      return hoveredFeature.getProperties().Id;
    }
    return undefined;
  }, [hoveredFeature]);

  const [{ data: roomSensorData, fetching }, reexecuteQuery] =
    useRoomOccupancySensorsQuery({
      variables: {
        // Sometimes pause doesn't work and there is an error because roomId is a null value
        RoomId: roomId ?? 0,
        Start: startOfDayUTC(start),
        End: endOfDayUTC(end),
      },
      pause: !roomId || !showRoomSensor,
    });

  // Only show loading indicator on first fetch and not on polling
  useEffect(() => {
    if (!fetching) {
      setDataLoaded(true);
    }
  }, [fetching]);

  // Refresh the data frequently in order to stay in sync. Not using a subscription as it takes
  // too long to load
  usePolling(fetching, reexecuteQuery, 5 * 1000);

  const roomSensors = useMemo(
    () =>
      showRoomSensor
        ? roomSensorData?.MqttBeacons.flatMap((mb) =>
            mb.Sensors.map((s) => ({
              id: uuidv4(),
              beacon: mb.Name,
              value: s.Value,
              index: s.Index,
              sensorType: s.SensorType.Name,
            })),
          )
        : undefined,
    [roomSensorData?.MqttBeacons, showRoomSensor],
  );

  const lineTypes = [
    ModuleType.LINECOUNT,
    ModuleType.LINECOUNT_IN,
    ModuleType.LINECOUNT_OUT,
  ];
  const notLineRoomSensors = roomSensors?.filter(
    (rs) => !(lineTypes as string[]).includes(rs.sensorType),
  );
  const lineRoomSensors = roomSensors?.filter((rs) =>
    (lineTypes as string[]).includes(rs.sensorType),
  );

  return isAssetFeature(hoveredFeature) ||
    isAssetAccuracyFeature(hoveredFeature) ? (
    <AssetPopup hoveredFeature={hoveredFeature} />
  ) : (
    <OccupancyPopup
      hoveredFeature={hoveredFeature}
      warmMinutesPolicy={warmMinutesPolicy}
      additionalContent={
        <>
          {showRoomSensor && isRoomFeature(hoveredFeature) && (
            <div className="flex flex-col">
              {!dataLoaded ? (
                <LoadingSkeleton />
              ) : (
                <>
                  <div className="font-bold">
                    <FormattedMessage id="Sensors" />
                  </div>
                  <div>
                    {notLineRoomSensors?.map((rs) => (
                      <p key={rs.id}>
                        <FormattedMessage
                          id={rs.sensorType as IntlMessageKeys}
                        />
                        {rs.index > 0 ? ` ${rs.index}` : undefined}: {rs.value}{' '}
                        ({rs.beacon})
                      </p>
                    ))}
                  </div>
                  {lineRoomSensors && lineRoomSensors.length > 0 && (
                    <div
                      className={
                        notLineRoomSensors && notLineRoomSensors.length > 0
                          ? 'border-t-2 border-neutral-300 dark:border-neutral-700 pt-1'
                          : ''
                      }
                    >
                      {lineRoomSensors.map((rs) => (
                        <p key={rs.id}>
                          <FormattedMessage
                            id={rs.sensorType as IntlMessageKeys}
                          />
                          {rs.index > 0 ? ` ${rs.index}` : undefined}:{' '}
                          {rs.value} ({rs.beacon})
                        </p>
                      ))}
                    </div>
                  )}
                  {roomSensors?.find((rs) =>
                    [...lineTypes, ModuleType.AREACOUNT].includes(
                      rs.sensorType as ModuleType,
                    ),
                  ) && (
                    <>
                      <div className="font-bold">
                        <FormattedMessage id="Occupancy" />
                      </div>
                      <div className="relative h-48 w-96">
                        <ThresholdChart
                          data={roomSensorData?.f_history_room_occupancy_15m}
                          loading={fetching}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {isTouchedEvt &&
            (isRoomFeature(hoveredFeature) ||
              isDeskInUseFeature(hoveredFeature)) && (
              <StyledButton
                data-test-id="reserve-button"
                className="my-2 w-fit"
                onClick={() => {
                  openReservationPopup(hoveredFeature);
                  setHoveredFeature(null);
                }}
              >
                <div className="flex items-center justify-center">
                  <HiOutlineLockClosed className="mr-2 size-4" />
                  <div>
                    <FormattedMessage id="Reserve it" />
                  </div>
                </div>
              </StyledButton>
            )}
        </>
      }
    />
  );
}
