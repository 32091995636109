import {
  LegendItem,
  LegendLabel,
  LegendLinear,
  LegendOrdinal,
} from '@visx/legend';
import type { LegendProps as VXLegendProps } from '@visx/legend/lib/legends/Legend';
import type { AnyD3Scale } from '@visx/scale';

interface LegendProps extends VXLegendProps<AnyD3Scale> {
  scaleType: 'ordinal' | 'linear';
  loading?: boolean;
}

interface LabelProps {
  value: string;
  text: string;
}

export default function Legend(props: LegendProps): React.JSX.Element {
  const { scaleType, loading } = props;
  const LegendType: React.ElementType =
    scaleType === 'ordinal' ? LegendOrdinal : LegendLinear;

  return (
    <LegendType direction="row" labelMargin="0 15px 0 0" {...props}>
      {(labels: LabelProps[]) =>
        labels.map((label) => (
          <LegendItem key={`legend-quantile-${label.value}`} margin="0 5px">
            {loading ? (
              <div className="animate-pulse bg-neutral-200 size-4 rounded-full" />
            ) : (
              <svg width="16" height="16" viewBox="0 0 16 16">
                <circle
                  fill={label.value}
                  r={16 / 2 - 1}
                  cx={16 / 2}
                  cy={16 / 2}
                  stroke="#E5E7EB"
                  strokeWidth="1"
                />
              </svg>
            )}
            <LegendLabel
              data-test-id="label-legend"
              align="left"
              margin="0 0 0 4px"
              className="text-xs"
            >
              {label.text}
            </LegendLabel>
          </LegendItem>
        ))
      }
    </LegendType>
  );
}
