import useStore from 'model/store';
import { FormattedMessage, useIntl } from 'translations/Intl';

import { RoomTypes } from '@/common/types';
import {
  LS_HIDE_CANCELLED_RESERVATIONS,
  LS_SHOW_PREVIOUS_RESERVATIONS,
  LS_SHOW_UPCOMING_RESERVATIONS,
} from '@/constants';
import Button from '@/generic/components/Form/Button';
import StyledButton from '@/generic/components/Form/Button/StyledButton';
import Subtitle from '@/generic/components/Subtitle';
import Transition from '@/generic/components/Transition';
import {
  type BuildingPartsFragment,
  type FloorPartsFragment,
  useCancelUserDeskReservationMutation,
  useCancelUserRoomReservationMutation,
  useUserReservationsQuery,
} from '@/graphql/types';
import { lower, upper } from '@/utils/date';
import format from '@/utils/format';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useRoomDeskFilter from '@/utils/graphql/useRoomDeskFilter';
import useToast from '@/utils/graphql/useToast';
import { isSameDay } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { FaChalkboardTeacher, FaMapMarkerAlt } from 'react-icons/fa';
import {
  HiClock,
  HiOutlineBuildingOffice,
  HiOutlineChevronDown,
  HiOutlineChevronRight,
  HiOutlineCube,
  HiXCircle,
} from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';

interface Event {
  id: number;
  title: string;
  buttons: null | React.JSX.Element;
  start: Date;
  end: Date;
  cancelled: boolean;
  location: string | React.JSX.Element;
  isDesk: boolean;
}

interface EventListProps {
  events: Event[];
  dataTestId: string;
  loading?: boolean;
}

function Icon({ isDesk, cancelled }: { isDesk: boolean; cancelled: boolean }) {
  return (
    <div
      className={`size-8 md:h-9 md:w-9 focus:outline-none rounded-full flex justify-center items-center ${
        cancelled
          ? 'bg-neutral-200 text-neutral-400'
          : 'bg-primary-200 text-primary-500'
      }`}
    >
      {isDesk ? (
        <FaChalkboardTeacher className="size-5 md:h-6 md:w-6" />
      ) : (
        <HiOutlineCube className="size-5 md:h-6 md:w-6" />
      )}
    </div>
  );
}

function EventSkeleton() {
  return (
    <div className="w-full animate-pulse flex items-center group-hover:bg-neutral-50 dark:group-hover:bg-neutral-700 space-x-2">
      <div className="size-10 bg-neutral-200 rounded-full " />
      <div className="flex flex-col space-y-2">
        <div className="w-40 bg-neutral-200 h-4 rounded-md" />
        <div className="w-60 bg-neutral-200 h-4 rounded-md" />
        <div className="flex space-x-2">
          <div className="w-28 bg-neutral-200 h-6 rounded-md" />
          <div className="w-28 bg-neutral-200 h-6 rounded-md" />
        </div>
      </div>
    </div>
  );
}

function EventList({ events, dataTestId, loading }: EventListProps) {
  if (loading) {
    return <EventSkeleton />;
  }

  if (events.length > 0) {
    return (
      <div data-test-id={dataTestId}>
        {events.map((evt) => {
          const todayReservation = isSameDay(evt.start, new Date());
          const sameDayReservation = isSameDay(evt.start, evt.end);
          const reservedNow = evt.start < new Date() && evt.end > new Date();
          return (
            <div
              key={`reservation-${evt.id}`}
              className={`flex items-center justify-center py-2 px-2 gap-4 ${
                reservedNow ? 'bg-primary-100 rounded text-neutral-700' : ''
              } border-b-2 last:border-b-0 border-neutral-200`}
            >
              <div className="hidden md:block">
                <Icon isDesk={evt.isDesk} cancelled={evt.cancelled} />
              </div>
              <div className="flex flex-col gap-2 grow">
                <div
                  className={`flex items-center text-sm font-bold gap-2 ${
                    evt.cancelled ? 'line-through' : ''
                  }`}
                >
                  <div className="block md:hidden">
                    <Icon isDesk={evt.isDesk} cancelled={evt.cancelled} />
                  </div>
                  <div className="flex flex-col grow md:flex-row md:items-center">
                    <div className="w-full">{evt.title}</div>
                  </div>
                </div>
                <div className="px-1 md:px-0 gap-2 text-sm flex flex-col md:flex-row md:divide-x">
                  <div
                    className={`${
                      reservedNow ? 'dark:text-neutral-500' : ''
                    } flex items-center text-neutral-500 dark:text-neutral-200`}
                  >
                    <HiClock className="size-5 mr-2" />
                    {todayReservation && <FormattedMessage id="Today" />}{' '}
                    {format(
                      evt.start,
                      sameDayReservation && todayReservation ? 'p' : 'PPp',
                    )}{' '}
                    - {format(evt.end, sameDayReservation ? 'p' : 'PPp')}
                    {reservedNow && (
                      <span className="bg-primary-200 text-primary-500 flex items-center text-xs rounded py-1 px-2 ml-2">
                        <FormattedMessage id="Now" />
                      </span>
                    )}
                  </div>
                  <div
                    className={`${
                      reservedNow ? 'dark:text-neutral-500' : ''
                    } md:pl-2 flex items-center text-neutral-500 dark:text-neutral-200`}
                  >
                    <HiOutlineBuildingOffice className="size-5 mr-2" />
                    {evt.location}
                  </div>
                </div>
                <div className="flex items-center text-xs space-x-2">
                  {evt.buttons}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return <FormattedMessage id="No reservations" />;
}

export default function ReservationsAgenda() {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useMemo(() => new URLSearchParams(), []);
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const roomTypes = useStore((state) => state.userSettings.roomTypes);
  const setBuilding = useStore((state) => state.userApi.setBuilding);
  const setFloor = useStore((state) => state.userApi.setFloor);
  const [{ data, fetching: loading }] = useUserReservationsQuery({
    variables: {
      DeskFilter: { IsPersonal: { _eq: true } },
      RoomFilter: { IsPersonal: { _eq: true } },
      ShowSelectedDesk: false,
      ShowSelectedRoom: false,
      ...useRoomDeskFilter(),
    },
  });
  const showCancelledEvents = localStorage.getItem(
    LS_HIDE_CANCELLED_RESERVATIONS,
  )
    ? localStorage.getItem(LS_HIDE_CANCELLED_RESERVATIONS) === 'true'
    : true;

  const [showUpcoming, setShowUpcoming] = useState(
    localStorage.getItem(LS_SHOW_UPCOMING_RESERVATIONS)
      ? localStorage.getItem(LS_SHOW_UPCOMING_RESERVATIONS) === 'true'
      : true,
  );
  const [showPrevious, setShowPrevious] = useState(
    localStorage.getItem(LS_SHOW_PREVIOUS_RESERVATIONS) === 'true',
  );

  const [, cancelDeskReservation] = useCancelUserDeskReservationMutation();
  const [, cancelRoomReservation] = useCancelUserRoomReservationMutation();

  const renderButtons = useCallback(
    (
      id: number,
      cancelled: boolean,
      endDate: Date,
      floor: FloorPartsFragment,
      building: BuildingPartsFragment,
      name: string,
      room = false,
    ) =>
      // Do not allow cancelling events in the past
      !cancelled && new Date() <= endDate ? (
        <>
          <StyledButton
            className="py-1 px-1 md:py-1 md:px-2 flex items-center"
            onClick={() => {
              setBuilding(building);
              setFloor(floor);

              params.set('building', building.Name);
              params.set('floor', floor.Number.toString());

              if (room) {
                params.set('room', name);
              } else {
                params.set('desk', name);
              }

              navigate({
                pathname: '/findmyplace',
                search: params.toString(),
              });
            }}
          >
            <FaMapMarkerAlt className="size-5 mr-2" />
            {room ? (
              <FormattedMessage id="Go to room" />
            ) : (
              <FormattedMessage id="Go to desk" />
            )}
          </StyledButton>
          <StyledButton
            className="py-1 px-1 md:py-1 md:px-2 flex items-center"
            onClick={
              room
                ? () =>
                    cancelRoomReservation(
                      { Id: id },
                      hasuraHeader(HasuraPermissions.READ, [
                        'RoomReservations_aggregate',
                      ]),
                    ).then((d) =>
                      toast(d, {
                        message: {
                          type: 'success',
                          content: intl.formatMessage(
                            {
                              id: 'Cancelled reservation for room',
                            },
                            { room: name },
                          ),
                        },
                      }),
                    )
                : () =>
                    cancelDeskReservation(
                      { Id: id },
                      hasuraHeader(HasuraPermissions.READ, [
                        'DeskReservations_aggregate',
                      ]),
                    ).then((d) =>
                      toast(d, {
                        message: {
                          type: 'success',
                          content: intl.formatMessage(
                            {
                              id: 'Cancelled reservation for desk',
                            },
                            { desk: name },
                          ),
                        },
                      }),
                    )
            }
            data-test-id="cancel-reservation-button"
          >
            <HiXCircle className="size-5 mr-2" />
            <FormattedMessage id="Cancel reservation" />
          </StyledButton>
        </>
      ) : null,
    [
      setBuilding,
      setFloor,
      params,
      navigate,
      cancelRoomReservation,
      hasuraHeader,
      toast,
      intl,
      cancelDeskReservation,
    ],
  );

  const events = useMemo(() => {
    const renderLocation = (building: string, floor: number) =>
      intl.formatMessage(
        {
          id: 'Building Floor',
        },
        {
          building,
          number: floor,
        },
      );

    const roomReservations = data?.RoomReservations?.map((reservation) => {
      const start = lower(reservation.Duration);
      const end = upper(reservation.Duration);
      return {
        id: reservation.Id,
        title: intl.formatMessage(
          {
            id: reservation.Cancelled
              ? 'Cancelled: Reservation for room'
              : 'Reservation for room',
          },
          {
            room: reservation.Room.Name,
          },
        ),
        buttons: renderButtons(
          reservation.Id,
          reservation.Cancelled,
          end,
          reservation.Room.Floor,
          reservation.Room.Floor.Building,
          reservation.Room.Name,
          true,
        ),
        start,
        end,
        cancelled: reservation.Cancelled,
        location: renderLocation(
          reservation.Room.Floor.Building.Name,
          reservation.Room.Floor.Number,
        ),
        isDesk: false,
      };
    });

    const deskReservations = data?.DeskReservations?.map((reservation) => {
      const start = lower(reservation.Duration);
      const end = upper(reservation.Duration);

      return {
        id: reservation.Id,
        title: intl.formatMessage(
          {
            id: reservation.Cancelled
              ? 'Cancelled: Reservation for desk'
              : 'Reservation for desk',
          },
          {
            desk: reservation.Desk.Name,
          },
        ),
        buttons: renderButtons(
          reservation.Id,
          reservation.Cancelled,
          end,
          reservation.Desk.Sensor?.RoomSensors[0]?.Room.Floor ?? {
            Id: 0,
            Number: 0,
          },
          reservation.Desk.Sensor?.RoomSensors[0]?.Room.Floor.Building ?? {
            Id: 0,
            Name: '',
          },
          reservation.Desk.Name,
        ),
        start,
        end,
        cancelled: reservation.Cancelled,
        location: renderLocation(
          reservation.Desk.Sensor?.RoomSensors[0]?.Room.Floor.Building.Name ??
            '',
          reservation.Desk.Sensor?.RoomSensors[0]?.Room.Floor.Number ?? 0,
        ),
        isDesk: true,
      };
    });

    return [
      ...(roomTypes.includes(RoomTypes.DESKS) ? (deskReservations ?? []) : []),
      ...(roomTypes.includes(RoomTypes.MEETING)
        ? (roomReservations ?? [])
        : []),
    ]
      .filter((reservation) =>
        !showCancelledEvents ? !reservation.cancelled : true,
      )
      .sort((a, b) => b.start.getTime() - a.start.getTime());
  }, [
    data?.DeskReservations,
    data?.RoomReservations,
    intl,
    renderButtons,
    roomTypes,
    showCancelledEvents,
  ]);

  const upcomingEvents = useMemo(
    () => events.filter((evt) => new Date() <= evt.end),
    [events],
  );
  const pastEvents = useMemo(
    () => events.filter((evt) => new Date() > evt.end),
    [events],
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2">
        <Button
          className="flex items-center"
          onClick={() => {
            localStorage.setItem(
              LS_SHOW_UPCOMING_RESERVATIONS,
              JSON.stringify(!showUpcoming),
            );
            setShowUpcoming(!showUpcoming);
          }}
        >
          {showUpcoming ? (
            <HiOutlineChevronDown className="size-6" />
          ) : (
            <HiOutlineChevronRight className="size-6" />
          )}
          <Subtitle
            value={intl.formatMessage({
              id: 'Upcoming Reservations',
            })}
          />
        </Button>
        <Transition show={showUpcoming}>
          <EventList
            events={upcomingEvents}
            loading={loading}
            dataTestId="upcoming-events"
          />
        </Transition>
      </div>
      <div className="flex flex-col gap-2">
        <Button
          className="flex items-center"
          onClick={() => {
            localStorage.setItem(
              LS_SHOW_PREVIOUS_RESERVATIONS,
              JSON.stringify(!showPrevious),
            );
            setShowPrevious(!showPrevious);
          }}
        >
          {showPrevious ? (
            <HiOutlineChevronDown className="size-6" />
          ) : (
            <HiOutlineChevronRight className="size-6" />
          )}
          <Subtitle
            value={intl.formatMessage({
              id: 'Previous Reservations',
            })}
          />
        </Button>
        <Transition show={showPrevious}>
          <EventList
            loading={loading}
            events={pastEvents}
            dataTestId="past-events"
          />
        </Transition>
      </div>
    </div>
  );
}
