import { useIntl } from 'translations/Intl';

import type { MarginProps } from '@/common/types';
import { RAINBOW } from '@/constants';
import Card from '@/generic/components/Card';
import Axis from '@/generic/components/Chart/Axis';
import Legend from '@/generic/components/Chart/Legend';
import LoadingSpinner from '@/generic/components/LoadingSpinner';
import {
  type SensorAverageHistoryClimateQuery,
  useSensorAverageHistoryClimateQuery,
} from '@/graphql/types';
import useStore from '@/model/store';
import localize from '@/utils/format';
import getColor from '@/utils/getColor';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import { lower, upper } from '@/utils/numberrange';
import { curveBasis } from '@visx/curve';
import { ParentSize } from '@visx/responsive';
import { scaleOrdinal } from '@visx/scale';
import { AnimatedLineSeries, Grid, Tooltip, XYChart } from '@visx/xychart';
import { useMemo, useState } from 'react';
import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';
import useXYChartTheme from 'utils/useXYChartTheme';

interface ResponsiveClimateChartProps {
  margin?: MarginProps;
}

interface CalendarChartProps extends ResponsiveClimateChartProps {
  height: number;
  width: number;
}

export type ChartData =
  SensorAverageHistoryClimateQuery['f_history_environment_daily'][number];

function ClimateChart({
  height,
  width,
  margin = { top: 40, left: 70, right: 80, bottom: 50 },
}: CalendarChartProps) {
  const roomTypes = useStore((state) => state.userSettings.roomTypes);
  const climateTypes = useStore((state) => state.userSettings.climateTypes);
  const [numTicks] = useState(4);

  const theme = useXYChartTheme({ colorRange: RAINBOW });
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();

  const [{ data: historyData, fetching: loadingHistory }] =
    useSensorAverageHistoryClimateQuery({
      variables: {
        ...useAnalyticsFilter(),
        RoomTypes: roomTypes,
      },
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_CLIMATE),
        [hasuraHeader],
      ),
    });

  const chartData = useMemo(
    () =>
      historyData?.f_history_environment_daily &&
      historyData.f_history_environment_daily.length > 0
        ? historyData.f_history_environment_daily.map((d) => ({
            ...d,
            Date: new Date(d.Date),
          }))
        : (climateTypes?.flatMap((type) => [
            ...['-'].map((r) => ({
              Id: '0',
              Room: r,
              SensorType: type,
              Value: 0,
              RoomType: '-',
              Date: new Date(),
              Unit: '',
              GoodValue: '[0,0]',
              PoorValue: '[0,0]',
              AcceptableValue: '[0,0]',
            })),
          ]) ?? []),
    [climateTypes, historyData, historyData?.f_history_environment_daily],
  );

  const rooms = useMemo(
    () => [...new Set(chartData.map((c) => c.Room))],
    [chartData],
  );

  const getRatingForValue = (good: string, value: number) => {
    if (value >= (lower(good) ?? 0) && value <= (upper(good) ?? 0))
      return intl.formatMessage({ id: 'Acceptable' });
    return intl.formatMessage({ id: 'Poor' });
  };

  return (
    <div className="space-y-2">
      <Card className="flex flex-wrap w-full mx-auto justify-center relative md:p-3">
        {loadingHistory ? (
          <div className="space-x-4 flex flex-wrap w-full justify-center mx-auto">
            {Array.from({ length: 10 }).map((_, j) => (
              <div className="flex space-x-1 my-1" key={j}>
                <div className="animate-pulse bg-neutral-200 h-4 rounded-full w-4" />
                <div className="animate-pulse bg-neutral-200 h-4 rounded-md w-16" />
              </div>
            ))}
          </div>
        ) : (
          <Legend
            scaleType="ordinal"
            labelFormat={(r) => r}
            scale={scaleOrdinal({
              domain: rooms,
              range: RAINBOW,
            })}
          />
        )}
      </Card>
      <div className="grid grid-cols-2 relative gap-2">
        {climateTypes?.map(
          (type) =>
            chartData.filter((c) => c.SensorType === type).length > 0 && (
              <Card
                title={`${intl.formatMessage({
                  id: '[Rooms] Climate',
                })} ${intl.formatMessage({ id: type })}`}
                key={type}
                className="relative h-96 col-span-2 md:col-span-1"
                data-test-id={`climate-chart-${type}`}
              >
                <LoadingSpinner loading={loadingHistory} />
                <XYChart
                  margin={margin}
                  theme={theme}
                  height={height}
                  xScale={{
                    type: 'time',
                  }}
                  yScale={{
                    type: 'linear',
                    domain: [
                      Math.min(
                        ...chartData
                          .filter((c) => c.SensorType === type)
                          .map((c) => c.Value),
                      ),
                      Math.max(
                        ...chartData
                          .filter((c) => c.SensorType === type)
                          .map((c) => c.Value),
                      ),
                    ],
                    nice: true,
                    zero: false,
                  }}
                >
                  <Grid
                    rows
                    numTicks={numTicks}
                    columns={false}
                    strokeDasharray="1,3"
                    stroke={getColor('NEUTRAL600')}
                  />
                  {rooms.map((r) => (
                    <AnimatedLineSeries
                      key={r}
                      dataKey={r}
                      data={chartData.filter(
                        (c) => c.SensorType === type && c.Room === r,
                      )}
                      xAccessor={(d) => d.Date}
                      yAccessor={(d) => d.Value}
                      curve={curveBasis}
                    />
                  ))}
                  <Axis
                    orientation="left"
                    tickFormat={(d) =>
                      `${d} ${
                        chartData.filter((c) => c.SensorType === type)[0]?.Unit
                      }`
                    }
                    numTicks={numTicks}
                  />
                  <Axis
                    orientation="bottom"
                    numTicks={width > 768 ? 5 : 2}
                    tickFormat={(v: string) =>
                      localize(new Date(v), 'eeeeee do LLL')
                    }
                  />
                  <Axis
                    orientation="right"
                    tickFormat={(v: number) =>
                      getRatingForValue(
                        chartData.find((c) => c.SensorType === type)
                          ?.GoodValue!,
                        v,
                      )
                    }
                    tickValues={[
                      lower(
                        chartData.find((c) => c.SensorType === type)
                          ?.AcceptableValue!,
                      ),
                      lower(
                        chartData.find((c) => c.SensorType === type)
                          ?.PoorValue!,
                      ),
                    ]}
                  />
                  <Tooltip<ChartData>
                    showVerticalCrosshair
                    renderTooltip={({ tooltipData, colorScale }) => (
                      <div className="dark:text-neutral-200">
                        {localize(
                          tooltipData?.nearestDatum?.datum.Date ?? new Date(),
                          'eeeeee do LLL',
                        )}
                        {Object.values(tooltipData?.datumByKey ?? {}).map(
                          (roomObject) => (
                            <div
                              key={roomObject.key}
                              data-test-id="climate-chart-tooltip"
                            >
                              <span
                                style={{
                                  color: colorScale?.(roomObject.key),
                                  textDecoration:
                                    tooltipData?.nearestDatum?.key ===
                                    roomObject.key
                                      ? 'underline'
                                      : undefined,
                                }}
                              >
                                {roomObject.key}
                              </span>{' '}
                              {Number.isNaN(roomObject.datum.Value)
                                ? '–'
                                : `${roomObject.datum.Value} ${roomObject.datum.Unit}`}
                            </div>
                          ),
                        )}
                      </div>
                    )}
                  />
                </XYChart>
              </Card>
            ),
        )}
      </div>
    </div>
  );
}

export default function ResponsiveClimateChart(
  props: ResponsiveClimateChartProps,
) {
  return (
    <ParentSize>
      {({ width, height }) => (
        <ClimateChart
          {...props}
          height={width > 768 ? height / 2.5 : height / 4.5}
          width={width}
        />
      )}
    </ParentSize>
  );
}
