import type { Route } from 'router/routes';
import { FormattedMessage } from 'translations/Intl';

import type { UrlParams } from '@/common/types';
import { HasuraPermissions } from '@/utils/graphql/useHasuraHeaders';
import { useState } from 'react';
import { HiCodeBracket } from 'react-icons/hi2';

import EmbeddedWrapper from '../EmbeddedWrapper';
import PrivateWrapper from '../PrivateWrapper';
import Tooltip from '../Tooltip';
import EmbeddedModal from './components/EmbeddedModal';

interface EmbeddedButtonProps {
  urlParams: UrlParams;
  component: Route['path'];
}

export default function EmbeddedButton({
  urlParams,
  component,
}: EmbeddedButtonProps): React.JSX.Element | null {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <PrivateWrapper roleRequired={HasuraPermissions.FEATURE_SHARE}>
      <EmbeddedWrapper>
        <>
          <Tooltip
            content={
              <p>
                <HiCodeBracket
                  className="size-5 cursor-pointer group-hover:block hidden hover:text-primary-500 transition-all"
                  id="embed"
                  data-test-id="embedded-button"
                  onClick={() => setModalOpen(true)}
                />
              </p>
            }
          >
            <FormattedMessage id="Embed" />
          </Tooltip>
          <EmbeddedModal
            isOpen={modalOpen}
            setIsOpen={setModalOpen}
            urlParams={urlParams}
            component={component}
          />
        </>
      </EmbeddedWrapper>
    </PrivateWrapper>
  );
}
