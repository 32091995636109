import Tab from 'generic/components/Tab';
import Title from 'generic/components/Title';
import {
  HiOutlineArrowPath,
  HiOutlineChartBar,
  HiOutlineNumberedList,
} from 'react-icons/hi2';
import { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';

export default function ReportingView() {
  return (
    <div>
      <div className="space-y-2 print:hidden">
        <div>
          <Title value="Reporting" />
        </div>
      </div>
      <Tab
        tabs={[
          {
            path: 'overview',
            userRole: HasuraPermissions.VIEW_ADMIN,
            name: 'Overview',
            renderIcon: ({ className }) => (
              <HiOutlineNumberedList className={className} />
            ),
          },
          {
            path: '',
            end: true, // avoid to be always active for all routes
            userRole: HasuraPermissions.VIEW_ADMIN,
            name: 'Defective beacons',
            renderIcon: ({ className }) => (
              <HiOutlineArrowPath className={className} />
            ),
          },
          {
            path: 'reports',
            name: 'Reports',
            userRole: HasuraPermissions.VIEW_REPORTS,
            renderIcon: ({ className }) => (
              <HiOutlineChartBar className={className} />
            ),
          },
        ]}
      />
    </div>
  );
}
