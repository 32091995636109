import type { CellContext, ColumnDef } from '@tanstack/react-table';
import type { DeviceTypes } from 'common/types';
import Card from 'generic/components/Card';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import Table from 'generic/components/Table';
import Tooltip from 'generic/components/Tooltip';
import Loader from 'generic/components/layout/BarLoader';
import { useDefectiveBeaconsQuery } from 'graphql/types';
import { useMemo } from 'react';
import { HiOutlinePrinter } from 'react-icons/hi2';
import { FormattedMessage, useIntl } from 'translations/Intl';
import BuildingFloorList from '../BuildingFloorList';
import ReportingMap, {
  getStatus,
  type DefectiveBeacons as DefectiveBeaconsType,
} from './components/ReportingMap';

function IdCell(props: CellContext<DefectiveBeaconsType, unknown>) {
  const { row } = props;
  return (
    <div
      className={`${
        row.original.StatusCode !== 0 ? 'bg-red-500' : 'bg-yellow-400'
      } size-5 rounded-full text-white flex justify-center items-center`}
    >
      {row.original.Index}
    </div>
  );
}

function StatusCell(props: CellContext<DefectiveBeaconsType, unknown>) {
  const { row } = props;
  return (
    <div className="break-words whitespace-pre max-w-sm">
      {getStatus(
        row.original.StatusCode,
        row.original.DeviceType?.Name as DeviceTypes,
      )}
    </div>
  );
}

export default function DefectiveBeacons() {
  const intl = useIntl();
  const [{ data: defectiveBeacons, fetching }] = useDefectiveBeaconsQuery();

  const defaultColumns: ColumnDef<DefectiveBeaconsType>[] = useMemo(
    () => [
      {
        id: 'identifier',
        header: intl.formatMessage({ id: 'id' }),
        accessorFn: (row) => row.Index.toString(),
        cell: IdCell,
      },
      {
        id: 'name',
        header: intl.formatMessage({ id: 'BeaconName' }),
        accessorKey: 'Name',
      },
      {
        id: 'deviceType',
        header: intl.formatMessage({ id: 'Device Type' }),
        accessorFn: (row) =>
          row.DeviceType?.Name ?? intl.formatMessage({ id: 'Unknown' }),
      },
      {
        id: 'statusCode',
        header: intl.formatMessage({ id: 'Last status' }),
        accessorFn: (row) =>
          getStatus(row.StatusCode, row.DeviceType?.Name as DeviceTypes),
        cell: StatusCell,
      },
      {
        id: 'offline',
        header: intl.formatMessage({ id: 'Online' }),
        accessorFn: (row) =>
          row.IsOffline
            ? intl.formatMessage({ id: 'Offline' })
            : intl.formatMessage({ id: 'Online' }),
      },
    ],
    [intl.formatMessage],
  );

  return (
    <>
      <Loader loading={fetching} />
      <Card className="print:!bg-transparent relative print:border-none print:!px-0 print:!py-0">
        <Tooltip
          content={
            <StyledButton
              className="print:hidden absolute right-6 top-6"
              onClick={() => window.print()}
            >
              <HiOutlinePrinter className="size-5" />
            </StyledButton>
          }
        >
          <FormattedMessage id="Print" />
        </Tooltip>
        <BuildingFloorList
          loading={fetching}
          buildings={defectiveBeacons?.Buildings ?? []}
          getTestId={(building) => `${building.Name}-defective-beacons`}
          renderMap={(_, floor) => (
            <ReportingMap
              key={floor.Number}
              image={floor.Image!}
              beacons={floor.MqttBeacons.map((beacon, key) => ({
                ...beacon,
                Index: key + 1,
              }))}
            />
          )}
          renderTable={(_, floor) => (
            <Table<DefectiveBeaconsType>
              id="defective-beacons"
              columns={defaultColumns}
              data={floor.MqttBeacons.map((beacon, key) => ({
                ...beacon,
                Index: key + 1,
              }))}
              getRowId={(row) => row.Id.toString()}
              initialState={{
                pagination: {
                  // Do not paginate as it won't be visible on the PDF
                  pageSize: floor.MqttBeacons.length,
                  pageIndex: 0,
                },
              }}
              enabledFeatures={{
                enableColumnSelection: false,
                enableCsvExport: false,
                enableGlobalFilter: false,
                enablePageSize: false,
                enableRowSelection: false,
                enablePagination: false,
                enableColumnFilter: false,
              }}
            />
          )}
          renderAdditionalContent={(building, floor) => (
            <div className="flex flex-col">
              <div data-test-id={`${building.Name}-defective-beacons-count`}>
                <FormattedMessage id="Defective beacons" />:{' '}
                {floor.defectiveBeaconCount.aggregate?.count}
              </div>
              <div data-test-id={`${building.Name}-offline-beacons-count`}>
                <FormattedMessage id="Offline beacons" />:{' '}
                {floor.offlineBeaconCount.aggregate?.count}
              </div>
              <div data-test-id={`${building.Name}-total-beacons-count`}>
                <FormattedMessage id="Total beacons" />:{' '}
                {floor.beaconCount.aggregate?.count}
              </div>
            </div>
          )}
        />
      </Card>
    </>
  );
}
