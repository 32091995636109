import { FormattedMessage } from 'translations/Intl';

import type { Event } from '../../../ReservationsCalendar/CustomEvent';

interface NewReservationProps {
  newEvent: Event | null;
  feature: string;
}

export default function NewReservation({
  newEvent,
  feature,
}: NewReservationProps): React.JSX.Element {
  return (
    <div className="flex flex-col  pt-4">
      <div className="text-lg  text-neutral-900 dark:text-neutral-400">
        <FormattedMessage
          id='Please confirm the new reservation for "{feature}"'
          values={{
            feature,
          }}
        />
      </div>
      {newEvent?.title}
    </div>
  );
}
