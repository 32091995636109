import {
  type BinsProps,
  type MarginProps,
  Themes,
  type TooltipDatumProps,
} from '@/common/types';
import Axis from '@/generic/components/Chart/Axis';
import Legend from '@/generic/components/Chart/Legend';
import LoadingSpinner from '@/generic/components/LoadingSpinner';
import { useHistoryOccupancyQuery } from '@/graphql/types';
import useStore from '@/model/store';
import { getAllHours, getWeekDayFromNumber } from '@/utils/date';
import getColor from '@/utils/getColor';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import type { Point } from '@visx/brush/lib/types';
import { Group } from '@visx/group';
import { HeatmapRect } from '@visx/heatmap';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear } from '@visx/scale';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';
import AnimatedRect from 'generic/components/Chart/AnimatedRect';
import Tooltip from 'generic/components/Tooltip';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'translations/Intl';
import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';

interface ResponsiveHeatmapOccupancyProps {
  margin?: MarginProps;
  meetingRoomOccupancy?: boolean;
}

interface HeatmapOccupancyProps extends ResponsiveHeatmapOccupancyProps {
  width: number;
  height: number;
}

function HeatmapOccupancy({
  width,
  height,
  margin = { top: 70, left: 70, right: 40, bottom: 60 },
  meetingRoomOccupancy = false,
}: HeatmapOccupancyProps): React.JSX.Element {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const theme = useStore((state) => state.userSettings.theme);
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<TooltipDatumProps>();

  const handleMouseMove = (coords: Point | null, datum: TooltipDatumProps) => {
    showTooltip({
      tooltipLeft: coords?.x,
      tooltipTop: coords?.y,
      tooltipData: datum,
    });
  };

  const [{ data: historyData, fetching: loadingHistory }] =
    useHistoryOccupancyQuery({
      variables: {
        ...useAnalyticsFilter(),
        HourlyRooms: meetingRoomOccupancy,
        WeeklyRooms: false,
        HourlyDesks: !meetingRoomOccupancy,
        WeeklyDesks: false,
      },
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
        [hasuraHeader],
      ),
    });

  const data = useMemo(() => {
    if (
      !meetingRoomOccupancy &&
      historyData?.f_history_desks_occupancy_hourly &&
      historyData.f_history_desks_occupancy_hourly.length > 0
    ) {
      return historyData.f_history_desks_occupancy_hourly;
    }

    return [];
  }, [
    historyData,
    historyData?.f_history_desks_occupancy_hourly,
    meetingRoomOccupancy,
  ]);

  const meetingData = useMemo(() => {
    if (
      meetingRoomOccupancy &&
      historyData?.f_history_rooms_occupancy_hourly &&
      historyData.f_history_rooms_occupancy_hourly.length > 0
    ) {
      return historyData.f_history_rooms_occupancy_hourly;
    }

    return [];
  }, [
    historyData,
    historyData?.f_history_rooms_occupancy_hourly,
    meetingRoomOccupancy,
  ]);

  const binData = useMemo<BinsProps[]>(
    () =>
      getAllHours().map((h) => ({
        bin: Number.parseInt(h, 10),
        bins: Array.from(Array(7)).map((_, f) => ({
          bin: f,
          count: meetingRoomOccupancy
            ? meetingData.filter(
                (e) => e.Hour === Number.parseInt(h, 10) && e.DayOfWeek === f,
              )[0]?.PercentageUsedMeetingRooms || 0
            : (data.filter(
                (e) => e.Hour === Number.parseInt(h, 10) && e.DayOfWeek === f,
              )[0]?.PercentageHotMinutes || 0) +
              (data.filter(
                (e) => e.Hour === Number.parseInt(h, 10) && e.DayOfWeek === f,
              )[0]?.PercentageWarmMinutes || 0),
        })),
      })),
    [data, meetingData, meetingRoomOccupancy],
  );

  const size =
    width > margin.left + margin.right
      ? width - margin.left - margin.right
      : width;
  const xMax = size;
  const yMax = height - margin.bottom - margin.top;

  const binWidth = xMax / 23;
  const binHeight = yMax / 7;

  const xScale = scaleLinear({
    domain: [0, 23],
    range: [0, xMax],
  });
  const yScale = scaleBand({
    domain: [0, 6, 5, 4, 3, 2, 1],
    range: [yMax, 0],
  });
  const rectColorScale = scaleLinear({
    range: [
      theme.color === Themes.LIGHT
        ? getColor('NEUTRAL50')
        : getColor('NEUTRAL600'),
      theme.color === Themes.LIGHT
        ? getColor('NEUTRAL300')
        : getColor('NEUTRAL400'),
      getColor('RED200'),
      getColor('RED400'),
      getColor('RED500'),
      getColor('RED'), // red-600
      getColor('RED700'),
      getColor('RED800'),
      getColor('RED900'),
    ],
    domain: [1, 5, 15, 30, 50, 60, 70, 85, 100],
    nice: true,
  });

  return (
    <>
      <LoadingSpinner loading={loadingHistory} />
      <div
        className="relative"
        data-test-id={`heatmap-chart-${meetingRoomOccupancy}`}
      >
        <svg width={width} height={height}>
          <Group top={margin.top} left={margin.left}>
            <HeatmapRect
              data={binData}
              xScale={(d) => xScale(d)}
              yScale={(d) => yScale(d) ?? 0}
              colorScale={rectColorScale}
              binWidth={binWidth}
              binHeight={binHeight > 0 ? binHeight : 0}
              gap={2}
            >
              {(heatmap) =>
                heatmap.map((heatmapBins) =>
                  heatmapBins.map((bin) => (
                    <AnimatedRect
                      key={`heatmap-rect-${bin.row}-${bin.column}`}
                      dataTestId={`heatmap-rect-${bin.row}-${bin.column}`}
                      bar={{
                        ...bin,
                        color: bin.color ?? '',
                      }}
                      rounded
                      onMouseMove={(event) =>
                        handleMouseMove(event, bin as TooltipDatumProps)
                      }
                      onMouseOut={hideTooltip}
                    />
                  )),
                )
              }
            </HeatmapRect>
            <Axis
              orientation="bottom"
              lowLevelChart
              top={yMax}
              hideZero
              numTicks={12}
              scale={xScale}
              label={intl.formatMessage({
                id: 'Hour of the day',
              })}
            />
            <Axis
              orientation="left"
              lowLevelChart
              tickFormat={(d) => getWeekDayFromNumber(d)}
              scale={yScale}
              label={intl.formatMessage({
                id: 'Day of the week',
              })}
            />
          </Group>
        </svg>
        <div className="absolute top-8 justify-center text-xs w-full flex">
          <div className="flex flex-col">
            <div className="w-full flex space-x-1 justify-center text-center items-center">
              <div>
                {meetingRoomOccupancy ? (
                  <FormattedMessage
                    id="Meeting rooms usage in percent"
                    values={{
                      meetingRooms: meetingData[0]?.TotalMeetingRooms ?? 0,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="Desks usage in percent"
                    values={{
                      desks: data[0]?.NumberOfDesks ?? 0,
                    }}
                  />
                )}
              </div>

              <Tooltip>
                <>
                  <FormattedMessage id="hot" /> + <FormattedMessage id="warm" />
                </>
              </Tooltip>
            </div>

            <div className="flex">
              <Legend
                scaleType="linear"
                labelFormat={(d) => `${d}%`}
                scale={rectColorScale}
              />
            </div>
          </div>
        </div>
        {tooltipOpen && tooltipData && (
          <TooltipWithBounds
            top={tooltipTop}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              background:
                theme.color === Themes.LIGHT
                  ? getColor('WHITE')
                  : getColor('NEUTRAL900'),
            }}
          >
            <div
              className="dark:text-neutral-200"
              data-test-id={`heatmap-tooltip-${meetingRoomOccupancy}`}
            >
              {`${getWeekDayFromNumber(
                tooltipData.bin.bin,
              )}. ${tooltipData.column}:00 - ${
                tooltipData.column + 1 === 24 ? '0' : tooltipData.column + 1
              }:00`}{' '}
              <strong>{tooltipData.bin.count.toFixed(2)}%</strong>
            </div>
          </TooltipWithBounds>
        )}
      </div>
    </>
  );
}

function ResponsiveHeatmapOccupancy(
  props: ResponsiveHeatmapOccupancyProps,
): React.JSX.Element {
  return (
    <ParentSize>
      {({ width, height }) => (
        <HeatmapOccupancy {...props} width={width} height={height} />
      )}
    </ParentSize>
  );
}

export default ResponsiveHeatmapOccupancy;
