import { FormattedMessage } from 'translations/Intl';

import { MqttSystems, useSetSceneResetMutation } from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import parseBluerangeTopic from '@/utils/parseBluerangeTopic';
import { HiOutlineArrowPath } from 'react-icons/hi2';

import SetSceneButton from '../SetSceneButton';

interface SetSceneResetButtonProps {
  mqttTopic: string;
}

export default function SetSceneResetButton({
  mqttTopic,
}: SetSceneResetButtonProps): React.JSX.Element {
  const hasuraHeader = useHasuraHeader();
  const [, setSceneResetMutation] = useSetSceneResetMutation();

  return (
    <SetSceneButton
      renderIcon={() => <HiOutlineArrowPath />}
      title="Set scene reset"
      onProceed={() =>
        setSceneResetMutation(
          {
            Topics: [parseBluerangeTopic(mqttTopic)],
            MqttSystem: MqttSystems.Bluerange,
          },
          hasuraHeader(HasuraPermissions.VIEW_ADMIN),
        )
      }
    >
      <FormattedMessage id="Reset scene" />
    </SetSceneButton>
  );
}
