import { ClimateType, RoomTypes } from 'common/types';
import LoadingScreen from 'generic/components/layout/LoadingScreen';
import { useOrganizationQuery, useReportDatesQuery } from 'graphql/types';
import keycloak from 'keycloak';
import useStore from 'model/store';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import useDecodedLocation from 'utils/useDecodedLocation';

import type { KeycloakTokenParsed } from 'keycloak-js';
import { useEffect, useMemo } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import NoRoles from './components/NoRoles/NoRoles';

interface KeycloakToken extends KeycloakTokenParsed {
  email: string;
  family_name?: string;
  given_name?: string;
  preferred_username: string;
  'https://hasura.io/jwt/claims'?: {
    'x-hasura-allowed-roles'?: HasuraPermissions[];
    'x-hasura-default-role': 'user';
    'x-hasura-org-id': string;
    'x-hasura-user-id': string;
  };
  acl?: {
    pub?: string[];
    sub?: string[];
    all?: string[];
  };
}

export default function SecuredPages() {
  const hasuraHeader = useHasuraHeader();
  const [queryParams] = useSearchParams();
  const room = useStore((state) => state.userSettings.room);
  const floor = useStore((state) => state.userSettings.floor);
  const building = useStore((state) => state.userSettings.building);
  const label = useStore((state) => state.userSettings.labels);
  const setClimateTypes = useStore((state) => state.userApi.setClimateTypes);
  const setRoom = useStore((state) => state.userApi.setRoom);
  const setFloor = useStore((state) => state.userApi.setFloor);
  const setBuilding = useStore((state) => state.userApi.setBuilding);
  const setLabels = useStore((state) => state.userApi.setLabels);
  const setReportDate = useStore((state) => state.userApi.setReportDate);
  const userRoles = useStore((state) => state.user?.roles);
  const setUser = useStore((state) => state.setUser);
  const setAAWActivated = useStore((state) => state.setAAWActivated);
  const setIsSetup = useStore((state) => state.setIsSetup);
  const setOrganizationName = useStore(
    (state) => state.organizationApi.setOrganizationName,
  );
  const setOfflineMinutes = useStore(
    (state) => state.organizationApi.setOfflineMinutes,
  );
  const setOrganizationUuid = useStore(
    (state) => state.organizationApi.setOrganizationUuid,
  );
  const setCleaningTimes = useStore(
    (state) => state.organizationApi.setCleaningTimes,
  );
  const setBusinessHours = useStore(
    (state) => state.organizationApi.setBusinessHours,
  );
  const setReservationLimit = useStore(
    (state) => state.organizationApi.setReservationLimit,
  );
  const setWarmMinutesPolicy = useStore(
    (state) => state.organizationApi.setWarmMinutesPolicy,
  );
  const setOrganizationLogo = useStore(
    (state) => state.organizationApi.setOrganizationLogo,
  );
  const { check: cleaningCheck } = useStore(
    (state) => state.organizationSettings.cleaningTimes,
  );
  const location = useLocation();
  const navigate = useNavigate();
  const buildingParam = useDecodedLocation('building');
  const floorParam = useDecodedLocation('floor');
  const roomParam = useDecodedLocation('room');

  /* If a param is specified then ignore the Zustand store. Else load from the Zustand store. If the element from the
   * param/store does not exist in the DB then set a default value
   *
   * Check loadingOrganization beacuse at the beginning it doesn't have any data and will wrongly assume the building/floor/room
   * doesn't exist
   *
   * Do not load anything from hasura if the user doesn't have any roles as it won't be able to decode the token
   */
  const [{ data: organization, fetching: loadingOrganization }] =
    useOrganizationQuery({
      pause: !userRoles,
      context: useMemo(() => ({ additionalTypenames: ['Labels'] }), []),
    });
  const [{ data: reportData, fetching: loadingReportDates }] =
    useReportDatesQuery({
      pause: !userRoles?.includes(HasuraPermissions.VIEW_REPORTS),
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_REPORTS),
        [hasuraHeader],
      ),
    });

  const buildings = useMemo(
    () => organization?.Buildings,
    [organization?.Buildings],
  );

  const labels = useMemo(
    () => organization?.Labels.map((l) => l.Name),
    [organization?.Labels],
  );

  const floors = useMemo(
    () =>
      buildings?.find(
        (bldg) =>
          (!buildingParam && bldg.Id === building?.Id) ||
          bldg.Name === buildingParam,
      )?.Floors,
    [buildings, building?.Id, buildingParam],
  );

  const rooms = useMemo(
    () =>
      floors?.find(
        (f) =>
          (!floorParam && f.Id === floor?.Id) ||
          f.Number.toString() === floorParam,
      )?.Rooms,
    [floor?.Id, floorParam, floors],
  );

  const labelsExist = useMemo(
    () => !!label?.every((l) => labels?.includes(l)),
    [label, labels],
  );

  const buildingExists = useMemo(
    () => !!buildings?.find((b) => building && b.Id === building.Id),
    [buildings, building],
  );

  const buildingParamExists = useMemo(
    () => !!buildings?.find((b) => buildingParam && b.Name === buildingParam),
    [buildings, buildingParam],
  );

  const floorExists = useMemo(
    () => !!floors?.find((f) => floor && f.Id === floor.Id),
    [floors, floor],
  );

  const floorParamExists = useMemo(
    () =>
      !!floors?.find((f) => floorParam && f.Number.toString() === floorParam),
    [floors, floorParam],
  );

  const roomExists = useMemo(
    () => !!rooms?.find((r) => room && r.Id === room.Id),
    [rooms, room],
  );

  const roomParamExists = useMemo(
    () => !!rooms?.find((r) => roomParam && r.Name === roomParam),
    [rooms, roomParam],
  );

  useEffect(() => {
    if (organization?.SensorTypes.length) {
      const climateTypes = organization.SensorTypes.map(
        (s) => s.Name as ClimateType,
        // TODO: Remove when stable
      ).filter((s) => ![ClimateType.HUMIDITY].includes(s));
      setClimateTypes(climateTypes);
    }
  }, [
    organization?.SensorTypes.length,
    organization?.SensorTypes.map,
    setClimateTypes,
  ]);

  // Reset room if it doesn't exist
  useEffect(() => {
    if (!roomExists && !loadingOrganization) {
      setRoom(undefined);
    }
  }, [loadingOrganization, roomExists, setRoom]);

  // Reset floor if it doesn't exist
  useEffect(() => {
    if (!floorExists && !loadingOrganization) {
      setFloor(undefined);
    }
  }, [loadingOrganization, floorExists, setFloor]);

  // Reset building if it doesn't exist
  useEffect(() => {
    if (!buildingExists && !loadingOrganization) {
      setBuilding(undefined);
    }
  }, [buildingExists, loadingOrganization, setBuilding]);

  // Reset labels if at least one doesn't exist
  useEffect(() => {
    if (!labelsExist && !loadingOrganization) {
      setLabels([]);
    }
  }, [labelsExist, loadingOrganization, setLabels]);

  // Set a building and floor as all the maps are loaded based on a building and floor being set
  useEffect(() => {
    if (organization?.Buildings[0]?.Floors[0]) {
      if (
        ((!floor || !floorExists) &&
          // Analytics allows deselecting floor and building so do not set it automatically
          !location.pathname.includes('analytics')) ||
        // Do not do anything if the floor param is set
        (!floorParam && floorParamExists)
      ) {
        setFloor(
          organization.Buildings.filter((b) => b.Id === building?.Id)[0]
            ?.Floors[0],
        );
      }
    }
  }, [
    building?.Id,
    floor,
    floorExists,
    floorParam,
    floorParamExists,
    location.pathname,
    setFloor,
    organization?.Buildings[0]?.Floors[0],
    organization?.Buildings.filter,
  ]);

  useEffect(() => {
    if (organization?.Buildings[0]) {
      if (
        ((!building || !buildingExists) &&
          // Analytics allows deselecting floor and building so do not set it automatically
          !location.pathname.includes('analytics')) ||
        // Do not do anything if the building param is set
        (!buildingParam && buildingParamExists)
      ) {
        setBuilding(organization.Buildings[0]);
      }
    }
  }, [
    building,
    buildingExists,
    buildingParam,
    buildingParamExists,
    location.pathname,
    setBuilding,
    organization?.Buildings[0],
  ]);

  // Remove parameters from URL if they do not exist
  useEffect(() => {
    if (buildings && !buildingParamExists) {
      queryParams.delete('building');
    }
    if (floors && !floorParamExists) {
      queryParams.delete('floor');
    }
    if (rooms && !roomParamExists) {
      queryParams.delete('room');
    }
    if (!roomParamExists || !floorParamExists || !buildingParamExists) {
      navigate({
        search: queryParams.toString(),
      });
    }
  }, [
    buildingParamExists,
    buildings,
    floorParamExists,
    floors,
    navigate,
    queryParams,
    roomParamExists,
    rooms,
  ]);

  useEffect(() => {
    const tokenInfo = keycloak.tokenParsed as KeycloakToken;
    const name = tokenInfo.name ?? tokenInfo.preferred_username ?? '';
    const {
      family_name: familyName,
      given_name: givenName,
      email,
      'https://hasura.io/jwt/claims': hasura,
      acl,
    } = tokenInfo; // Keycloak doesn't know about these claims

    // If the user registers with SSO then he won't have any hasura specific claims
    if (hasura) {
      const mda2Enabled =
        (organization?.mda2BeaconCount.aggregate?.count ?? 0) > 0;
      const allowedRoles = hasura['x-hasura-allowed-roles'] ?? [];

      setUser({
        name,
        roles: mda2Enabled
          ? [...allowedRoles, HasuraPermissions.MDA2]
          : allowedRoles,
        email,
        familyName: familyName ?? '',
        givenName: givenName ?? '',
        organization: hasura['x-hasura-org-id'],
        userUuid: hasura['x-hasura-user-id'],
        acls: { ...acl },
      });

      setAAWActivated(
        allowedRoles.includes(HasuraPermissions.FEATURE_ROOMMODES) &&
          !!mda2Enabled,
      );
    }
  }, [
    setUser,
    organization?.mda2BeaconCount.aggregate?.count,
    setAAWActivated,
  ]);

  useEffect(() => {
    if (organization?.totalBeaconCount.aggregate?.count) {
      // Mark as setup if there are any beacons available
      setIsSetup(organization.totalBeaconCount.aggregate.count > 0);
    }
  }, [organization?.totalBeaconCount.aggregate?.count, setIsSetup]);

  useEffect(() => {
    if (reportData?.Reports[0]?.Period) {
      setReportDate(reportData.Reports[0].Period);
    }
  }, [reportData?.Reports?.[0]?.Period, setReportDate]);

  useEffect(() => {
    if (organization?.Organizations[0]) {
      const {
        Name,
        Id,
        WarmMinutesPolicy,
        CleaningThreshold,
        BusinessHours,
        ReservationLimit,
        LogoDark,
        LogoLight,
        OfflineMinutes,
      } = organization.Organizations[0];

      setOrganizationName(Name);
      setOrganizationUuid(Id);
      setOfflineMinutes(OfflineMinutes);
      setWarmMinutesPolicy(WarmMinutesPolicy);
      setCleaningTimes({
        check: cleaningCheck,
        clean: CleaningThreshold,
      });
      if (BusinessHours[0]?.BusinessHours) {
        setBusinessHours({
          BusinessHours: BusinessHours[0].BusinessHours,
          LunchHours: BusinessHours[0]?.Lunch ?? undefined,
        });
      }
      if (ReservationLimit) {
        setReservationLimit(ReservationLimit);
      }
      if (LogoDark && LogoLight) {
        setOrganizationLogo({
          light: LogoLight,
          dark: LogoDark,
        });
      }
    }
  }, [
    cleaningCheck,
    organization?.Organizations[0],
    setOrganizationLogo,
    setOrganizationName,
    setOrganizationUuid,
    setWarmMinutesPolicy,
    setCleaningTimes,
    setBusinessHours,
    setReservationLimit,
    setOfflineMinutes,
  ]);

  // Reset the room if it is not available for selection
  useEffect(() => {
    if (room) {
      // Room occupancy
      if (location.pathname === '/analytics/rooms') {
        if (room.RoomType.Name !== RoomTypes.MEETING) {
          setRoom(undefined);
        }
      }

      // Desk occupancy
      if (location.pathname === '/analytics') {
        if (room.RoomType.Name !== RoomTypes.DESKS) {
          setRoom(undefined);
        }
      }
    }
  }, [location.pathname, room, setRoom]);

  if (loadingOrganization || loadingReportDates) {
    return <LoadingScreen />;
  }

  // User doesn't have any roles, most probably because he used SSO and doesn't belong to any orga
  if (!userRoles?.length) {
    return <NoRoles />;
  }

  return <Outlet />;
}
