import useStore from 'model/store';

import BreadCrumbs from '@/generic/components/BreadCrumbs';
import Title from '@/generic/components/Title';
import { useState } from 'react';

import BuildingList from './components/BuildingList';

export default function AdminView(): React.JSX.Element {
  const [isAddingBuilding, setIsAddingBuilding] = useState(false);
  const organizationName = useStore(
    (state) => state.organizationSettings.organizationName,
  );

  return (
    <div className="relative space-y-4">
      <div>
        <Title value="Organization Settings" />
      </div>

      <BreadCrumbs
        items={[
          {
            text: organizationName ?? '',
          },
        ]}
      />

      <BuildingList
        isAdding={isAddingBuilding}
        setIsAdding={setIsAddingBuilding}
      />
    </div>
  );
}
