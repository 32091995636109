import Transition from '@/generic/components/Transition';
import { HasuraPermissions } from '@/utils/graphql/useHasuraHeaders';
import Switch from 'generic/components/Form/Switch/Switch';
import PrivateWrapper from 'generic/components/PrivateWrapper';
import Tooltip from 'generic/components/Tooltip';
import { FormattedMessage } from 'translations/Intl';

interface AAWSelectionProps {
  aloneAtWorkIsActivated: boolean;
  setAloneAtWorkIsActivated: (val: boolean) => void;
  show: boolean;
}

export default function AAWSelection({
  show,
  aloneAtWorkIsActivated,
  setAloneAtWorkIsActivated,
}: AAWSelectionProps): React.JSX.Element {
  return (
    <PrivateWrapper roleRequired={HasuraPermissions.FEATURE_ROOMMODES}>
      <Transition show={show} appear>
        <Switch
          isEnabled={aloneAtWorkIsActivated}
          onSetEnable={() => setAloneAtWorkIsActivated(!aloneAtWorkIsActivated)}
          label={
            <div className="flex space-x-1 items-center">
              <div>
                <FormattedMessage id="AloneAtWork" />
              </div>
              <Tooltip>
                <FormattedMessage id="AloneAtWork description" />
              </Tooltip>
            </div>
          }
          labelClassName="text-base md:text-sm"
        />
      </Transition>
    </PrivateWrapper>
  );
}
