import Subtitle from 'generic/components/Subtitle/Subtitle';
import { useDisableKeycloakUsersMutation } from 'graphql/types';
import type { KeycloakTableUser } from 'pages/SettingsView/components/UserManagementView/components/UsersTable/components/AddUserModal/AddUserModal';
import { useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import useToast from 'utils/graphql/useToast';

import { Action } from '@/common/types';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import type { Row } from '@tanstack/react-table';

interface ConfirmationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  disabled: boolean;
  users: Row<KeycloakTableUser>[];
}

export default function ConfirmationModal({
  open,
  setOpen,
  disabled,
  users,
}: ConfirmationModalProps): React.JSX.Element {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const toast = useToast();
  const [{ fetching }, disableUsers] = useDisableKeycloakUsersMutation();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      action={Action.UPDATE}
      open={open}
      setShowModal={setOpen}
      footer={
        <ModalFooter
          disabled={false}
          proceed={intl.formatMessage({
            id: 'Confirm',
          })}
          isLoading={fetching}
          action={Action.UPDATE}
          onProceed={() => {
            disableUsers(
              {
                UserInput: {
                  users: users.map((user) => ({ id: user.original.id })),
                  disabled,
                },
              },
              hasuraHeader(HasuraPermissions.VIEW_USERGROUP),
            )
              .then((data) =>
                toast(data, {
                  message: {
                    type: 'success',
                    content: intl.formatMessage(
                      { id: disabled ? 'Disabled users' : 'Enabled users' },
                      {
                        count: users.length,
                      },
                    ),
                  },
                }),
              )
              .finally(() => {
                setOpen(false);
                for (const user of users) {
                  user.getIsSelected() ? user.toggleSelected() : false;
                }
              });
          }}
          onCancel={() => setOpen(false)}
        />
      }
    >
      <Subtitle
        value={
          disabled
            ? intl.formatMessage({ id: 'Disable selected users' })
            : intl.formatMessage({ id: 'Enable selected users' })
        }
        className="text-base pb-2"
      />
    </Modal>
  );
}
