import { ModuleType } from 'common/types';
import type { OccupancyMapQuery } from 'graphql/types';

import getColor from '@/utils/getColor';
import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import GeoJSON, { type GeoJSONFeature } from 'ol/format/GeoJSON';
import type OLGeometry from 'ol/geom/Geometry';
import type Point from 'ol/geom/Point';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

import type { LayerOptions } from './Layer';
import type TypedFeature from './TypedFeature';
import VectorLayer from './VectorLayer';

type RoomBeaconQueryType = NonNullable<
  OccupancyMapQuery['MqttBeacons']
>[number];
interface RoomBeaconType extends RoomBeaconQueryType {
  isRoomBeaconFeature: boolean;
}

export type RoomBeaconFeatureType = TypedFeature<RoomBeaconType, OLGeometry>;

const styleFunction = (
  feature: RoomBeaconFeatureType,
  hoveredFeature: RoomBeaconFeatureType | undefined,
  isMobile: boolean,
) => {
  const isHovered = feature.getGeometry() === hoveredFeature?.getGeometry();
  const offline = feature.getProperties().IsOffline;
  const inUse = feature
    .getProperties()
    .Sensors.filter(
      (s) =>
        ![
          ModuleType.LINECOUNT,
          ModuleType.LINECOUNT_IN,
          ModuleType.LINECOUNT_OUT,
        ].includes(s.SensorType.Name as ModuleType),
    )
    .some((s) => (s.Value ?? 0) > 0);

  const isPrivate = feature
    .getProperties()
    .Sensors.filter(
      (s) =>
        ![
          ModuleType.LINECOUNT,
          ModuleType.LINECOUNT_IN,
          ModuleType.LINECOUNT_OUT,
        ].includes(s.SensorType.Name as ModuleType),
    )
    .some((s) => s.IsPrivate ?? false);

  const getRadius = () => {
    if (isMobile && isHovered) return 12;
    if (isHovered) return 6;
    return 5;
  };

  const getBeaconColor = () => {
    if (offline) return 'NEUTRAL400';
    if (isPrivate) return 'NEUTRAL600';
    if (inUse) return 'RED';
    return 'GREEN';
  };

  return [
    new Style({
      image: new Circle({
        radius: getRadius(),
        fill: new Fill({
          color: getColor(getBeaconColor(), offline ? '.4' : '.9'),
        }),
        stroke: new Stroke({
          color: getColor(getBeaconColor(), offline ? '.4' : '1'),
          width: isHovered ? 3 : 2,
        }),
      }),
    }),
  ];
};

interface RoomBeaconLayerOptions extends LayerOptions {
  olLayer: OLVectorLayer<VectorSource<Feature<Point>>>;
}

class RoomBeaconLayer extends VectorLayer<Point> {
  features?: OccupancyMapQuery['MqttBeacons'];

  hoveredFeature?: RoomBeaconFeatureType;

  isMobile: boolean;

  init(map: OLMap): void {
    super.init(map);
  }

  constructor() {
    super({
      ...{
        olLayer: new OLVectorLayer({
          source: new VectorSource(),
          style: (feature) =>
            styleFunction(
              feature as RoomBeaconFeatureType,
              this.hoveredFeature,
              this.isMobile,
            ),
        }),
      },
      name: 'roomBeaconLayer',
    } as RoomBeaconLayerOptions);

    this.hoveredFeature = undefined;
    this.isMobile = false;
  }

  setFeatures(feats: OccupancyMapQuery['MqttBeacons']): void {
    if (feats) {
      this.features = feats;
    }
    const source = this.olLayer.getSource() as VectorSource<Feature<Point>>;
    const newFeatures = new GeoJSON().readFeatures(
      RoomBeaconLayer.toGeoJson(this.features),
    );
    source.clear();
    source.addFeatures(newFeatures as Feature<Point>[]);
  }

  static toGeoJson(feats?: OccupancyMapQuery['MqttBeacons']): GeoJSONFeature {
    const features = feats?.map((feat) => {
      const { Geometry } = feat;
      return {
        type: 'Feature',
        geometry: Geometry,
        properties: {
          ...feat,
          isRoomBeaconFeature: true,
        },
      };
    });
    return {
      type: 'FeatureCollection',
      features,
    };
  }
}

export default RoomBeaconLayer;
