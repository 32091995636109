import type { ReactNode } from 'react';

export type TimelineEntry = {
  id: number;
  renderIcon: ({ className }: { className: string }) => React.JSX.Element;
  title: string;
  subtitle: ReactNode;
  active: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export default function TimeLineEntry({
  renderIcon,
  title,
  subtitle,
  active,
  onMouseEnter,
  onMouseLeave,
}: Omit<TimelineEntry, 'id'>) {
  return (
    <li>
      <div
        data-test-id={title}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={`items-center block p-3 sm:flex hover:bg-neutral-100 dark:hover:bg-neutral-700 ${active ? 'bg-neutral-100 dark:bg-neutral-700' : ''}`}
      >
        <div>
          {renderIcon({ className: 'size-12 mb-3 me-3 rounded-full sm:mb-0' })}
        </div>
        <div className="text-neutral-600 dark:text-neutral-400">
          <div className="text-base font-normal">{title}</div>
          <div className="text-sm font-normal">{subtitle}</div>
        </div>
      </div>
    </li>
  );
}
