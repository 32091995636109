import Checkbox from 'generic/components/Form/Checkbox';
import Tooltip from 'generic/components/Tooltip';
import Transition from 'generic/components/Transition';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';

import { Action, type Floor, ModuleType } from '@/common/types';
import Button from '@/generic/components/Form/Button';
import ImageInput from '@/generic/components/Form/ImageInput/ImageInput';
import Input from '@/generic/components/Form/Input';
import Switch from '@/generic/components/Form/Switch';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import Loader from '@/generic/components/layout/BarLoader';
import {
  type FloorSensorsQuery,
  useBuildingQuery,
  useFloorSensorsQuery,
  useInsertFloorMutation,
  useInsertFloorSensorsMutation,
  useUpdateFloorMutation,
  useUpdateFloorWithoutImageMutation,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import { hexToBase64Image } from '@/utils/hexToBase64';
import useDecodedParams from '@/utils/useDecodedParams';
import useStore from 'model/store';
import { Fragment, useEffect, useMemo, useState } from 'react';
import {
  HiBars3,
  HiBars4,
  HiOutlineChevronRight,
  HiOutlineMinus,
  HiOutlinePlus,
} from 'react-icons/hi2';

interface AddFloorModalProps {
  floor?: Floor;
  isAdding: boolean;
  isEditAction: boolean;
  setIsAdding: (value: boolean) => void;
}

type FloorSensorType = {
  FloorsId: number;
  SensorsId: number;
  Sign: number;
};

type SensorsType = FloorSensorsQuery['smartModules'][number]['Sensors'][number];

function AddFloorModal({
  isEditAction,
  isAdding,
  setIsAdding,
  floor,
}: AddFloorModalProps): React.JSX.Element {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const AAWActivated = useStore((state) => state.AAWActivated);
  const [name, setName] = useState(floor?.Name ?? '');
  const [number, setNumber] = useState(floor?.Number ?? 1);
  const [image, setImage] = useState(floor?.Image);
  const [imageResolution, setImageResolution] = useState<{
    w: number;
    h: number;
  } | null>(null);
  const [imageValidated, setImageValidated] = useState(false);
  const [isLoadingAddFloor, setIsLoadingAddFloor] = useState(false);
  const [toggledBeacons, setToggledBeacons] = useState<number[]>([]);
  const [activeFloorSensors, setActiveFloorSensors] = useState<
    FloorSensorType[]
  >([]);
  const { buildingName } = useDecodedParams();
  const [{ data: buildingData, fetching: loading }] = useBuildingQuery({
    variables: {
      BuildingName: buildingName,
    },
  });

  const [{ data: floorSensors, fetching: loadingFloorSensor }] =
    useFloorSensorsQuery({
      variables: {
        FloorId: floor?.Id ?? 0,
        ModuleTypes: Object.values(ModuleType),
      },
      pause: !floor || typeof floor.Id !== 'number',
      context: useMemo(
        () => ({
          additionalTypenames: ['Desks', 'MqttBeacons', 'Rooms'],
        }),
        [],
      ),
    });

  const [, editFloorSensors] = useInsertFloorSensorsMutation();

  const [{ fetching: loadingAddFloor }, addFloor] = useInsertFloorMutation();

  const [
    { fetching: loadingChangeFloorWithoutImage },
    changeFloorWithoutImage,
  ] = useUpdateFloorWithoutImageMutation();

  const [{ fetching: loadingChangeFloor }, changeFloor] =
    useUpdateFloorMutation();

  useEffect(() => {
    setIsLoadingAddFloor(loadingAddFloor);
  }, [loadingAddFloor]);

  const [isLoadingChangeFloor, setIsLoadingChangeFloor] = useState(false);
  useEffect(() => {
    setIsLoadingChangeFloor(
      loadingChangeFloor || loadingChangeFloorWithoutImage,
    );
  }, [loadingChangeFloor, loadingChangeFloorWithoutImage]);

  useEffect(() => {
    if (isAdding) {
      // Allow to cancel loading on close.
      setIsLoadingAddFloor(false);
      setIsLoadingChangeFloor(false);
    }
  }, [isAdding]);

  const building = useMemo(
    () => buildingData?.Buildings[0],
    [buildingData?.Buildings[0]],
  );

  useEffect(() => {
    setName(floor?.Name ?? '');
    setNumber(floor?.Number ?? 1);
    setImage(floor?.Image);
  }, [floor]);

  useEffect(() => {
    if (image) {
      const i = new Image();
      i.onload = () => {
        setImageResolution({ w: i.width, h: i.height });
      };
      i.src = hexToBase64Image(image);
    } else {
      setImageResolution(null);
    }
  }, [image]);

  useEffect(() => {
    if (floorSensors?.FloorSensor) {
      setActiveFloorSensors(
        floorSensors.FloorSensor.map((fS) => ({
          FloorsId: fS.FloorsId,
          SensorsId: fS.SensorsId,
          Sign: fS.Sign,
        })),
      );
    }
  }, [floorSensors]);

  if (loading) return <Loader loading={loading} />;

  const resetValues = () => {
    setIsAdding(false);
    setImage(null);
    setImageValidated(false);
    setName('');
    setNumber(1);
  };

  return (
    <Modal
      action={isEditAction ? Action.UPDATE : Action.ADD}
      title={intl.formatMessage({
        id: isEditAction ? 'Edit floor' : 'Add floor',
      })}
      open={isAdding}
      setShowModal={resetValues}
      footer={
        <ModalFooter
          disabled={
            Number.isNaN(number) ||
            !imageValidated ||
            loadingAddFloor ||
            loadingChangeFloor ||
            !imageResolution
          }
          action={isEditAction ? Action.UPDATE : Action.ADD}
          loading={<FormattedMessage id="Save" />}
          isLoading={isLoadingAddFloor || isLoadingChangeFloor}
          onProceed={() => {
            if (isEditAction) {
              editFloorSensors(
                {
                  FloorId: floor?.Id,
                  FloorSensors: activeFloorSensors,
                },
                hasuraHeader(HasuraPermissions.WRITE_FLOOR),
              );

              if (image === floor?.Image) {
                changeFloorWithoutImage(
                  {
                    Id: floor?.Id,
                    Name: name.trim() === '' ? null : name,
                    Number: number,
                    Input: {
                      floorId: floor?.Id,
                    },
                    AAWActivated,
                  },
                  hasuraHeader(HasuraPermissions.WRITE_FLOOR),
                ).then((data) => {
                  toast(data, {
                    field: name.trim() === '' ? 'Number' : 'Name',
                  });
                  resetValues();
                });
              } else {
                if (floor?.Id) {
                  changeFloor(
                    {
                      Id: floor.Id,
                      Floor: {
                        Name: name.trim() === '' ? null : name,
                        Number: number,
                        Image: image || floor?.Image,
                        ImageWidth: imageResolution?.w,
                        ImageHeight: imageResolution?.h,
                        // Reset the calculations when changing the image
                        GeometryUnitPerMeter: null,
                      },
                      Input: {
                        floorId: floor.Id,
                      },
                      AAWActivated,
                    },
                    hasuraHeader(HasuraPermissions.WRITE_FLOOR),
                  ).then((data) => {
                    toast(data, {
                      field: name.trim() === '' ? 'Number' : 'Name',
                    });
                    resetValues();
                  });
                }
              }
            } else {
              addFloor(
                {
                  Name: name.trim() === '' ? null : name,
                  BuildingId: building?.Id,
                  Number: number,
                  Image: image,
                  ImageWidth: imageResolution?.w,
                  ImageHeight: imageResolution?.h,
                },
                hasuraHeader(HasuraPermissions.WRITE_FLOOR),
              ).then((data) => {
                toast(data, { field: name.trim() === '' ? 'Number' : 'Name' });
                resetValues();
              });
            }
          }}
          onCancel={resetValues}
        />
      }
    >
      <div className="space-y-4">
        <Input
          type="text"
          value={name}
          label={intl.formatMessage({
            id: 'Floor name',
          })}
          placeholder={intl.formatMessage({
            id: 'Floor name',
          })}
          id="floor-name"
          renderIcon={({ className }) => <HiBars4 className={className} />}
          onChangeValue={(e: string) => setName(e)}
        />

        <Input
          type="number"
          value={number}
          label={intl.formatMessage({
            id: 'Floor number',
          })}
          data-test-id="Floor number"
          renderIcon={({ className }) => <HiBars3 className={className} />}
          onChangeValue={(v: string) => setNumber(Number.parseInt(v, 10))}
          required
        />

        <ImageInput
          previewImage={floor?.Image ?? ''}
          label={intl.formatMessage({
            id: 'Floor Plan',
          })}
          setImage={setImage}
          imageValidated={imageValidated}
          setImageValidated={setImageValidated}
          required
        />

        {!loadingFloorSensor &&
          floor &&
          floorSensors?.smartModules &&
          floorSensors.smartModules.length > 0 && (
            <div className="flex flex-col">
              <div className="flex items-center text-base md:text-sm text-neutral-700 dark:text-neutral-200">
                <span className="m-1">
                  <FormattedMessage id="Floor sensors" />
                </span>
                <Tooltip>
                  <FormattedMessage id="Activate all sensors that are used to count the total number of visitors for the floor." />
                </Tooltip>
              </div>
              <div className="flex flex-col">
                {floorSensors.smartModules.map((module) => {
                  const toggledBeacon = toggledBeacons.includes(module.Id);
                  const onHandleToggle = () => {
                    if (toggledBeacon) {
                      setToggledBeacons(
                        toggledBeacons.filter((b) => b !== module.Id),
                      );
                    } else {
                      const newToggledBeacons = [...toggledBeacons];
                      newToggledBeacons.push(module.Id);
                      setToggledBeacons(newToggledBeacons);
                    }
                  };
                  return (
                    <Fragment key={module.Id}>
                      <Button
                        key={`${module.Id}-button`}
                        onClick={onHandleToggle}
                        className="flex items-center"
                      >
                        <HiOutlineChevronRight
                          className={`size-5 transition-transform ${
                            toggledBeacon ? 'rotate-90' : ''
                          }`}
                        />
                        <div className="text-base md:text-sm text-neutral-700 dark:text-neutral-200">
                          {module.Name}
                        </div>
                      </Button>
                      <Transition
                        key={`${module.Id}-transition`}
                        show={toggledBeacon}
                      >
                        {module.Sensors.filter((sensor) =>
                          (
                            [
                              ModuleType.LINECOUNT,
                              ModuleType.AREACOUNT,
                              ModuleType.PRESENCE,
                            ] as string[]
                          ).includes(sensor.SensorType.Name),
                        )
                          .sort((a, b) => a.Index - b.Index)
                          .map((sensor) => {
                            const isActive =
                              activeFloorSensors.length > 0 &&
                              !!activeFloorSensors.find(
                                (fS) => fS.SensorsId === sensor.Id,
                              );
                            const isLineCounter =
                              sensor.SensorType.Name === ModuleType.LINECOUNT;
                            const currentSign =
                              activeFloorSensors.find(
                                (fS) => sensor.Id === fS.SensorsId,
                              )?.Sign || 1;
                            return (
                              <div key={sensor.Id}>
                                <Checkbox
                                  checked={isActive}
                                  setChecked={() => {
                                    let newActiveFloorSensors = [
                                      ...activeFloorSensors,
                                    ];
                                    let associatedLineSensors =
                                      [] as SensorsType[];
                                    if (isLineCounter) {
                                      associatedLineSensors =
                                        module.Sensors.filter(
                                          (s) =>
                                            (
                                              [
                                                ModuleType.LINECOUNT_IN,
                                                ModuleType.LINECOUNT_OUT,
                                              ] as string[]
                                            ).includes(s.SensorType.Name) &&
                                            s.Index === sensor.Index,
                                        );
                                    }
                                    if (isActive) {
                                      newActiveFloorSensors =
                                        newActiveFloorSensors.filter(
                                          (fS) => fS.SensorsId !== sensor.Id,
                                        );
                                      if (associatedLineSensors.length) {
                                        for (const s of associatedLineSensors) {
                                          newActiveFloorSensors =
                                            newActiveFloorSensors.filter(
                                              (fS) => fS.SensorsId !== s.Id,
                                            );
                                        }
                                      }
                                    } else {
                                      newActiveFloorSensors.push({
                                        FloorsId: floor.Id,
                                        SensorsId: sensor.Id,
                                        Sign: 1,
                                      });
                                      if (associatedLineSensors.length) {
                                        for (const s of associatedLineSensors) {
                                          newActiveFloorSensors.push({
                                            FloorsId: floor.Id,
                                            SensorsId: s.Id,
                                            Sign: 1,
                                          });
                                        }
                                      }
                                    }
                                    setActiveFloorSensors(
                                      newActiveFloorSensors,
                                    );
                                  }}
                                  label={intl.formatMessage({
                                    id: sensor.SensorType
                                      .Name as IntlMessageKeys,
                                  })}
                                />
                                {isLineCounter && isActive && (
                                  <div className="col-span-1 flex items-center">
                                    <div className="size-6 rounded-full flex items-center justify-center text-neutral-700 dark:text-neutral-200">
                                      <HiOutlineMinus className="size-3" />
                                    </div>
                                    <Switch
                                      isEnabled={currentSign === 1}
                                      onSetEnable={(enabling) => {
                                        const newActiveFloorSensors = [
                                          ...activeFloorSensors,
                                        ];
                                        let associatedLineSensors =
                                          [] as SensorsType[];
                                        associatedLineSensors =
                                          module.Sensors.filter(
                                            (s) =>
                                              (
                                                [
                                                  ModuleType.LINECOUNT_IN,
                                                  ModuleType.LINECOUNT_OUT,
                                                ] as string[]
                                              ).includes(s.SensorType.Name) &&
                                              s.Index === sensor.Index,
                                          );
                                        if (associatedLineSensors.length) {
                                          for (const s of associatedLineSensors) {
                                            const associatedLineOutSensorIdx =
                                              newActiveFloorSensors.findIndex(
                                                (nS) => nS.SensorsId === s.Id,
                                              );
                                            newActiveFloorSensors.splice(
                                              associatedLineOutSensorIdx,
                                              1,
                                              {
                                                FloorsId: floor.Id,
                                                SensorsId: s.Id,
                                                Sign: enabling ? 1 : -1,
                                              },
                                            );
                                          }
                                        }
                                        const idx =
                                          newActiveFloorSensors.findIndex(
                                            (s) => s.SensorsId === sensor.Id,
                                          );
                                        newActiveFloorSensors.splice(idx, 1, {
                                          FloorsId: floor.Id,
                                          SensorsId: sensor.Id,
                                          Sign: enabling ? 1 : -1,
                                        });
                                        setActiveFloorSensors(
                                          newActiveFloorSensors,
                                        );
                                      }}
                                      color="bg-primary-200 dark:bg-neutral-200"
                                      disabledColor="bg-primary-200 dark:bg-neutral-200"
                                    />
                                    <div className="size-6 rounded-full flex items-center justify-center text-neutral-700 dark:text-neutral-200">
                                      <HiOutlinePlus className="size-3" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </Transition>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          )}
      </div>
    </Modal>
  );
}

export default AddFloorModal;
