import HelpTooltip from 'generic/components/HelpTooltip';
import useStore from 'model/store';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';

import Card from '@/generic/components/Card';
import StyledButton from '@/generic/components/Form/Button/StyledButton';
import {
  HiBars4,
  HiOutlineBuildingOffice,
  HiOutlinePlus,
} from 'react-icons/hi2';

interface NoContentProps {
  clickAction: () => void;
  type: 'building' | 'floor';
  loading: boolean;
}

export default function NoContent({
  clickAction,
  type,
  loading,
}: NoContentProps) {
  const setInitialSetupSteps = useStore((state) => state.setInitialSetupSteps);

  return !loading ? (
    <Card className="w-full h-96 dark:bg-opacity-80 dark:hover:bg-opacity-100 border-neutral-200 hover:border-transparent px-0 py-0">
      <div className="h-full w-full flex items-center justify-center">
        <div className="flex flex-col items-center p-8 space-y-4">
          <div className="relative">
            {type === 'building' ? (
              <HiOutlineBuildingOffice className="size-12 text-neutral-500 mb-2" />
            ) : (
              <HiBars4 className="size-12 text-neutral-500 mb-2" />
            )}
            <HiOutlinePlus className="absolute right-0 top-8 rounded-xl size-5 text-neutral-500 dark:text-neutral-200 shadow bg-white dark:bg-neutral-800" />
          </div>
          <div className="text-center">
            <div>
              <FormattedMessage id={`No ${type}s` as IntlMessageKeys} />
            </div>
            <div>
              <FormattedMessage
                id={`Get started by creating a new ${type}.` as IntlMessageKeys}
              />
            </div>
          </div>
          <HelpTooltip
            stepName={type === 'floor' ? 'addedFloor' : 'addedBuilding'}
            content={
              <StyledButton
                onClick={() => {
                  clickAction();
                  setInitialSetupSteps(
                    type === 'floor' ? 'addedFloor' : 'addedBuilding',
                  );
                }}
                className="h-full flex items-center justify-center "
                id={`add-${type}`}
                data-test-id={`add-${type}`}
              >
                <HiOutlinePlus className="size-5 mr-4" />
                <FormattedMessage id={`Add ${type}` as IntlMessageKeys} />
              </StyledButton>
            }
          >
            <FormattedMessage
              id={
                type === 'floor'
                  ? 'Initial: Add floor'
                  : 'Initial: Add building'
              }
            />
          </HelpTooltip>
        </div>
      </div>
    </Card>
  ) : null;
}
