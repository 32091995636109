import type { MarginProps } from '@/common/types';
import { RAINBOW } from '@/constants';
import Axis from '@/generic/components/Chart/Axis';
import Legend from '@/generic/components/Chart/Legend';
import getColor from '@/utils/getColor';
import GridRows from '@visx/grid/lib/grids/GridRows';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleOrdinal } from '@visx/scale';
import { LinePath } from '@visx/shape';
import { AnimatedPath } from 'pages/AnalyticsView/components/BrushCart/Components/AreaChart';
import { useMemo } from 'react';
import { useIntl } from 'translations/Intl';

interface Data {
  numberOfPeople: number;
  occupancyPercent: number;
  capacity: number;
}

interface ResponsiveLineChart {
  margin?: MarginProps;
  data: Data[];
}

interface LineChart extends ResponsiveLineChart {
  height: number;
  width: number;
}

function LineChart({
  height,
  width,
  margin = {
    top: 60,
    left: 70,
    right: 30,
    bottom: 60,
  },
  data,
}: LineChart) {
  const intl = useIntl();
  const capacities = useMemo(
    () => [...new Set(data.map((d) => d.capacity))].sort((a, b) => a - b),
    [data],
  );

  // Bounds
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  // scales
  const xScale = scaleLinear<number>({
    range: [0, xMax],
    domain: [0, Math.max(...data.map((d) => d.numberOfPeople))],
  });

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [0, 100],
    nice: true,
  });

  const colorScale = scaleOrdinal({
    domain: capacities,
    range: RAINBOW,
  });

  return (
    <div className="relative">
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            numTicks={10}
            scale={yScale}
            width={xMax}
            height={yMax}
            strokeDasharray="1,3"
            stroke={getColor('NEUTRAL600')}
            strokeOpacity={0.6}
          />
          {capacities.map((c) => (
            <LinePath<Data>
              key={c}
              data={data.filter((d) => d.capacity === c)}
              x={(d) => xScale(d.numberOfPeople) ?? 0}
              y={(d) => yScale(d.occupancyPercent) ?? 0}
            >
              {({ path }) => (
                <AnimatedPath
                  path={path}
                  data={data.filter((d) => d.capacity === c)}
                  stroke={colorScale(c)}
                />
              )}
            </LinePath>
          ))}
          <Axis
            lowLevelChart
            top={yMax}
            scale={xScale}
            orientation="bottom"
            label={intl.formatMessage({ id: 'Number of people' })}
          />
          <Axis
            orientation="left"
            lowLevelChart
            tickFormat={(y) => `${y}%`}
            scale={yScale}
            label={intl.formatMessage({
              id: 'Number of people in % of total occupancy',
            })}
          />
        </Group>
      </svg>
      <div className="flex items-center justify-evenly absolute top-8 w-full space-y-2">
        <div className="flex">
          <Legend
            scaleType="ordinal"
            scale={colorScale}
            labelFormat={(d) =>
              `${intl.formatMessage({ id: 'Capacity' })} ${d}`
            }
          />
        </div>
      </div>
    </div>
  );
}

export default function ResponsiveCleaningChart(props: ResponsiveLineChart) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <LineChart {...props} width={width} height={height} />
      )}
    </ParentSize>
  );
}
