import { ExtendedMap } from '@/generic/components/BasicMap';
import Card from '@/generic/components/Card';
import LastPolled from '@/generic/components/LastPolled';
import Map from '@/generic/components/Map';
import Loader from '@/generic/components/layout/BarLoader';
import BaseLayer from '@/generic/layers/BaseLayer';
import ClimateBeaconLayer from '@/generic/layers/ClimateBeaconLayer';
import ClimateRoomLayer, {
  type ClimateRoomFeatureType,
  type ClimateRoomLayerFeature,
} from '@/generic/layers/ClimateRoomLayer';
import type Layer from '@/generic/layers/Layer';
import { useFloorImageQuery, useSensorAverageQuery } from '@/graphql/types';
import defaultFloorplan from '@/img/default_floorplan.jpg';
import useStore from '@/model/store';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import usePolling from '@/utils/graphql/usePolling';
import useDeviceDetect from '@/utils/useDeviceDetect';
import { TooltipWithBounds } from '@visx/tooltip';
import type { MapBrowserEvent } from 'ol';
import { touchOnly } from 'ol/events/condition';
import { INVALID_NUMBER } from 'pages/SettingsView/components/OrganizationView/components/LimitSelect/components/ClimateSlider/ClimateSlider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ClimatePopup from './components/ClimatePopup';
import {
  type ClimateFeatures,
  isClimateBeaconFeature,
} from './components/ClimatePopup/ClimatePopup';

export default function ClimateMap(): React.JSX.Element {
  const { isMobile } = useDeviceDetect();
  const hasuraHeader = useHasuraHeader();
  const [baseLayer] = useState(new BaseLayer());
  const [climateRoomLayer] = useState(new ClimateRoomLayer());
  const [climateBeaconLayer] = useState(new ClimateBeaconLayer());
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const building = useStore((state) => state.userSettings.building);
  const floor = useStore((state) => state.userSettings.floor);
  const climateTypes = useStore((state) => state.userSettings.climateTypes);
  const [layers] = useState<Layer[]>([
    baseLayer,
    climateRoomLayer,
    climateBeaconLayer,
  ]);
  const [map] = useState(new ExtendedMap());
  const [hoveredFeature, setHoveredFeature] = useState<ClimateFeatures | null>(
    null,
  );

  const [{ data, fetching: loading }, reexecute] = useSensorAverageQuery({
    variables: {
      ClimateTypes: climateTypes,
      Building: building?.Name,
      Floor: floor?.Number,
    },
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_CLIMATE),
      [hasuraHeader],
    ),
    pause: typeof floor?.Number !== 'number' || !building?.Name,
  });

  const [{ data: floorImageData, fetching: imageLoading }] = useFloorImageQuery(
    {
      variables: {
        BuildingName: building?.Name,
        FloorNumber: floor?.Number,
      },
      pause: typeof floor?.Number !== 'number' || !building?.Name,
    },
  );

  usePolling(loading, reexecute, 1000 * 60, setLastUpdate);

  const getSensor = useCallback(
    (sensorType: string, room: string) =>
      data?.f_live_environment.filter(
        (r) => r.Room === room && r.SensorType === sensorType,
      )[0],
    [data?.f_live_environment],
  );

  const getOfflineValue = useCallback(
    (room: string) =>
      data?.f_live_environment
        .filter((r) => r.Room === room)
        .every((s) => s.IsOffline) ?? false,
    [data?.f_live_environment],
  );

  useEffect(() => {
    if (floorImageData) {
      baseLayer.setImage(floorImageData.Floors[0]?.Image ?? '');
    }
    // Set a dummy room if there are no floors added yet
    if (floorImageData && floorImageData.Floors.length === 0) {
      baseLayer.setDefault(defaultFloorplan);
    }
  }, [baseLayer, floorImageData, floorImageData?.Floors[0]?.Image]);

  useEffect(() => {
    if (data?.f_live_environment && floorImageData?.Floors[0]?.Image) {
      const filteredRooms = data.f_live_environment
        .map((r) => ({
          Room: r.Room,
          Geometry: r.Geometry,
        }))
        // Filter duplicates
        .filter(
          (v, i, a) =>
            a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i,
        );

      const roomsWithClimate: ClimateRoomLayerFeature[] = filteredRooms.map(
        (r) => ({
          ...r,
          SensorValues: [
            ...new Set(data.f_live_environment.map((l) => l.SensorType)),
          ]
            .sort((a, b) => a.localeCompare(b))
            .map((sensorType) => ({
              SensorType: sensorType,
              Value: getSensor(sensorType, r.Room)?.Value ?? INVALID_NUMBER,
              GoodValue: getSensor(sensorType, r.Room)?.GoodValue ?? '[0,0]',
              AcceptableValue:
                getSensor(sensorType, r.Room)?.AcceptableValue ?? '[0,0]',
              PoorValue: getSensor(sensorType, r.Room)?.PoorValue ?? '[0,0]',
              Unit: getSensor(sensorType, r.Room)?.Unit ?? '',
            }))
            // Filters out values with default value, beacuse there was no corresponding sensortype
            .filter((s) => s.Value !== INVALID_NUMBER),
          Offline: getOfflineValue(r.Room),
        }),
      );
      climateRoomLayer.setFeatures(roomsWithClimate);
    } else {
      climateRoomLayer.setFeatures([]);
    }
  }, [
    floorImageData?.Floors[0]?.Image,
    data,
    data?.f_live_environment,
    getOfflineValue,
    getSensor,
    climateRoomLayer,
  ]);

  useEffect(() => {
    if (data?.MqttBeacons && floorImageData?.Floors[0]?.Image) {
      climateBeaconLayer.setFeatures(
        data.MqttBeacons.filter((s) => s.Sensors.length !== 0),
      );
    } else {
      climateBeaconLayer.setFeatures([]);
    }
  }, [
    floorImageData?.Floors[0]?.Image,
    data,
    data?.MqttBeacons,
    climateBeaconLayer,
  ]);

  useEffect(() => {
    if (climateTypes) {
      // Hide popup when sensor types change to not show stale data
      setHoveredFeature(null);
    }
  }, [climateTypes]);

  const showFeatureInfo = (
    _: MapBrowserEvent<PointerEvent>,
    feat?: ClimateFeatures,
  ) => {
    if (!feat) {
      setHoveredFeature(null);
      climateRoomLayer.hoveredFeature = undefined;
      climateBeaconLayer.hoveredFeature = undefined;
      climateRoomLayer.olLayer.changed();
      climateBeaconLayer.olLayer.changed();
    } else if (isClimateBeaconFeature(feat)) {
      // Reset room
      climateRoomLayer.hoveredFeature = undefined;
      climateRoomLayer.olLayer.changed();
      // Set beacon
      climateBeaconLayer.hoveredFeature = feat;
      setHoveredFeature(feat);
      climateBeaconLayer.isMobile = isMobile;
      climateBeaconLayer.olLayer.changed();
      // Rooms
    } else {
      // Reset beacon
      climateBeaconLayer.hoveredFeature = undefined;
      climateBeaconLayer.olLayer.changed();
      // Set room
      climateRoomLayer.hoveredFeature = feat as ClimateRoomFeatureType;
      setHoveredFeature(feat);
      climateRoomLayer.isMobile = isMobile;
      climateRoomLayer.olLayer.changed();
    }
  };

  return (
    <div className="relative">
      <Loader loading={loading || imageLoading} />
      <LastPolled lastPoll={lastUpdate} />
      <Map<ClimateFeatures>
        map={map}
        layers={layers}
        isLoadingFeatures={imageLoading}
        onFeaturesClick={(features, evt) => {
          if (touchOnly(evt)) {
            showFeatureInfo(evt, features[0]);
          }
        }}
        onFeaturesHover={(hoveredFeatures, evt) => {
          const [feat] = hoveredFeatures.map((hF) => hF.feature);
          showFeatureInfo(evt, feat);
        }}
        renderTooltip={(props) => {
          if (isMobile) {
            return (
              <Card>
                <ClimatePopup hoveredFeature={hoveredFeature} />
              </Card>
            );
          }
          return (
            <TooltipWithBounds {...props}>
              <ClimatePopup hoveredFeature={hoveredFeature} />
            </TooltipWithBounds>
          );
        }}
      />
    </div>
  );
}
