import { useCurrentVersionQuery } from 'graphql/types';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';

import localize from '@/utils/format';
import { HiCalendar, HiCodeBracket, HiEnvelope } from 'react-icons/hi2';
import type { IconType } from 'react-icons/lib';

import ChangelogList from './components/ChangelogList';

export default function AboutView() {
  const [{ data: version }] = useCurrentVersionQuery();

  const features: {
    name: IntlMessageKeys;
    description: string | React.JSX.Element;
    icon: IconType;
  }[] = [
    {
      name: 'Version',
      description: `${version?.Changelogs[0]?.Version ?? 'v1.0.0'} (${
        import.meta.env.VITE_VERSION ?? '1'
      })`,
      icon: HiCodeBracket,
    },
    {
      name: 'Last Updated',
      description: localize(
        new Date(import.meta.env.VITE_LAST_UPDATED ?? new Date()),
      ),
      icon: HiCalendar,
    },
    {
      name: 'Contact',
      description: (
        <a href="mailto:mda-support@regent.ch">mda-support@regent.ch</a>
      ),
      icon: HiEnvelope,
    },
  ];
  return (
    <div className="py-12 md:py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-primary-500 font-semibold tracking-wide uppercase">
            <FormattedMessage id="About" />
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-neutral-900 dark:text-neutral-200 sm:text-4xl">
            Regent MDA
          </p>
          <p className="mt-4 max-w-2xl text-xl text-neutral-500 lg:mx-auto">
            <FormattedMessage id="Developed with love in Basel" /> ©{' '}
            {localize(new Date(), 'yyyy')}
          </p>
        </div>

        <div className="mt-10 space-y-5">
          <div>
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center size-12 rounded-md bg-primary-500 dark:bg-primary-700 text-white">
                      <feature.icon className="size-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6  text-neutral-900 dark:text-neutral-100">
                      <FormattedMessage id={feature.name} />
                    </p>
                  </dt>
                  <dd className="ml-16 text-base text-neutral-500 dark:text-neutral-300">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          <div>
            <ChangelogList />
          </div>
        </div>
      </div>
    </div>
  );
}
