import { FormattedMessage, useIntl } from 'translations/Intl';

import { Action } from '@/common/types';
import Input from '@/generic/components/Form/Input';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import Subtitle from '@/generic/components/Subtitle';
import {
  type MqttMessageMappingsQuery,
  useDeleteMessageMappingMutation,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import { useState } from 'react';
import { HiOutlineTrash } from 'react-icons/hi2';

interface RemoveMappingModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  mappingToRemove?: MqttMessageMapping;
  setMappingToRemove: (mappingToRemove?: MqttMessageMapping) => void;
}

export type MqttMessageMapping =
  MqttMessageMappingsQuery['MqttMessageMappings'][number];

export default function RemoveMappingModal({
  open,
  setOpen,
  mappingToRemove,
  setMappingToRemove,
}: RemoveMappingModalProps): React.JSX.Element {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [confirmDelete, setConfirmDelete] = useState('');
  const [{ fetching }, deleteMapping] = useDeleteMessageMappingMutation();

  const resetValues = () => {
    setConfirmDelete('');
    setMappingToRemove(undefined);
    setOpen(false);
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      action={Action.REMOVE}
      open={open}
      setShowModal={resetValues}
      footer={
        <ModalFooter
          disabled={confirmDelete !== mappingToRemove?.Name}
          proceed={intl.formatMessage({
            id: 'Confirm',
          })}
          isLoading={fetching}
          action={Action.REMOVE}
          onProceed={() => {
            if (confirmDelete === mappingToRemove?.Name) {
              deleteMapping(
                {
                  Id: mappingToRemove.Id,
                },
                hasuraHeader(HasuraPermissions.WRITE_MQTTMAPPING),
              ).then((data) => {
                toast(data, {
                  message: {
                    type: 'success',
                    content: intl.formatMessage(
                      { id: 'Deleted mapping' },
                      { name: mappingToRemove.Name },
                    ),
                  },
                });
                resetValues();
              });
            }
          }}
          onCancel={resetValues}
        />
      }
    >
      <Subtitle
        value={intl.formatMessage({
          id: 'Are you sure to remove this mapping?',
        })}
        className="text-base pb-2"
      />
      <p className="pb-2">
        <FormattedMessage
          id="Enter the mapping {name} in order to delete it"
          values={{
            name: <b>{mappingToRemove?.Name}</b>,
          }}
        />
      </p>

      <Input
        type="text"
        value={confirmDelete}
        renderIcon={({ className }) => <HiOutlineTrash className={className} />}
        onChangeValue={(input: string) => setConfirmDelete(input)}
        placeholder={mappingToRemove?.Name ?? ''}
        required
        data-test-id="confirm-delete-mapping"
      />
    </Modal>
  );
}
