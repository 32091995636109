import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';

import { Action } from '@/common/types';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import {
  type SensorsStatusQuery,
  useDeleteSensorMutation,
} from '@/graphql/types';
import useStore from '@/model/store';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';

interface RemoveSensorModalProps {
  beaconName: string;
  sensorToRemove: SensorsStatusQuery['Sensors'][number] | null;
  setSensorToRemove: (
    sensor: SensorsStatusQuery['Sensors'][number] | null,
  ) => void;
  setIsDeleteLoading: (sensorId: number | null) => void;
}

export default function RemoveSensorModal({
  beaconName,
  sensorToRemove,
  setSensorToRemove,
  setIsDeleteLoading,
}: RemoveSensorModalProps) {
  const intl = useIntl();
  const userRoles = useStore((state) => state.user)?.roles;
  const [, removeSensor] = useDeleteSensorMutation();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();

  const resetValues = () => setSensorToRemove(null);

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      action={Action.REMOVE}
      open={!!sensorToRemove}
      setShowModal={resetValues}
      footer={
        !!sensorToRemove && (
          <ModalFooter
            disabled={false}
            proceed={intl.formatMessage({
              id: 'Confirm',
            })}
            action={Action.REMOVE}
            onProceed={() => {
              setIsDeleteLoading(sensorToRemove.Id);
              removeSensor(
                {
                  SensorId: sensorToRemove.Id,
                },
                hasuraHeader(
                  userRoles?.includes(HasuraPermissions.READ_ALL)
                    ? HasuraPermissions.READ_ALL
                    : HasuraPermissions.DELETE_SENSOR,
                ),
              )
                .then((data) => {
                  setIsDeleteLoading(null);
                  toast(data, { field: 'SensorType.Name' });
                })
                .catch(() => {
                  setIsDeleteLoading(null);
                });
              resetValues();
            }}
            onCancel={resetValues}
          />
        )
      }
    >
      {sensorToRemove && (
        <>
          <p className="pb-2">
            <FormattedMessage
              id="Are you sure to remove this {type} sensor from beacon {beaconName}?"
              values={{
                type: (
                  <b>
                    {intl.formatMessage({
                      id: sensorToRemove.SensorType.Name as IntlMessageKeys,
                    })}
                  </b>
                ),
                beaconName: <b>{beaconName}</b>,
              }}
            />
          </p>
          <p className="pb-2">
            <FormattedMessage id="Deleting this sensor will delete all associated historical measurements" />
          </p>
        </>
      )}
    </Modal>
  );
}
