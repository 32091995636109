import LuminaireIcon from 'generic/components/LuminaireIcon';
import useStore from 'model/store';
import { FormattedMessage, useIntl } from 'translations/Intl';

import {
  Action,
  type BeaconFeatures,
  type DeskFeatures,
  DeviceTypes,
  type DrawingDeskFeatures,
  type FloorRoomMapFeatures,
  GeometryType,
  type NewSensor,
  OtherType,
  type RoomFeatures,
  type SensorTypes,
  type StoredOffsets,
} from '@/common/types';
import { LS_DESK_OFFSETS, LS_DESK_RADIUS, LS_DESK_ROTATION } from '@/constants';
import Button from '@/generic/components/Form/Button';
import ModalFooter from '@/generic/components/ModalFooter';
import Panel from '@/generic/components/Panel';
import Transition from '@/generic/components/Transition';
import type VectorLayer from '@/generic/layers/VectorLayer';
import {
  type FloorMapFeaturesQuery,
  MqttSystems,
  useInactivateBeaconMutation,
  useInsertDesksMutation,
  useUpdateDesksMutation,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import useDeviceDetect from '@/utils/useDeviceDetect';
import type OLMap from 'ol/Map';
import type Point from 'ol/geom/Point';
import type OLVectorLayer from 'ol/layer/Vector';
import type VectorSource from 'ol/source/Vector';
import { useEffect, useMemo, useState } from 'react';
import { HiOutlineExclamationCircle, HiOutlineTrash } from 'react-icons/hi2';
import { Link } from 'react-router-dom';

import {
  getDeskFeatureIndex,
  isDrawingDeskFeature,
} from '../../interactions/moveDesk';
import OtherSensorsList from '../OtherSensorsList';
import DeskConfiguration from './DeskConfiguration';
import DeskSensorActivation from './DeskSensorActivation';
import IdentifyButton from './components/IdentifyButton';

export function isBeaconFeature(
  feature: FloorRoomMapFeatures,
): feature is BeaconFeatures {
  if ('MqttBeaconSource' in (feature as BeaconFeatures).getProperties()) {
    return true;
  }
  return false;
}

interface AddDesksCardProps {
  map: OLMap;
  open: boolean;
  floorId: number;
  isNew: boolean;
  beacon?: BeaconFeatures;
  beaconGeom?: Point | null;
  features?: FloorMapFeaturesQuery['Desks'];
  drawingDesksLayer: VectorLayer<Point>;
  onClose: (isSaving: boolean) => void;
  onRotate: (
    radians: number,
    deskFeats: (DrawingDeskFeatures | DeskFeatures)[],
  ) => void;
  setIsSaving: (bool: boolean) => void;
  intersectedRooms: RoomFeatures[] | null;
  setIsSelectingNewBeacon: (arg: boolean) => void;
  storedRotation: number;
  storedOffsets: StoredOffsets;
  onRadiusChange: () => void;
  resetDesksToInitialPositions: (activeSensorIds: number[]) => void;
}

export default function AddDesksCard({
  map,
  open,
  floorId,
  isNew,
  beacon,
  beaconGeom,
  features,
  drawingDesksLayer,
  onClose,
  onRotate,
  setIsSaving,
  intersectedRooms,
  setIsSelectingNewBeacon,
  storedRotation,
  storedOffsets,
  onRadiusChange,
  resetDesksToInitialPositions,
}: AddDesksCardProps) {
  const intl = useIntl();
  const toast = useToast();
  const { isMobile } = useDeviceDetect();
  const userRoles = useStore((state) => state.user)?.roles;
  const AAWActivated = useStore((state) => state.AAWActivated);
  const hasuraHeader = useHasuraHeader();
  const beaconName = useMemo(
    () => beacon?.getProperties().Name ?? null,
    [beacon],
  );
  const mqttTopic = useMemo(
    () =>
      beacon && isBeaconFeature(beacon)
        ? beacon.getProperties().MqttTopic
        : null,
    [beacon],
  );
  const mqttBeaconSource = useMemo(
    () =>
      (beacon && isBeaconFeature(beacon)
        ? beacon.getProperties().MqttBeaconSource.Name
        : null) as MqttSystems | null,
    [beacon],
  );
  const sensors = useMemo(
    () =>
      beacon && isBeaconFeature(beacon)
        ? [...beacon.getProperties().Sensors].sort((a, b) => a.Index - b.Index)
        : [],
    [beacon],
  );
  const deskInUseSensors = useMemo(
    () => sensors.filter((s) => s.SensorType.Name === OtherType.DESKINUSE),
    [sensors],
  );

  const deskFeatures = useMemo(
    () =>
      features?.filter((f) =>
        deskInUseSensors.map((s) => s.Id).includes(f.Sensor?.Id ?? 0),
      ),
    [deskInUseSensors, features],
  );
  const activeSensorsIds = useMemo(
    () => (deskFeatures ? deskFeatures.map((f) => f.Sensor?.Id) : []),
    [deskFeatures],
  );
  const [sensorsList, setSensorsList] = useState<
    NewSensor[] | undefined | null
  >(null);
  const [otherSensorsList, setOtherSensorsList] = useState<
    NewSensor[] | undefined | null
  >(null);

  useEffect(() => {
    const newSensorsList = deskInUseSensors.map((s) => {
      const active = isNew ? true : activeSensorsIds.includes(s.Id);
      return {
        id: s.Id,
        index: s.Index,
        active,
        name:
          active && deskFeatures?.length
            ? deskFeatures.find((d) => d.Sensor?.Id === s.Id)?.Name
            : `${beaconName}_${s.Index}`,
        type: s.SensorType.Name,
      } as NewSensor;
    });
    setSensorsList(newSensorsList);

    const otherSensors = sensors.filter(
      (sensor) => sensor.SensorType.Name !== OtherType.DESKINUSE,
    );
    const newOtherSensorsList = otherSensors.map((s) => ({
      id: s.Id,
      index: s.Index,
      active: isNew ? true : activeSensorsIds.includes(s.Id),
      type: s.SensorType.Name as SensorTypes,
      roomSensors: [],
    }));
    setOtherSensorsList(newOtherSensorsList);
  }, [
    deskInUseSensors,
    deskFeatures,
    activeSensorsIds,
    sensors,
    isNew,
    beaconName,
  ]);

  const saveDisabled = useMemo(() => {
    const activeNames = sensorsList?.filter((s) => s.active).map((s) => s.name);
    const duplicateNames = activeNames?.filter(
      (item, index) => activeNames.indexOf(item) !== index,
    );
    return (
      !beaconGeom ||
      duplicateNames?.length !== 0 ||
      activeNames?.length === 0 ||
      activeNames?.indexOf('') !== -1
    );
  }, [beaconGeom, sensorsList]);

  const [, addDesks] = useInsertDesksMutation();
  const [, updateDesks] = useUpdateDesksMutation();
  const [, inactivateBeacon] = useInactivateBeaconMutation();

  const canRemoveBeacon = !isNew && beacon;

  // If there is no geometry it will show a help text
  // Do not show this when on mobile as it uses too much space without
  // being too useful
  if (!beaconGeom && isMobile) {
    return null;
  }

  return (
    <Panel
      open={open}
      setOpen={onClose}
      isRelative
      dataTestId="add-desk-panel"
      className="w-full min-w-fit md:w-[350px]"
      title={
        <div className="flex flex-row items-center space-x-4 p-1">
          {!!beacon &&
            (mqttTopic !== null &&
            mqttBeaconSource !== null &&
            beaconName !== null ? (
              <IdentifyButton
                mqttTopic={mqttTopic}
                mqttSystem={mqttBeaconSource}
                beaconName={beaconName}
              >
                <LuminaireIcon
                  device={{
                    deviceType:
                      (beacon.getProperties().DeviceType?.Name as
                        | DeviceTypes
                        | undefined) ?? DeviceTypes.NODE,
                    desksInUse:
                      beacon.getProperties().NumberOfAvailableDesks ?? 0,
                  }}
                  size={50}
                />
              </IdentifyButton>
            ) : (
              <LuminaireIcon
                device={{
                  deviceType:
                    (beacon.getProperties().DeviceType?.Name as
                      | DeviceTypes
                      | undefined) ?? DeviceTypes.NODE,
                  desksInUse:
                    beacon.getProperties().NumberOfAvailableDesks ?? 0,
                }}
                size={50}
              />
            ))}
          {userRoles?.includes(HasuraPermissions.VIEW_STATUS) ? (
            <Link
              className="text-primary-500"
              data-test-id="desk-name"
              to={`/status?beacon=${beaconName}`}
            >
              {beaconName}
            </Link>
          ) : (
            <div>{beaconName}</div>
          )}
          {canRemoveBeacon && (
            <Button
              className="transition-all p-1 rounded-full bg-primary-200 dark:bg-primary-400 dark:text-white hover:bg-primary-600 dark:hover:bg-primary-700 disabled:hover:bg-primary-200 text-primary-500 hover:text-white disabled:hover:text-primary-500"
              id={`remove-${beaconName}`}
              onClick={() => {
                setIsSaving(true);
                inactivateBeacon(
                  {
                    DesksToDelete: deskFeatures?.map((d) => d.Id),
                    BeaconId: beacon.getProperties().Id,
                    RoomSensorsToDelete: sensors.map((s) => s.Id),
                    Input: {
                      floorId,
                    },
                    AAWActivated,
                  },
                  hasuraHeader(HasuraPermissions.WRITE_DESK),
                )
                  .then((data) => {
                    if (data.error) {
                      setIsSaving(false);
                    } else {
                      toast(data, {
                        message: {
                          type: 'success',
                          content: intl.formatMessage(
                            {
                              id: 'Deleted desk',
                            },
                            { device: beacon.getProperties().Name },
                          ),
                        },
                      });
                    }
                  })
                  .catch(() => {
                    setIsSaving(false);
                  });
                onClose(false);
              }}
              title={intl.formatMessage({
                id: 'Remove',
              })}
            >
              <HiOutlineTrash className="size-5" />
            </Button>
          )}
        </div>
      }
    >
      <div className="flex flex-col gap-2 [&>*:not(:first-child)]:pt-2 [&>*:not(:first-child)]:border-t [&>*]:border-neutral-200 [&>*]:dark:border-neutral-700">
        <Transition show={!!beaconGeom}>
          <DeskConfiguration
            isNew={isNew}
            beaconGeom={beaconGeom}
            beaconName={beaconName}
            onRotate={onRotate}
            onRadiusChange={onRadiusChange}
          />
        </Transition>
        <Transition show={!beaconGeom}>
          <div className="text-sm flex flex-col items-start pt-4 gap-2">
            <div
              data-test-id="geometry-warning"
              className="flex flex-row items-center"
            >
              <HiOutlineExclamationCircle className="shrink-0 mx-auto size-6 text-primary-500 mr-1" />
              {intl.formatMessage({
                id: 'Place the device on the map',
              })}
            </div>
            <div className="flex flex-row items-center">
              <HiOutlineExclamationCircle className="shrink-0 mx-auto size-6 text-primary-500 mr-1" />
              <FormattedMessage id="hold-ctrl-key" />
            </div>
          </div>
        </Transition>
        <Transition show={!!beaconGeom}>
          <DeskSensorActivation
            mqttSystem={mqttBeaconSource ?? MqttSystems.Bluerange}
            mqttTopic={mqttTopic ?? ''}
            sensorsList={sensorsList}
            setSensorsList={setSensorsList}
            intersectedRooms={intersectedRooms}
            drawingDesksLayer={drawingDesksLayer}
            resetDesksToInitialPositions={resetDesksToInitialPositions}
          />
        </Transition>
        <OtherSensorsList beaconGeom={beaconGeom} sensors={otherSensorsList} />
      </div>
      <Panel.Footer>
        <ModalFooter
          action={isNew ? Action.ADD : Action.UPDATE}
          disabled={saveDisabled}
          dataTestId={{
            proceed: 'desk-save',
            cancel: 'desk-cancel',
          }}
          onProceed={() => {
            setIsSaving(true);
            (map.getTarget() as HTMLElement).focus();
            const drawingDeskFeats =
              (
                drawingDesksLayer.olLayer as OLVectorLayer<
                  VectorSource<DrawingDeskFeatures>
                >
              )
                .getSource()
                ?.getFeatures() ?? [];
            if (isNew) {
              // Save Desk rotation and offset in localstorage for future desks
              localStorage.setItem(
                `${LS_DESK_ROTATION}-${floorId}`,
                storedRotation.toString(),
              );
              localStorage.setItem(
                LS_DESK_OFFSETS,
                JSON.stringify(storedOffsets),
              );
              const newRadius = drawingDeskFeats[0]?.getProperties().Radius;
              if (typeof newRadius === 'number') {
                localStorage.setItem(
                  `${LS_DESK_RADIUS}-${floorId}`,
                  newRadius.toString(),
                );
              }

              const deskSensors =
                sensorsList
                  ?.filter((s) => s.active)
                  .map((s) => {
                    const activeFeat = drawingDeskFeats.find(
                      (deskFeature) =>
                        getDeskFeatureIndex(deskFeature) === s.index,
                    );
                    return {
                      Name: s.name,
                      Geometry: {
                        type: GeometryType.POINT,
                        coordinates: activeFeat
                          ?.getGeometry()
                          ?.getCoordinates() ?? [0, 0],
                      },
                      Radius: activeFeat?.getProperties().Radius,
                      SensorId: s.id,
                    };
                  }) ?? [];
              addDesks(
                {
                  BeaconId: beacon?.getProperties().Id ?? 0,
                  BeaconGeometry: {
                    type: GeometryType.POINT,
                    coordinates: beaconGeom?.getCoordinates() ?? [0, 0],
                  },
                  Desks: deskSensors,
                  BeaconFloorId: floorId,
                  Input: {
                    floorId,
                  },
                  AAWActivated,
                },
                hasuraHeader(HasuraPermissions.WRITE_DESK),
              )
                .then((data) => {
                  toast(data, {
                    message: {
                      type: 'success',
                      content: intl.formatMessage(
                        { id: 'Added desk' },
                        {
                          device:
                            data.data?.update_MqttBeacons?.returning[0]?.Name,
                        },
                      ),
                    },
                  });
                  if (data.error) {
                    setIsSaving(false);
                    onClose(false);
                  } else {
                    onClose(true);
                  }
                })
                .catch(() => {
                  setIsSaving(false);
                  onClose(false);
                });
              // open card to create new desk
              setIsSelectingNewBeacon(true);
            } else {
              const inactiveSensorsIds = sensorsList
                ?.filter((s) => !s.active)
                .map((s) => s.id);
              const desksToDelete = deskFeatures
                ?.filter((deskFeat) =>
                  inactiveSensorsIds?.includes(deskFeat.Sensor?.Id ?? 0),
                )
                .map((deskFeat) => deskFeat.Id);

              const desks =
                sensorsList
                  ?.filter((s) => s.active)
                  .map((s) => {
                    const activeDrawingFeat = drawingDeskFeats
                      .filter((f) => isDrawingDeskFeature(f))
                      .find(
                        (deskFeature) =>
                          getDeskFeatureIndex(deskFeature) === s.index,
                      );
                    const currentDeskFeature = deskFeatures?.find(
                      (f) =>
                        activeDrawingFeat &&
                        f.Id === activeDrawingFeat.getProperties().DeskId,
                    );
                    const updatedDesk = {
                      Id: currentDeskFeature?.Id,
                      Name: s.name,
                      Radius: activeDrawingFeat?.getProperties().Radius ?? 0,
                      Geometry: {
                        type: GeometryType.POINT,
                        coordinates: activeDrawingFeat
                          ?.getGeometry()
                          ?.getCoordinates() ?? [0, 0],
                      },
                      SensorId: s.id,
                    };
                    if (!currentDeskFeature) {
                      updatedDesk.Id = undefined;
                    }
                    return updatedDesk;
                  }) ?? [];
              updateDesks(
                {
                  Desks: desks,
                  DesksToDelete: desksToDelete,
                  BeaconId: beacon?.getProperties().Id,
                  BeaconGeometry: {
                    type: GeometryType.POINT,
                    coordinates: beacon?.getGeometry()?.getCoordinates() ?? [
                      0, 0,
                    ],
                  },
                  Input: {
                    floorId,
                  },
                  AAWActivated,
                },
                hasuraHeader(HasuraPermissions.WRITE_DESK),
              )
                .then((data) => {
                  toast(data, {
                    message: {
                      type: 'success',
                      content: intl.formatMessage(
                        { id: 'Updated desk' },
                        {
                          device:
                            data.data?.update_MqttBeacons?.returning[0]?.Name,
                        },
                      ),
                    },
                  });
                  if (data.error) {
                    setIsSaving(false);
                    onClose(false);
                  } else {
                    onClose(true);
                  }
                })
                .catch(() => {
                  setIsSaving(false);
                  onClose(false);
                });
            }
          }}
          onCancel={() => {
            onClose(false);
          }}
        />
      </Panel.Footer>
    </Panel>
  );
}
