import Input from 'generic/components/Form/Input';
import { useIntl } from 'translations/Intl';

import type { Column } from '@tanstack/react-table';
import { HiOutlineMagnifyingGlass, HiOutlineVariable } from 'react-icons/hi2';

interface FilterProps<T> {
  column: Column<T, any>;
  firstValue: string | number;
}

export default function Filter<T>({ column, firstValue }: FilterProps<T>) {
  const intl = useIntl();
  const columnFilterValue = column.getFilterValue();
  const listId = `${column.id}-list-${Math.random()}`;

  if (typeof firstValue === 'string') {
    return (
      <div>
        <datalist id={listId}>
          {Array.from(column.getFacetedUniqueValues().keys())
            // If it has mixed values then make sure only strings are looked at
            .filter((c): c is string => typeof c === 'string')
            .sort((a, b) => a.localeCompare(b))
            .map((value) => (
              <option value={value} key={value + column.id} />
            ))}
        </datalist>
        <Input
          type="text"
          value={(columnFilterValue ?? '') as string}
          placeholder={intl.formatMessage(
            {
              id: 'Search records',
            },
            {
              records: column.getFacetedUniqueValues().size,
            },
          )}
          renderIcon={({ className }) => (
            <HiOutlineMagnifyingGlass className={className} />
          )}
          onChangeValue={(e) => column.setFilterValue(e)}
          list={listId}
        />
      </div>
    );
  }
  if (typeof firstValue === 'number') {
    return (
      <div className="flex space-x-2 w-full">
        <Input
          type="number"
          wrapperClassName="w-full"
          renderIcon={({ className }) => (
            <HiOutlineVariable className={className} />
          )}
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number] | undefined)?.[0] ?? ''}
          onChangeValue={(value) =>
            column.setFilterValue((old: [number, number] | undefined) => [
              value,
              old?.[1],
            ])
          }
          placeholder={`${intl.formatMessage({ id: 'Min' })} ${
            column.getFacetedMinMaxValues()?.[0]
              ? `(${column.getFacetedMinMaxValues()?.[0]})`
              : ''
          }`}
        />
        <Input
          type="number"
          wrapperClassName="w-full"
          renderIcon={({ className }) => (
            <HiOutlineVariable className={className} />
          )}
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number] | undefined)?.[1] ?? ''}
          onChangeValue={(value) =>
            column.setFilterValue((old: [number, number]) => [old[0], value])
          }
          placeholder={`${intl.formatMessage({ id: 'Max' })} ${
            column.getFacetedMinMaxValues()?.[1]
              ? `(${column.getFacetedMinMaxValues()?.[1]})`
              : ''
          }`}
        />
      </div>
    );
  }
}
