import { RoomTypes } from '@/common/types';
import useStore from '@/model/store';

export default function useRoomDeskFilter() {
  const roomTypes = useStore((state) => state.userSettings.roomTypes);

  return {
    Rooms: roomTypes.includes(RoomTypes.MEETING),
    Desks: roomTypes.includes(RoomTypes.DESKS),
  };
}
