import { Themes } from '@/common/types';
import Button from '@/generic/components/Form/Button';
import keycloak from '@/keycloak';
import { BUBBLE_ROUTES, SERVICES_ROUTES } from '@/router/routes';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import HelpTooltip from 'generic/components/HelpTooltip';
import Transition from 'generic/components/Transition';
import DocsBubble from 'generic/components/layout/Header/components/DocsBubble/DocsBubble';
import useStore from 'model/store';
import { HiBars3, HiOutlineChevronDown, HiOutlineXMark } from 'react-icons/hi2';
import { NavLink, type NavLinkProps, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'translations/Intl';
import useDeviceDetect from 'utils/useDeviceDetect';
import PrivateWrapper from '../../PrivateWrapper';
import FilterBar from '../FilterBar';
import NotificationBubble from './components/NotificationBubble';
import UserBubble from './components/UserBubble';

export function NavLinkComp({
  ref,
  ...props
}: NavLinkProps & {
  ref: React.RefObject<HTMLAnchorElement | null>;
}) {
  return (
    <NavLink
      ref={ref}
      className={({ isActive }) =>
        `text-left text-base font-medium hover:text-primary-500 ${
          isActive ? 'cursor-default text-primary-500' : ''
        }`
      }
      {...props}
    />
  );
}

export default function Header(): React.JSX.Element {
  const { isMobile } = useDeviceDetect();
  const user = useStore((state) => state.user);
  const theme = useStore((state) => state.userSettings.theme);
  const organizationLogo = useStore(
    (state) => state.organizationSettings.organizationLogo,
  );
  const setInitialSetupSteps = useStore((state) => state.setInitialSetupSteps);
  const location = useLocation();

  return (
    <Popover className="print:hidden flex-initial sticky top-0 z-40 xl:shadow-sm bg-primary-300 dark:bg-primary-600/90 backdrop-blur bg-opacity-90 xl:bg-transparent xl:dark:bg-transparent">
      <div className="flex justify-between items-center py-4 px-4 xl:px-8 xl:justify-start xl:space-x-10 xl:bg-white/90 xl:dark:bg-neutral-800/90">
        <div className="flex justify-start xl:w-0 xl:flex-1">
          <img
            className="h-8"
            src={
              theme.color === Themes.DARK
                ? organizationLogo.dark
                : organizationLogo.light
            }
            alt="organization logo"
          />
        </div>
        <div className="xl:hidden flex space-x-2 items-center">
          <PopoverButton
            data-test-id="user-menu-button-mobile"
            className="bg-primary-400 dark:bg-primary-700 rounded-md p-2 inline-flex items-center justify-center text-neutral-100 dark:text-neutral-200 dark:hover:text-neutral-300 hover:text-neutral-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
          >
            <span className="sr-only">Open menu</span>
            <HiBars3 className="size-6" aria-hidden="true" id="open-menu" />
          </PopoverButton>
        </div>
        <nav className="hidden xl:flex space-x-2">
          {SERVICES_ROUTES.map((item) =>
            Array.isArray(item.userRole)
              ? item.userRole.every((role) => user?.roles.includes(role))
              : user?.roles.includes(item.userRole) &&
                (item.path === 'admin' &&
                // Mobile has a different menu so hide it there
                !isMobile &&
                !location.pathname.includes('admin') ? (
                  <HelpTooltip
                    stepName="clickedAdmin"
                    key={item.name}
                    content={
                      <NavLink
                        to={`../${item.path}`}
                        onClick={() => setInitialSetupSteps('clickedAdmin')}
                        key={item.name}
                        data-test-id={item.dataTestId}
                        className={({ isActive }) =>
                          `transition-all text-base py-1.5 px-4 rounded-lg hover:bg-neutral-200 dark:hover:bg-neutral-700 ${
                            isActive
                              ? 'bg-primary-200 text-primary-500 cursor-default hover:bg-primary-200 dark:hover:bg-primary-200'
                              : ''
                          }`
                        }
                      >
                        {item.name}
                      </NavLink>
                    }
                  >
                    <FormattedMessage id="Start your setup here" />
                  </HelpTooltip>
                ) : item.subMenu ? (
                  <Popover key={item.name} className="relative">
                    {({ open }) => (
                      <>
                        <NavLink
                          to={`../${item.path}`}
                          key={item.name}
                          data-test-id={item.dataTestId}
                          className={({ isActive }) =>
                            `flex items-center transition-all text-base py-1.5 px-4 rounded-lg hover:bg-neutral-200 dark:hover:bg-neutral-700${
                              isActive
                                ? ' bg-primary-200 text-primary-500 cursor-default hover:bg-primary-200 dark:hover:bg-primary-200'
                                : ''
                            }`
                          }
                        >
                          <div>{item.name}</div>
                          <PopoverButton as="div">
                            <HiOutlineChevronDown
                              className={`${
                                open ? '' : 'text-opacity-70'
                              } cursor-pointer ml-1 size-5 text-primary-500 transition duration-150 ease-in-out`}
                              aria-hidden="true"
                            />
                          </PopoverButton>
                        </NavLink>

                        <PopoverPanel
                          modal
                          transition
                          className="absolute left-1/2 z-10 mt-3 w-screen -translate-x-1/2 transform px-4 sm:px-0 max-w-xs transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
                        >
                          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid gap-8 bg-white dark:bg-neutral-700 p-4 grid-cols-1">
                              {item.subMenu?.map((menu) => (
                                <PrivateWrapper
                                  key={menu.name}
                                  roleRequired={menu.userRole}
                                >
                                  <PopoverButton
                                    data-test-id={menu.dataTestId}
                                    as={NavLinkComp}
                                    to={`../${item.path}${
                                      menu.path && `/${menu.path}`
                                    }`}
                                    className={`${
                                      location.pathname ===
                                      `/${item.path}${
                                        menu.path && `/${menu.path}`
                                      }`
                                        ? 'bg-neutral-50 dark:bg-neutral-800 cursor-default'
                                        : ''
                                    } -m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-neutral-50 hover:dark:bg-neutral-800 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-50`}
                                  >
                                    <div className="flex size-10 rounded bg-primary-500 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                      {menu.icon && (
                                        <menu.icon
                                          className="size-6"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </div>
                                    <div className="ml-4">
                                      <p className="text-sm font-medium text-neutral-900 dark:text-neutral-100">
                                        {menu.name}
                                      </p>
                                      <p className="text-sm text-neutral-500 dark:text-neutral-300">
                                        <FormattedMessage
                                          id={menu.description}
                                        />
                                      </p>
                                    </div>
                                  </PopoverButton>
                                </PrivateWrapper>
                              ))}
                            </div>
                          </div>
                        </PopoverPanel>
                      </>
                    )}
                  </Popover>
                ) : (
                  <NavLink
                    to={`../${item.path}`}
                    key={item.name}
                    data-test-id={item.dataTestId}
                    className={({ isActive }) =>
                      `transition-all text-base py-1.5 px-4 rounded-lg hover:bg-neutral-200 dark:hover:bg-neutral-700${
                        isActive
                          ? ' bg-primary-200 text-primary-500 cursor-default hover:bg-primary-200 dark:hover:bg-primary-200'
                          : ''
                      }`
                    }
                  >
                    {item.name}
                  </NavLink>
                )),
          )}
        </nav>

        <div className="hidden xl:flex items-center justify-end xl:flex-1 xl:w-0 xl:space-x-2">
          <DocsBubble />
          <NotificationBubble />
          {user && <UserBubble userInfos={user} />}
        </div>
      </div>

      <PopoverPanel
        focus
        transition
        className="absolute top-0 inset-x-0 p-2 origin-top-right xl:hidden transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black dark:border dark:border-neutral-900 ring-opacity-5 backdrop-blur bg-opacity-90 bg-white dark:bg-neutral-800 divide-y-2 divide-neutral-50 dark:divide-neutral-900">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <Transition
                show={theme.color === Themes.LIGHT}
                className="flex flex-row space-x-4"
              >
                <img
                  className="max-w-[150px]"
                  src={organizationLogo.light}
                  alt="organization logo"
                />
              </Transition>

              <Transition show={theme.color === Themes.DARK}>
                <img
                  className="max-w-[150px]"
                  src={organizationLogo.dark}
                  alt="organization logo"
                />
              </Transition>

              <div className="-mr-2">
                <PopoverButton className="bg-white dark:bg-neutral-900 rounded-md p-2 inline-flex items-center justify-center text-neutral-400 hover:text-neutral-500 hover:bg-neutral-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                  <span className="sr-only">Close menu</span>
                  <HiOutlineXMark className="size-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
          </div>

          <div
            data-test-id="mobile-routes-menu"
            className="py-6 px-5 space-y-6"
          >
            <nav className="grid grid-cols-2 gap-y-4 gap-x-8">
              {SERVICES_ROUTES.map(({ userRole, dataTestId, name, path }) => (
                <PrivateWrapper key={name} roleRequired={userRole}>
                  <PopoverButton
                    data-test-id={dataTestId}
                    as={NavLinkComp}
                    to={`../${path}`}
                  >
                    {name}
                  </PopoverButton>
                </PrivateWrapper>
              ))}
            </nav>
          </div>

          <div className="py-6 px-5 space-y-2">
            <DocsBubble />
            <NotificationBubble />
            {BUBBLE_ROUTES.map(({ path, userRole, name, dataTestId }) => (
              <div key={name}>
                <PrivateWrapper roleRequired={userRole}>
                  <PopoverButton
                    data-test-id={dataTestId}
                    as={NavLinkComp}
                    to={`../${path}`}
                  >
                    <FormattedMessage id={name} />
                  </PopoverButton>
                </PrivateWrapper>
              </div>
            ))}
            <div>
              <Button
                onClick={() => keycloak.logout()}
                className="text-base font-medium hover:text-primary-500"
              >
                <FormattedMessage id="Logout" />
              </Button>
            </div>
          </div>
        </div>
      </PopoverPanel>
      <div className="xl:px-8 relative overflow-hidden -mt-1 xl:mt-0 xl:overflow-visible xl:bg-neutral-200 xl:dark:bg-neutral-700/80 -z-10 backdrop-blur xl:bg-opacity-80 xl:border-t border-neutral-300 dark:border-neutral-700">
        <FilterBar />
      </div>
    </Popover>
  );
}
