import keycloak from '@/keycloak';
import useStore from '@/model/store';
import { authExchange } from '@urql/exchange-auth';
import { requestPolicyExchange } from '@urql/exchange-request-policy';
import { retryExchange } from '@urql/exchange-retry';
import {
  CloseCode,
  type SubscribePayload,
  createClient as createWSClient,
} from 'graphql-ws';
import { useMemo } from 'react';
import {
  cacheExchange,
  createClient,
  fetchExchange,
  subscriptionExchange,
} from 'urql';

const timeBeforeExpiry = 30;
// the socket close timeout due to token expiry
let tokenExpiryInterval: NodeJS.Timeout;

// See: https://github.com/FormidableLabs/urql/discussions/1806
// https://github.com/enisdenjo/graphql-ws/blob/7642666be4e1e1e6d6394fbadb779f83a461cd7c/README.md
const useWSClient = () => {
  const subscriptionPermission = useStore(
    (state) => state.subscriptionPermission,
  );
  return useMemo(
    () =>
      createWSClient({
        shouldRetry: () => true,
        url: `wss://${import.meta.env.VITE_BACKEND}`,
        connectionParams: async () => ({
          headers: {
            authorization: `Bearer ${useStore.getState().token}`,
            'x-hasura-role': subscriptionPermission,
          },
        }),
        on: {
          connected: (socket) => {
            // Clear timeout on every connect for debouncing the expiry
            clearTimeout(tokenExpiryInterval);

            const tokenTimeout =
              new Date((keycloak.tokenParsed?.exp ?? 1) * 1000).getTime() -
              new Date().getTime();

            // Should refresh token first and then set a new one
            // for subscription in order to have minimal interruption
            tokenExpiryInterval = setTimeout(() => {
              if ((socket as WebSocket).readyState === WebSocket.OPEN)
                (socket as WebSocket).close(CloseCode.Forbidden, 'Forbidden');
              // Add a 10 second delay as this way we are sure the new token is available
            }, tokenTimeout);
          },
        },
      }),
    [subscriptionPermission],
  );
};

export default function useClient() {
  const wsClient = useWSClient();

  return useMemo(
    () =>
      createClient({
        url: `https://${import.meta.env.VITE_BACKEND}`,
        exchanges: [
          requestPolicyExchange({}),
          cacheExchange,
          retryExchange({
            initialDelayMs: 1000,
            maxDelayMs: 15000,
            randomDelay: true,
            maxNumberAttempts: 2,
          }),
          authExchange(async (utils) => {
            // Called on initial launch, fetch the auth state from zustand
            let { token } = useStore.getState();

            return {
              addAuthToOperation(operation) {
                if (token) {
                  const additionalHeaders =
                    typeof operation.context.fetchOptions === 'function'
                      ? (operation.context.fetchOptions().headers ?? {})
                      : (operation.context.fetchOptions?.headers ?? {});

                  return utils.appendHeaders(operation, {
                    'x-hasura-role': 'read',
                    ...(additionalHeaders as { [key: string]: string }),
                    // Set "Authorization" last to make sure the token is always correct
                    Authorization: `Bearer ${token}`,
                  });
                }
                return operation;
              },
              willAuthError() {
                if (keycloak.isTokenExpired(timeBeforeExpiry)) {
                  return true;
                }

                return false;
              },
              didAuthError(error) {
                console.error('didAuthError', error);

                // Check if the error was an auth error
                return error.graphQLErrors.some(
                  (e) => e.extensions.code === 'invalid-jwt',
                );
              },
              async refreshAuth() {
                // Called when auth error has occurred
                // The token is refreshed in AuthenticationProvider.tsx
                await keycloak.updateToken(timeBeforeExpiry);

                token = useStore.getState().token;
              },
            };
          }),
          fetchExchange,
          subscriptionExchange({
            forwardSubscription: (request) => ({
              subscribe: (sink) => ({
                unsubscribe: wsClient.subscribe(
                  request as SubscribePayload,
                  sink,
                ),
              }),
            }),
          }),
        ],
      }),
    [wsClient],
  );
}
