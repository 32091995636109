import { FormattedMessage } from 'translations/Intl';

import { MqttSystems, useSetSceneIdentifyMutation } from '@/graphql/types';
import SetSceneButton from '@/pages/StatusView/components/RowSubComponent/components/BluerangeMqttAction/components/SetSceneButton';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import { HiOutlineLightBulb } from 'react-icons/hi2';

interface SetSceneIdentifyButtonProps {
  mqttTopic: string;
}

export default function SetSceneIdentifyButton({
  mqttTopic,
}: SetSceneIdentifyButtonProps): React.JSX.Element {
  const hasuraHeader = useHasuraHeader();
  const [, setSceneIdentifyMutation] = useSetSceneIdentifyMutation();

  return (
    <SetSceneButton
      renderIcon={() => <HiOutlineLightBulb />}
      title="Set scene identify"
      timeout
      onProceed={() =>
        setSceneIdentifyMutation(
          {
            Topics: [mqttTopic],
            MqttSystem: MqttSystems.Mda2,
          },
          hasuraHeader(HasuraPermissions.VIEW_ADMIN),
        )
      }
    >
      <FormattedMessage id="Identify scene" />
    </SetSceneButton>
  );
}
