import { FormattedMessage, useIntl } from 'translations/Intl';

import { Action } from '@/common/types';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import {
  type GroupInput,
  type KeycloakUsersQuery,
  useUpdateKeycloakUsersMutation,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import type { Row } from '@tanstack/react-table';
import { useState } from 'react';

import type { KeycloakTableUser } from '../AddUserModal/AddUserModal';
import GroupsInput from '../AddUserModal/GroupsInput';

interface UpdateUserGroupModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  users: Row<KeycloakTableUser>[];
}

export type KeycloakUser =
  KeycloakUsersQuery['GetUsers']['groupMembers'][number];

export default function UpdateUserGroupModal({
  open,
  setOpen,
  users,
}: UpdateUserGroupModalProps): React.JSX.Element {
  const intl = useIntl();
  const [groupsToAdd, setGroupsToAdd] = useState<GroupInput[]>([]);
  const [groupsToDelete, setGroupsToDelete] = useState<GroupInput[]>([]);

  const [{ fetching }, updateUserGroup] = useUpdateKeycloakUsersMutation();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      action={Action.UPDATE}
      open={open}
      setShowModal={setOpen}
      footer={
        <ModalFooter
          disabled={false}
          proceed={intl.formatMessage({
            id: 'Confirm',
          })}
          isLoading={fetching}
          action={Action.UPDATE}
          onProceed={() => {
            updateUserGroup(
              {
                UserInput: {
                  users: users.map((r) => ({
                    id: r.original.id,
                  })),
                  groupsToAdd,
                  groupsToDelete,
                },
              },
              hasuraHeader(HasuraPermissions.VIEW_USERGROUP),
            )
              .then((data) =>
                toast(data, {
                  message: {
                    type: 'success',
                    content: intl.formatMessage(
                      { id: 'Updated user groups for users' },
                      {
                        count: users.length,
                      },
                    ),
                  },
                }),
              )
              .finally(() => {
                setOpen(false);
                setGroupsToAdd([]);
                setGroupsToDelete([]);
                for (const user of users) {
                  user.getIsSelected() ? user.toggleSelected() : false;
                }
              });
          }}
          onCancel={() => {
            setOpen(false);
            setGroupsToAdd([]);
            setGroupsToDelete([]);
          }}
        />
      }
    >
      <div className="flex flex-col">
        <div>
          <div>
            <FormattedMessage id="Groups to add" />
          </div>
          <GroupsInput
            dataTestId="groups-to-add"
            groupsToAdd={groupsToAdd}
            setGroupsToAdd={setGroupsToAdd}
            groupsToDelete={[]}
            setGroupsToDelete={() => null}
          />
        </div>
        <div>
          <div>
            <FormattedMessage id="Groups to delete" />
          </div>
          <GroupsInput
            dataTestId="groups-to-delete"
            groupsToAdd={groupsToDelete}
            setGroupsToAdd={setGroupsToDelete}
            groupsToDelete={[]}
            setGroupsToDelete={() => null}
          />
        </div>
      </div>
    </Modal>
  );
}
