import type {
  DrawingDeskFeatures,
  OutlineFeatures,
  RotateAnchorFeatures,
} from '@/common/types';
import { LS_DESK_ROTATION } from '@/constants';
import type { Feature } from 'ol';
import type MapBrowserEvent from 'ol/MapBrowserEvent';
import { getCenter } from 'ol/extent';
import type Point from 'ol/geom/Point';
import type Polygon from 'ol/geom/Polygon';
import Pointer from 'ol/interaction/Pointer';
import type VectorSource from 'ol/source/Vector';

import type GeometryLayer from 'generic/layers/GeometryLayer';
import { isPoint } from 'generic/layers/GeometryLayer';
import type { FloorMapFeaturesQuery } from 'graphql/types';
import type Geometry from 'ol/geom/Geometry';
import { getDeskFeatureIndex } from './moveDesk';
import RotateEvent, { RotateEventType } from './rotateEvent';

interface RotateBeaconType {
  source: VectorSource<Feature<Point>>;
  drawingDesksSource: VectorSource<Feature<Point>>;
  beaconsLayer: GeometryLayer<FloorMapFeaturesQuery['MqttBeacons'][number]>;
  // TODO: OL 9.2.5 requires this hack of adding Feature<Geometry> otherwise there are type errors
  drawingBeaconSource: VectorSource<Feature<Geometry | Point>>;
}

class RotateBeacon extends Pointer {
  anchor: number[] | null;

  dragging: boolean;

  drawingDesksSource: RotateBeaconType['drawingDesksSource'];

  featsToRotate: Feature<Point | Polygon>[];

  feature?: RotateAnchorFeatures;

  initialRotation: number;

  beaconsLayer: RotateBeaconType['beaconsLayer'];

  // TODO: OL 9.2.5 requires this hack of adding Feature<Geometry> otherwise there are type errors
  drawingBeaconSource: VectorSource<Feature<Geometry | Point>>;

  outlineFeat: OutlineFeatures | null;

  rotateAttribute = 'rotation';

  source: VectorSource<Feature<Point>>;

  drawingBeaconFeat: Feature<Point> | null = null;

  constructor(options: RotateBeaconType) {
    super();
    this.anchor = null;
    this.dragging = false;
    this.source = options.source;
    this.drawingDesksSource = options.drawingDesksSource;
    this.beaconsLayer = options.beaconsLayer;
    this.drawingBeaconSource = options.drawingBeaconSource;
    this.initialRotation = 0;
    this.featsToRotate = [];
    this.feature = undefined;
    this.outlineFeat = null;
  }

  handleDownEvent(evt: MapBrowserEvent<PointerEvent>): boolean {
    this.dragging = false;
    this.feature = evt.map.forEachFeatureAtPixel(evt.pixel, (f) => {
      const feature = f as RotateAnchorFeatures;

      if (this.source.getFeatures().indexOf(feature) > -1) {
        return feature;
      }

      return undefined;
    });
    if (this.feature) {
      this.outlineFeat = this.feature.getProperties().outlineFeature;
      const outlineExtent = this.outlineFeat.getGeometry()?.getExtent();
      this.anchor = outlineExtent ? getCenter(outlineExtent) : null;
      const featIds = this.outlineFeat.getProperties().relatedDesks;
      this.featsToRotate = [
        this.outlineFeat,
        this.outlineFeat.getProperties().relatedBeacon,
        ...(
          this.drawingDesksSource.getFeatures() as DrawingDeskFeatures[]
        ).filter((f) => featIds.includes(getDeskFeatureIndex(f))),
      ];
    }
    const selectedFeat = [
      this.beaconsLayer.getSelectedFeature(),
      ...this.drawingBeaconSource.getFeatures(),
    ].find((f): f is Feature<Point> => {
      const geometry = f?.getGeometry();

      return !!geometry && isPoint(geometry);
    });

    this.anchor = selectedFeat?.getGeometry()?.getCoordinates() ?? null;

    if (this.anchor && this.feature) {
      this.feature.set(
        this.rotateAttribute,
        this.feature.get(this.rotateAttribute) || 0,
      );
      for (const f of this.featsToRotate) {
        f.set(this.rotateAttribute, f.get(this.rotateAttribute) || 0);
      }

      if (
        !evt.coordinate[0] ||
        !evt.coordinate[1] ||
        !this.anchor[0] ||
        !this.anchor[1]
      ) {
        // Do not rotate when mising infos
        return false;
      }
      // rotation between clicked coordinate and feature center
      this.initialRotation =
        Math.atan2(
          evt.coordinate[1] - this.anchor[1],
          evt.coordinate[0] - this.anchor[0],
        ) + this.feature.get(this.rotateAttribute);
    }

    if (this.feature) {
      this.dispatchEvent(
        new RotateEvent(RotateEventType.START, this.feature, evt),
      );
      return true;
    }

    return false;
  }

  handleDragEvent(evt: MapBrowserEvent<PointerEvent>): void {
    this.dragging = true;

    const floorId = this.get('floorId'); // floorId is set in a useEffect in 'FloorRoomMap'
    const localStorageRotation =
      floorId && localStorage.getItem(`${LS_DESK_ROTATION}-${floorId}`);

    if (this.feature && this.anchor) {
      if (
        !evt.coordinate[0] ||
        !evt.coordinate[1] ||
        !this.anchor[0] ||
        !this.anchor[1]
      ) {
        // Do not rotate when mising infos
        return;
      }
      const rotation = Math.atan2(
        evt.coordinate[1] - this.anchor[1],
        evt.coordinate[0] - this.anchor[0],
      );

      const rotationDiff = this.initialRotation - rotation;
      const geomRotation =
        rotationDiff - this.feature.get(this.rotateAttribute);

      this.feature.getGeometry()?.rotate(-geomRotation, this.anchor);
      for (const f of this.featsToRotate) {
        f?.getGeometry()?.rotate(-geomRotation, this.anchor);
      }

      const [drawingBeaconFeat] = this.drawingBeaconSource.getFeatures();
      if (drawingBeaconFeat) {
        drawingBeaconFeat.set(this.rotateAttribute, rotationDiff);
        drawingBeaconFeat.changed();
      }

      this.feature.set(this.rotateAttribute, rotationDiff);
      for (const f of this.featsToRotate) {
        f.set(
          this.rotateAttribute,
          rotationDiff +
            (localStorageRotation
              ? Number.parseFloat(localStorageRotation)
              : 0),
        );
        f.changed();
      }
    }
  }

  handleUpEvent(evt: MapBrowserEvent<PointerEvent>): boolean {
    if (this.outlineFeat) {
      this.dispatchEvent(
        new RotateEvent(
          RotateEventType.END,
          this.outlineFeat.getProperties().relatedBeacon,
          evt,
          this.outlineFeat
            .getProperties()
            .relatedBeacon.get(this.rotateAttribute),
        ),
      );
    }
    return false;
  }
}

export default RotateBeacon;
