import { FormattedMessage } from 'translations/Intl';

import format from '@/utils/format';
import { isSameDay } from 'date-fns';

interface DurationMessageProps {
  start: Date;
  end: Date;
  isPersonal?: boolean;
}

export default function DurationMessage({
  start,
  end,
  isPersonal = false,
}: DurationMessageProps) {
  return (
    <FormattedMessage
      id={isPersonal ? 'Reserved for you from to' : 'Reserved from to'}
      values={{
        from: format(
          start,
          isSameDay(start, end) && isSameDay(start, new Date()) ? 'p' : 'PPp',
        ),
        to: format(end, isSameDay(start, end) ? 'p' : 'PPp'),
      }}
    />
  );
}
