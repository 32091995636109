import keycloak from '@/keycloak';
import { HasuraPermissions } from '@/utils/graphql/useHasuraHeaders';
import useStore from 'model/store';
import type { ReactNode } from 'react';

interface PrivateWrapperProps {
  roleRequired: HasuraPermissions | HasuraPermissions[];
  children: ReactNode;
}

export default function PrivateWrapper({
  roleRequired,
  children,
}: PrivateWrapperProps) {
  const userRoles = useStore((state) => state.user)?.roles;

  const isAuthorized = (role: HasuraPermissions) =>
    (!!keycloak.token && keycloak.hasResourceRole(role)) ||
    // Special case for MDA2 role, as it is "virtual" and calculated based on the number of MDA2 beacons
    (role === HasuraPermissions.MDA2 &&
      userRoles?.includes(HasuraPermissions.MDA2));

  const authorized = Array.isArray(roleRequired)
    ? roleRequired.every((role) => isAuthorized(role))
    : isAuthorized(roleRequired);

  if (authorized) {
    return children;
  }

  return null;
}
