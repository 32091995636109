import type { Route } from '@/router/routes';
import { useLocation } from 'react-router-dom';

interface LocationWrapperProps {
  allowedLocations: Route['path'][];
  children: React.JSX.Element;
}

export default function LocationWrapper({
  allowedLocations,
  children,
}: LocationWrapperProps) {
  const location = useLocation();

  return allowedLocations.includes(
    // Use substring as Route['path'] starts without a '/'
    location.pathname.substring(1) as Route['path'],
  )
    ? children
    : null;
}
