import useDecodedLocation from '@/utils/useDecodedLocation';

interface EmbeddedWrapperProps {
  alternative?: React.JSX.Element;
  children: React.JSX.Element | null | false;
}

export default function EmbeddedWrapper({
  alternative,
  children,
}: EmbeddedWrapperProps): React.JSX.Element | null {
  const embedded = useDecodedLocation('embedded');

  if (embedded === 'true') {
    if (alternative) return alternative;
    return null;
  }

  if (!children) return null;

  return children;
}
