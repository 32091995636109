import { hexToBase64Image } from '@/utils/hexToBase64';
import { load } from 'ol/Image';
import ImageLayer from 'ol/layer/Image';
import Projection from 'ol/proj/Projection';
import ImageSource from 'ol/source/Image';
import { createLoader } from 'ol/source/static';
import { BASE_LAYER_NAME } from '../../constants';
import Layer, { type LayerOptions } from './Layer';
import SourceChangeEvent, { SourceChangeEventType } from './SourceChangeEvent';

interface BaseLayerOptions extends LayerOptions {
  olLayer: ImageLayer<ImageSource>;
  extent: number[];
  projection: Projection;
}

class BaseLayer extends Layer {
  extent: number[] = [0, 0, 0, 0];
  projection: Projection = new Projection({
    code: 'static-image',
    units: 'pixels',
    extent: this.extent,
  });

  constructor() {
    super({
      ...({
        olLayer: new ImageLayer(),
        name: BASE_LAYER_NAME,
      } as BaseLayerOptions),
    });
  }

  setImageSource(floorplan: string): void {
    const im = new Image();
    im.src = floorplan;

    im.onload = () => {
      this.extent = [0, 0, im.width, im.height];
      this.projection = new Projection({
        code: 'static-image',
        units: 'pixels',
        extent: this.extent,
      });

      this.olLayer.setSource(
        new ImageSource({
          loader: createLoader({
            url: floorplan,
            imageExtent: this.extent,
            load,
          }),
        }),
      );
      this.dispatchEvent(
        new SourceChangeEvent(
          SourceChangeEventType.CHANGE,
          im.width,
          im.height,
        ),
      );
    };
  }

  setDefault(image: string): void {
    this.setImageSource(image);
  }

  setImage(image: string): void {
    if (image) {
      this.setImageSource(hexToBase64Image(image));
    }
  }
}

export default BaseLayer;
