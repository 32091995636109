import Subtitle from 'generic/components/Subtitle/Subtitle';
import { FormattedMessage, useIntl } from 'translations/Intl';

import { Action } from '@/common/types';
import Input from '@/generic/components/Form/Input';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import {
  type OrganizationsQuery,
  useDeleteOrganizationMutation,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import { useState } from 'react';
import { HiOutlineTrash } from 'react-icons/hi2';

export type Organization = OrganizationsQuery['Organizations'][number];

interface RemoveOrganizationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  organizationToRemove?: Organization;
  setOrganizationToRemove: (organizationToRemove?: Organization) => void;
}

export default function RemoveOrganizationModal({
  open,
  setOpen,
  organizationToRemove,
  setOrganizationToRemove,
}: RemoveOrganizationModalProps): React.JSX.Element {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [confirmDelete, setConfirmDelete] = useState('');
  const [{ fetching: loadingDeleteOrganization }, deleteOrganization] =
    useDeleteOrganizationMutation();

  const resetValues = () => {
    setOpen(false);
    setOrganizationToRemove(undefined);
    setConfirmDelete('');
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      action={Action.REMOVE}
      open={open}
      setShowModal={resetValues}
      footer={
        <ModalFooter
          disabled={confirmDelete !== organizationToRemove?.Name}
          proceed={intl.formatMessage({
            id: 'Confirm',
          })}
          isLoading={loadingDeleteOrganization}
          action={Action.REMOVE}
          onProceed={() => {
            if (confirmDelete === organizationToRemove?.Name) {
              deleteOrganization(
                {
                  Organization: organizationToRemove.Name,
                  OrganizationId: organizationToRemove.Id,
                },
                hasuraHeader(HasuraPermissions.READ_ALL),
              ).then((data) => {
                toast(data, {
                  message: {
                    type: 'success',
                    content: intl.formatMessage(
                      { id: 'Deleted organization' },
                      { name: organizationToRemove.Name },
                    ),
                  },
                });
                resetValues();
              });
            }
          }}
          onCancel={resetValues}
        />
      }
    >
      <Subtitle
        value={intl.formatMessage({
          id: 'Are you sure to remove this organization?',
        })}
        className="text-base pb-2"
      />

      <p className="pb-2">
        <FormattedMessage
          id="Enter the organization name {name} in order to delete it"
          values={{
            name: <b>{organizationToRemove?.Name}</b>,
          }}
        />
      </p>

      <Input
        type="text"
        value={confirmDelete}
        id="Remove organization"
        renderIcon={({ className }) => <HiOutlineTrash className={className} />}
        onChangeValue={(input: string) => setConfirmDelete(input)}
        placeholder={organizationToRemove?.Name ?? ''}
        required
      />
    </Modal>
  );
}
