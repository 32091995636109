import {
  ModuleType,
  isDeskInUseFeature,
  isRoomBeaconFeature,
  isRoomFeature,
} from 'common/types';
import Transition from 'generic/components/Transition/Transition';
import type { DeskInUseFeatureType } from 'generic/layers/DeskInUseLayer';
import type { RoomBeaconFeatureType } from 'generic/layers/RoomBeaconLayer';
import type { RoomInUseFeatureType } from 'generic/layers/RoomInUseLayer';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';

import DurationMessage from '@/generic/components/DurationMessage';
import PopupBody from '@/generic/components/layout/PopupBody';
import { formattedDistance, lower, upper } from '@/utils/date';
import { isAfter, subMinutes } from 'date-fns';
import { useMemo } from 'react';
import { FaUserLock } from 'react-icons/fa';
import {
  HiOutlineCheckBadge,
  HiOutlineClock,
  HiOutlineExclamationTriangle,
  HiOutlineLockClosed,
  HiOutlineUser,
  HiShieldExclamation,
  HiXCircle,
} from 'react-icons/hi2';

interface OccupancyPopupProps {
  hoveredFeature:
    | RoomBeaconFeatureType
    | DeskInUseFeatureType
    | RoomInUseFeatureType;
  warmMinutesPolicy: number;
  additionalContent?: React.JSX.Element;
}

const renderStatus = (
  value: number,
  lastHeartbeat: string,
  warmMinutesPolicy: number,
) => {
  if (value === 0) {
    // Warm
    if (
      isAfter(
        new Date(lastHeartbeat),
        subMinutes(new Date(), warmMinutesPolicy),
      )
    ) {
      return <FormattedMessage id="warm" />;
    }
  } else {
    // Hot
    return <FormattedMessage id="used" />;
  }
  // Cold
  return <FormattedMessage id="free" />;
};

export const freeIcon = (
  <div className="bg-green-200 flex-shrink-0 flex items-center justify-center size-8 rounded-full mr-2">
    <HiOutlineCheckBadge className="mx-auto size-6 text-green-500" />
  </div>
);

export const usedIcon = (
  <div className="bg-red-200 flex-shrink-0 flex items-center justify-center size-8 rounded-full mr-2">
    <HiXCircle className="mx-auto size-6 text-red-500" />
  </div>
);

export const warmIcon = (
  <div className="bg-yellow-200 flex-shrink-0 flex items-center justify-center size-8 rounded-full mr-2">
    <HiOutlineClock className="mx-auto size-6 text-yellow-500" />
  </div>
);

export const offlineIcon = (
  <div className="bg-neutral-300 flex-shrink-0 flex items-center justify-center size-8 rounded-full mr-2">
    <HiOutlineExclamationTriangle className="mx-auto size-6 text-neutral-700" />
  </div>
);

export const reservedIcon = (
  <div className="bg-primary-300 flex-shrink-0 flex items-center justify-center size-8 rounded-full mx-2">
    <HiOutlineLockClosed className="mx-auto size-6 text-primary-700" />
  </div>
);

export const incognitoIcon = (
  <div className="bg-neutral-300 flex-shrink-0 flex items-center justify-center size-8 rounded-full mx-2">
    <HiShieldExclamation className="mx-auto size-6 text-neutral-700" />
  </div>
);

export const personalIcon = (
  <div className="bg-primary-300 flex-shrink-0 flex items-center justify-center size-8 rounded-full mx-2">
    <HiOutlineUser className="mx-auto size-6 text-primary-700" />
  </div>
);

export const reservedPersonalIcon = (
  <div className="bg-primary-300 flex-shrink-0 flex items-center justify-center size-8 rounded-full mx-2">
    <FaUserLock className="mx-auto size-6 text-primary-700" />
  </div>
);

const renderStatusIcon = (
  value: number,
  lastUpdate: string,
  warmMinutesPolicy: number,
  offline: boolean,
  isReserved: boolean,
  isPrivate: boolean,
  isPersonal = false,
) => {
  if (isPrivate) {
    return incognitoIcon;
  }
  if (isReserved) {
    return isPersonal ? reservedPersonalIcon : reservedIcon;
  }
  if (offline) {
    return offlineIcon;
  }
  if (value === 0) {
    // Warm
    if (
      isAfter(new Date(lastUpdate), subMinutes(new Date(), warmMinutesPolicy))
    ) {
      return warmIcon;
    }
  } else {
    // Hot
    return usedIcon;
  }
  // Cold
  return freeIcon;
};

const renderRoomStatusIcon = (
  occupancy: number,
  offline: boolean,
  isReserved: boolean,
  isPrivate: boolean,
  isPersonal = false,
) => {
  if (isPrivate) {
    return incognitoIcon;
  }
  if (isReserved) {
    return isPersonal ? reservedPersonalIcon : reservedIcon;
  }
  if (offline) {
    return offlineIcon;
  }
  if (occupancy > 0) {
    return usedIcon;
  }
  return freeIcon;
};

const renderRoomBeaconIcon = (
  inUse: boolean,
  offline: boolean,
  isPrivate: boolean,
) => {
  if (isPrivate) {
    return incognitoIcon;
  }
  if (offline) {
    return offlineIcon;
  }
  if (inUse) {
    return usedIcon;
  }
  return freeIcon;
};

function OccupancyPopup({
  hoveredFeature,
  warmMinutesPolicy,
  additionalContent,
}: OccupancyPopupProps): React.JSX.Element | null {
  const offline = useMemo(
    () =>
      isDeskInUseFeature(hoveredFeature)
        ? !!hoveredFeature.getProperties().Sensor?.MqttBeacon.IsOffline
        : !!hoveredFeature.getProperties().IsOffline,
    [hoveredFeature],
  );

  // Desks
  if (isDeskInUseFeature(hoveredFeature)) {
    const sensor = hoveredFeature.getProperties().Sensor;
    const lastActivity = sensor
      ? formattedDistance(new Date(sensor.UpdatedAt))
      : new Date();
    const reservations = hoveredFeature.getProperties().DeskReservations;

    // Go through all dates and find the lowest date after now
    const nextReserved = reservations
      .map((r) => ({
        start: lower(r.Duration),
        end: upper(r.Duration),
        cancelled: r.Cancelled,
        isPersonal: r.IsPersonal,
      }))
      .filter((d) => d.end >= new Date() && !d.cancelled)
      .sort((a, b) => a.start.getTime() - b.start.getTime())[0];

    const reservedNow =
      nextReserved &&
      nextReserved.start < new Date() &&
      nextReserved.end > new Date();
    return (
      <PopupBody
        title={hoveredFeature.getProperties().Name}
        renderIcon={() =>
          renderStatusIcon(
            sensor?.Value ?? 0,
            sensor?.UpdatedAt,
            warmMinutesPolicy,
            offline,
            !!reservedNow,
            sensor?.IsPrivate ?? false,
            nextReserved?.isPersonal ?? undefined,
          )
        }
      >
        {offline && (
          <div className="flex flex-col">
            <FormattedMessage id="The device may be offline" />
            <br />
            {sensor?.UpdatedAt && (
              <div className="flex items-center">
                <FormattedMessage id="Last activity" />:{' '}
                {lastActivity.toString()}
              </div>
            )}
          </div>
        )}
        {!sensor?.IsPrivate ? (
          <div className="flex flex-col space-y-2">
            <div className="flex">
              {renderStatus(
                sensor?.Value ?? 0,
                sensor?.UpdatedAt,
                warmMinutesPolicy,
              )}
              &nbsp;
              {sensor?.UpdatedAt && (
                <div className="flex items-center">
                  ({lastActivity.toString()})
                </div>
              )}
            </div>
            {nextReserved?.start && (
              <DurationMessage
                start={nextReserved.start}
                end={nextReserved.end}
                isPersonal={nextReserved.isPersonal ?? undefined}
              />
            )}
            <div>{additionalContent}</div>
          </div>
        ) : (
          <FormattedMessage id="Private room" />
        )}
      </PopupBody>
    );
  }

  // Rooms
  if (isRoomFeature(hoveredFeature)) {
    const roomOccupancy = hoveredFeature.getProperties().RoomOccupancy;
    const roomOccupancyPercentage =
      hoveredFeature.getProperties().RoomOccupancyPercentage;
    const lastActivity = formattedDistance(
      new Date(hoveredFeature.getProperties().UpdatedAt),
    );
    const isPrivate = hoveredFeature.getProperties().IsPrivate;
    const reservation = hoveredFeature.getProperties().NextReservation;
    const reservationStart = reservation ? lower(reservation) : new Date();
    const reservationEnd = reservation ? upper(reservation) : new Date();

    const isReserved =
      !!reservation &&
      reservationStart < new Date() &&
      reservationEnd > new Date();
    const isPersonal = hoveredFeature.getProperties().IsPersonal;
    const lineInValue = hoveredFeature.getProperties().LineInValue;
    const lineOutValue = hoveredFeature.getProperties().LineOutValue;
    const capacity = hoveredFeature.getProperties().Capacity;

    return (
      <PopupBody
        title={hoveredFeature.getProperties().Name}
        renderIcon={() =>
          renderRoomStatusIcon(
            roomOccupancy,
            offline,
            isReserved,
            isPrivate,
            isPersonal,
          )
        }
      >
        {offline && (
          <div className="flex flex-col">
            <FormattedMessage id="The device may be offline" />
            <br />
            {hoveredFeature.getProperties().UpdatedAt && (
              <div className="flex items-center">
                <FormattedMessage id="Last activity" />: {lastActivity}
              </div>
            )}
          </div>
        )}
        {!isPrivate ? (
          <div className="space-y-2">
            <div>
              {!hoveredFeature.getProperties().IsPresenceSensor &&
                typeof roomOccupancy === 'number' && (
                  <div>
                    <FormattedMessage id="Occupancy" />:{' '}
                    {typeof roomOccupancy === 'number'
                      ? `${roomOccupancy}${capacity > 0 ? `/${capacity}` : ''}`
                      : 0}{' '}
                    {typeof roomOccupancyPercentage === 'number'
                      ? `(${roomOccupancyPercentage}%)`
                      : ''}
                  </div>
                )}
              <div className="flex items-center gap-1">
                <div>
                  {roomOccupancy > 0 ? (
                    <FormattedMessage id="used" />
                  ) : (
                    <FormattedMessage id="free" />
                  )}
                </div>
                {hoveredFeature.getProperties().UpdatedAt && (
                  <div>({lastActivity})</div>
                )}
              </div>
              {reservation && (
                <DurationMessage
                  start={reservationStart}
                  end={reservationEnd}
                  isPersonal={isPersonal}
                />
              )}
              <Transition show={!!(lineInValue && lineOutValue)}>
                <div className="flex">
                  <FormattedMessage id="People who entered" />:{' '}
                  {String.fromCharCode(0x2191)} {lineInValue}
                </div>
                <div className="flex">
                  <FormattedMessage id="People who left" />:{' '}
                  {String.fromCharCode(0x2193)} {lineOutValue}
                </div>
              </Transition>
            </div>
            <div>{additionalContent}</div>
          </div>
        ) : (
          <FormattedMessage id="Private room" />
        )}
      </PopupBody>
    );
  }

  // Roombeacons
  if (isRoomBeaconFeature(hoveredFeature)) {
    const { LastHeartbeat, Sensors } = hoveredFeature.getProperties();

    const isPrivate = Sensors.some((s) => s.IsPrivate);
    const lineTypes = [
      ModuleType.LINECOUNT,
      ModuleType.LINECOUNT_IN,
      ModuleType.LINECOUNT_OUT,
    ];
    const inUse = Sensors.filter(
      (s) => !lineTypes.includes(s.SensorType.Name as ModuleType),
    ).some((s) => (s.Value ?? 0) > 0);
    const notLineRoomSensors = Sensors.filter(
      (s) => !(lineTypes as string[]).includes(s.SensorType.Name),
    );
    const lineRoomSensors = Sensors.filter((s) =>
      (lineTypes as string[]).includes(s.SensorType.Name),
    );

    return (
      <PopupBody
        title={hoveredFeature.getProperties().Name}
        renderIcon={() => renderRoomBeaconIcon(inUse, offline, isPrivate)}
      >
        {offline && (
          <div className="flex flex-col">
            <FormattedMessage id="The device may be offline" />
            <br />
            <div className="flex items-center">
              <FormattedMessage id="Last activity" />:{' '}
              {formattedDistance(new Date(LastHeartbeat))}
            </div>
          </div>
        )}
        {!isPrivate ? (
          <div className="flex flex-col gap-1">
            {notLineRoomSensors.map((s) => (
              <div className="flex" key={s.Id}>
                <FormattedMessage id={s.SensorType.Name as IntlMessageKeys} />
                {s.Index > 0 ? ` ${s.Index}` : ''}: {s.Value} (
                {formattedDistance(new Date(s.UpdatedAt))})
              </div>
            ))}
            {lineRoomSensors.length > 0 && (
              <div
                className={` flex flex-col ${
                  notLineRoomSensors.length > 0
                    ? 'border-t-2 border-neutral-300 dark:border-neutral-700 pt-1'
                    : ''
                }`}
              >
                {lineRoomSensors.map((s) => (
                  <div className="flex" key={s.Id}>
                    <FormattedMessage
                      id={s.SensorType.Name as IntlMessageKeys}
                    />
                    {s.Index > 0 ? ` ${s.Index}` : ''}: {s.Value} (
                    {formattedDistance(new Date(s.UpdatedAt))})
                  </div>
                ))}
              </div>
            )}
            {additionalContent}
          </div>
        ) : (
          <FormattedMessage id="Private room" />
        )}
      </PopupBody>
    );
  }
  return null;
}

export default OccupancyPopup;
