import Tab from 'generic/components/Tab';
import Title from 'generic/components/Title/Title';
import { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';

import {
  HiMiniServerStack,
  HiOutlineBuildingOffice2,
  HiOutlineCog6Tooth,
  HiOutlineCog8Tooth,
  HiOutlineCpuChip,
  HiOutlineRocketLaunch,
  HiOutlineUser,
  HiOutlineUserGroup,
} from 'react-icons/hi2';

export default function SettingsView() {
  return (
    <div>
      <Title value="Settings" />
      <Tab
        tabs={[
          {
            end: true, // avoid to be always active for all routes
            path: '',
            userRole: HasuraPermissions.READ,
            name: 'Personal',
            renderIcon: ({ className }) => (
              <HiOutlineCog6Tooth className={className} />
            ),
          },
          {
            path: 'organization',
            userRole: HasuraPermissions.WRITE_ORGANIZATION,
            name: 'Organization',
            renderIcon: ({ className }) => (
              <HiOutlineCog8Tooth className={className} />
            ),
          },
          {
            path: 'mapping',
            userRole: HasuraPermissions.WRITE_MQTTMAPPING,
            name: 'External devices',
            renderIcon: ({ className }) => (
              <HiMiniServerStack className={className} />
            ),
          },
          {
            path: 'sensors',
            userRole: [
              HasuraPermissions.WRITE_ORGANIZATION,
              HasuraPermissions.MDA2,
            ],
            name: 'Sensor policies',
            renderIcon: ({ className }) => (
              <HiOutlineCpuChip className={className} />
            ),
          },
          {
            path: 'firmwares',
            userRole: HasuraPermissions.READ_ALL,
            name: 'Firmwares',
            renderIcon: ({ className }) => (
              <HiOutlineRocketLaunch className={className} />
            ),
          },
          {
            path: 'user',
            userRole: HasuraPermissions.VIEW_USERGROUP,
            name: 'Users',
            renderIcon: ({ className }) => (
              <HiOutlineUser className={className} />
            ),
          },
          {
            path: 'group',
            userRole: HasuraPermissions.VIEW_USERGROUP,
            name: 'Groups',
            renderIcon: ({ className }) => (
              <HiOutlineUserGroup className={className} />
            ),
          },
          {
            path: 'organizations',
            userRole: HasuraPermissions.VIEW_ORGANIZATIONS,
            name: 'Organizations',
            renderIcon: ({ className }) => (
              <HiOutlineBuildingOffice2 className={className} />
            ),
          },
        ]}
      />
    </div>
  );
}
