import { FormattedMessage } from 'translations/Intl';

import EmbeddedButton from '@/generic/components/EmbeddedButton';
import EmbeddedWrapper from '@/generic/components/EmbeddedWrapper';
import SingleRoomOccupancy from '@/generic/embeddedComponents/SingleRoomOccupancy';
import { colorForOccupancy } from '@/generic/embeddedComponents/SingleRoomOccupancy/SingleRoomOccupancy';
import {
  type FloorPartsFragment,
  type LiveOccupancyQuery,
  useLiveRoomOccupancyQuery,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import usePolling from '@/utils/graphql/usePolling';
import useDecodedLocation from '@/utils/useDecodedLocation';
import { useMemo } from 'react';

function renderStatus(
  offline: boolean,
  occupancy: number,
): string | React.JSX.Element {
  if (offline) {
    return '';
  }
  return occupancy > 0 ? (
    <FormattedMessage id="In use" />
  ) : (
    <FormattedMessage id="is Free" />
  );
}

function renderOccupancy(occupancy: number, capacity: number) {
  if (capacity > 0) {
    return `(${occupancy}/${capacity})`;
  }
  return occupancy > 0 ? `(${occupancy})` : '';
}

interface RoomOccupancyInterface {
  floor?: FloorPartsFragment;
  buildingName?: string;
  loadedData?: LiveOccupancyQuery['f_live_rooms_occupancy'];
}

export default function RoomOccupancy({
  floor,
  buildingName,
  loadedData,
}: RoomOccupancyInterface) {
  const hasuraHeader = useHasuraHeader();
  const building = useDecodedLocation('building');
  const decodedFloor = useDecodedLocation('floor');
  const floorNumber = decodedFloor ? Number.parseInt(decodedFloor, 10) : null;
  const room = useDecodedLocation('room');

  const isEmbedded = !floor && !buildingName;

  // Fetch here if embedded, else data comes from previous component in 'loadeddata' prop
  const [{ data, fetching: loading }, reexecuteQuery] =
    useLiveRoomOccupancyQuery({
      variables: {
        Floor: floorNumber ?? floor?.Number,
        Building: building ?? buildingName,
        Room: room ?? '',
      },
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_DASHBOARD),
        [hasuraHeader],
      ),
      pause: !isEmbedded || (building ?? buildingName) === '-',
    });
  usePolling(loading, reexecuteQuery, 1000 * 30);

  return (
    <div>
      {(loadedData || data?.f_live_rooms_occupancy)?.map((liveRoom) => {
        const offline = liveRoom.IsOffline;
        return (
          <EmbeddedWrapper
            alternative={<SingleRoomOccupancy room={liveRoom} />}
            key={liveRoom.Name}
          >
            <div
              data-test-id={`room-occupancy-${liveRoom.Name}`}
              className="flex justify-between text-sm group"
            >
              <div className="flex items-center space-x-2">
                <div
                  className={`${
                    offline
                      ? 'bg-neutral-300'
                      : colorForOccupancy(liveRoom.RoomOccupancy)
                  } size-3 rounded-full transition-colors`}
                />
                <div>{liveRoom.Name}</div>
              </div>
              <div className="flex space-x-2">
                <EmbeddedButton
                  urlParams={[
                    ['building', buildingName ?? ''],
                    ['floor', floor?.Number.toString() ?? '0'],
                    ['room', liveRoom.Name],
                  ]}
                  component="rooms"
                />
                <div>
                  {renderStatus(offline, liveRoom.RoomOccupancy)}
                  {!liveRoom.IsPresenceSensor && !offline && (
                    <>
                      {' '}
                      <span>
                        {renderOccupancy(
                          liveRoom.RoomOccupancy,
                          liveRoom.Capacity,
                        )}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </EmbeddedWrapper>
        );
      })}
    </div>
  );
}
