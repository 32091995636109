import Checkbox from 'generic/components/Form/Checkbox';
import type { ClientRoleInfos } from 'graphql/types';

import Button from '@/generic/components/Form/Button';
import Transition from '@/generic/components/Transition';
import { useMemo, useState } from 'react';
import { HiOutlineChevronRight } from 'react-icons/hi2';

import RoleCheckbox from '../RoleCheckbox/RoleCheckbox';

type UserRole = ClientRoleInfos;

interface RoleGroupCheckboxProps {
  name: string;
  roles?: UserRole[];
  expanded?: boolean;
  groupRoles: UserRole[];
  handleOnChange: (roles: UserRole[]) => void;
}

export default function RoleGroupCheckbox({
  name,
  roles,
  groupRoles,
  expanded = false,
  handleOnChange,
}: RoleGroupCheckboxProps): React.JSX.Element | null {
  const [open, setOpen] = useState(expanded);

  const isGroupChecked = useMemo(() => {
    const t = roles
      ?.map((r) => groupRoles.find((role) => r.name === role.name))
      .filter((r) => r);
    return t && t.length === roles?.length;
  }, [groupRoles, roles]);

  return roles?.length ? (
    <>
      <div className="flex items-center gap-2">
        <Button className="flex items-center" onClick={() => setOpen(!open)}>
          <HiOutlineChevronRight
            className={`size-6 transition-transform ${open ? 'rotate-90' : ''}`}
          />
          {name}
        </Button>
        <Checkbox
          checked={!!isGroupChecked}
          setChecked={() =>
            handleOnChange(
              roles
                .filter((r) => r.name && r.id)
                .map((newRole) => ({
                  id: newRole.id,
                  name: newRole.name,
                })),
            )
          }
        />
      </div>
      <Transition show={open} className="grid grid-cols-2 gap-2 mt-1">
        {roles.map((r) => (
          <RoleCheckbox
            key={r.id}
            role={r}
            groupRoles={groupRoles}
            handleOnChange={handleOnChange}
          />
        ))}
      </Transition>
    </>
  ) : null;
}
