import { LS_SHOW_ONLY_READ_NOTIF } from '@/constants';
import Switch from '@/generic/components/Form/Switch';
import Transition from '@/generic/components/Transition';
import {
  type AllNotificationsQuery,
  useAllNotificationsQuery,
  useMarkAllNotificationsAsReadMutation,
} from '@/graphql/types';
import useStore from '@/model/store';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import { type Virtualizer, useVirtualizer } from '@tanstack/react-virtual';
import { isToday, isYesterday } from 'date-fns';
import Input from 'generic/components/Form/Input/Input';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaCircleNotch } from 'react-icons/fa';
import {
  HiOutlineBellAlert,
  HiOutlineChatBubbleOvalLeft,
  HiOutlineMagnifyingGlass,
} from 'react-icons/hi2';
import { FormattedMessage, useIntl } from 'translations/Intl';
import Notification, {
  type NotificationsType,
  filterUnread,
} from './Notification';

interface NotificationListProps {
  open: boolean;
  total?: number;
}

function Notifications({
  virtualizer,
  notifications,
  seeOnlyUnread,
}: {
  virtualizer: Virtualizer<HTMLDivElement, Element>;
  notifications: NotificationsType;
  seeOnlyUnread: boolean;
}) {
  // TODO: Remove when updated https://github.com/TanStack/table/issues/5567
  'use no memo';

  return (
    <div
      style={{
        height: `${virtualizer.getTotalSize()}px`,
        width: '100%',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          transform: `translateY(${virtualizer.getVirtualItems()[0]?.start}px)`,
        }}
      >
        {virtualizer.getVirtualItems().map((virtualItem) => (
          <Notification
            data-index={virtualItem.index}
            ref={virtualizer.measureElement}
            key={virtualItem.key}
            notification={notifications[virtualItem.index]!}
            seeOnlyUnread={seeOnlyUnread}
          />
        ))}
      </div>
    </div>
  );
}

function NotificationSkeleton() {
  return (
    <div className="w-full animate-pulse flex flex-col p-4 group-hover:bg-neutral-50 dark:group-hover:bg-neutral-700">
      <div className="flex items-center pb-2 justify-between">
        <div className="flex items-center">
          <div className="size-6 bg-neutral-200 rounded-full " />
          <div className="ml-2 w-20 bg-neutral-200 h-4 rounded-md" />
        </div>
      </div>
      <div className="w-full bg-neutral-200 h-4 rounded-md" />
    </div>
  );
}

export default function NotificationList({
  open,
  total,
}: NotificationListProps) {
  const intl = useIntl();
  const todayRef = useRef<HTMLDivElement>(null);
  const yesterdayRef = useRef<HTMLDivElement>(null);
  const olderRef = useRef<HTMLDivElement>(null);
  const hasuraHeader = useHasuraHeader();
  const [limit, setLimit] = useState(20);
  const [filter, setFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState(filter);
  const userRoles = useStore((state) => state.user)?.roles;
  const [seeOnlyUnread, setSeeOnlyUnread] = useState(
    localStorage.getItem(LS_SHOW_ONLY_READ_NOTIF)
      ? localStorage.getItem(LS_SHOW_ONLY_READ_NOTIF) === 'true'
      : false,
  );
  const [loadUnread, setLoadUnread] = useState(false);
  const [, markAllNotificationsAsRead] =
    useMarkAllNotificationsAsReadMutation();

  // Load all notifications when there is a search query in order to search all instead
  // of only the paginated ones
  const [{ data: allNotifications, fetching: loadingAllNotifications }] =
    useAllNotificationsQuery({
      pause: !open,
      variables: {
        Query: `%${searchFilter}%`,
        GetAll: !seeOnlyUnread,
        Limit: limit,
      },
      context: useMemo(
        () =>
          hasuraHeader(
            userRoles?.includes(HasuraPermissions.READ_ALL)
              ? HasuraPermissions.READ_ALL
              : HasuraPermissions.READ,
            ['Notifications'],
          ),
        [hasuraHeader, userRoles],
      ),
    });

  const filterMessage = useCallback(
    (notification: AllNotificationsQuery['Notifications'][number]) =>
      (notification.Message.toLowerCase().includes(
        searchFilter.toLowerCase(),
      ) ||
        notification.Source.toLowerCase().includes(
          searchFilter.toLowerCase(),
        )) &&
      (seeOnlyUnread ? filterUnread(notification) : true),
    [searchFilter, seeOnlyUnread],
  );

  const data = useMemo(
    () => allNotifications?.Notifications ?? [],
    [allNotifications?.Notifications],
  );

  const allNotifsAreLoaded = useMemo(
    () =>
      allNotifications?.Notifications.length
        ? total === allNotifications.Notifications.length
        : false,
    [allNotifications?.Notifications.length, total],
  );

  const handleScroll = ({ currentTarget }: React.UIEvent<HTMLElement>) => {
    if (
      !allNotifsAreLoaded &&
      !loadingAllNotifications &&
      currentTarget.scrollTop + currentTarget.clientHeight >=
        currentTarget.scrollHeight - 100
    ) {
      setLimit((prev) => prev + 20);
    }
  };

  const today: NotificationsType = useMemo(
    () =>
      data
        .filter((n) => isToday(new Date(n.UpdatedAt)))
        .filter((m) => filterMessage(m)),
    [data, filterMessage],
  );

  const yesterday: NotificationsType = useMemo(
    () =>
      data
        .filter((n) => isYesterday(new Date(n.UpdatedAt)))
        .filter((m) => filterMessage(m)),
    [data, filterMessage],
  );

  const older: NotificationsType = useMemo(
    () =>
      data
        .filter(
          (n) =>
            !isToday(new Date(n.UpdatedAt)) &&
            !isYesterday(new Date(n.UpdatedAt)),
        )
        .filter((m) => filterMessage(m)),
    [data, filterMessage],
  );

  const rowTodayVirtualizer = useVirtualizer({
    count: today.length,
    getScrollElement: () => todayRef.current,
    estimateSize: () => 120,
  });

  const rowYesterdayVirtualizer = useVirtualizer({
    count: yesterday.length,
    getScrollElement: () => yesterdayRef.current,
    estimateSize: () => 120,
  });

  const rowOlderVirtualizer = useVirtualizer({
    count: older.length,
    getScrollElement: () => olderRef.current,
    estimateSize: () => 120,
  });

  const markAllAsRead = () => {
    setLoadUnread(true);
    markAllNotificationsAsRead(
      {},
      hasuraHeader(
        userRoles?.includes(HasuraPermissions.READ_ALL)
          ? HasuraPermissions.READ_ALL
          : HasuraPermissions.READ,
        ['Notifications', 'Notifications_aggregate'],
      ),
    ).finally(() => setLoadUnread(false));
  };

  const isVisibleNotifs =
    seeOnlyUnread && data.length > 0
      ? data.filter((n) => filterUnread(n)).length > 0
      : data.length > 0;

  // Filter the data after a delay and not instantly to make it more responsive
  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchFilter(filter), 500);
    return () => clearTimeout(timeOutId);
  }, [filter]);

  return (
    <div className="xl:max-w-md w-full">
      <div className="justify-between items-center p-4 border-b dark:border-neutral-700 gap-4 hidden xl:flex">
        <div className="text-base font-bold">
          <FormattedMessage id="Notifications" />
        </div>
        <Switch
          isEnabled={seeOnlyUnread}
          onSetEnable={(enabling) => {
            localStorage.setItem(
              LS_SHOW_ONLY_READ_NOTIF,
              JSON.stringify(enabling),
            );
            setSeeOnlyUnread(enabling);
          }}
          label={<FormattedMessage id="Only show unread" />}
          labelPosition="left"
        />
      </div>
      <div className="py-1 px-4">
        <Input
          type="text"
          value={filter}
          min={0}
          renderIcon={({ className }) => (
            <HiOutlineMagnifyingGlass className={className} />
          )}
          placeholder={intl.formatMessage({ id: 'Filter' })}
          onChangeValue={setFilter}
        />
      </div>
      {data.length > 0 && (
        <div
          className="flex flex-col overflow-y-auto max-h-[70vh] relative overscroll-contain"
          onScroll={handleScroll}
        >
          {isVisibleNotifs && (
            <button
              onClick={markAllAsRead}
              type="button"
              className="absolute flex space-x-1 items-center z-20 top-0 right-0 py-2 px-4 text-xs text-neutral-500 dark:text-neutral-300 hover:underline"
            >
              <Transition show={loadUnread}>
                <div>
                  <FaCircleNotch className="size-3 animate-spin text-primary-500" />
                </div>
              </Transition>
              <div>
                <FormattedMessage id="Mark all as read" />
              </div>
            </button>
          )}
          {today.length > 0 && (
            <div ref={todayRef}>
              {isVisibleNotifs && (
                <div className="z-10 backdrop-blur bg-opacity-90 bg-white dark:bg-neutral-800 flex sticky top-0 py-1 px-4 text-neutral-500 dark:text-neutral-300 text-bold uppercase">
                  <FormattedMessage id="Today" />
                </div>
              )}
              <Notifications
                virtualizer={rowTodayVirtualizer}
                notifications={today}
                seeOnlyUnread={seeOnlyUnread}
              />
            </div>
          )}
          {yesterday.length > 0 && (
            <div ref={yesterdayRef}>
              {isVisibleNotifs && (
                <div className="z-10 backdrop-blur bg-opacity-90 bg-white dark:bg-neutral-800 sticky top-0 py-1 px-4 text-neutral-500 dark:text-neutral-300 text-bold uppercase">
                  <FormattedMessage id="Yesterday" />
                </div>
              )}
              <Notifications
                virtualizer={rowYesterdayVirtualizer}
                notifications={yesterday}
                seeOnlyUnread={seeOnlyUnread}
              />
            </div>
          )}
          {older.length > 0 && (
            <div ref={olderRef}>
              {isVisibleNotifs && (
                <div className="z-10 backdrop-blur bg-opacity-90 bg-white dark:bg-neutral-800 sticky top-0 py-1 px-4 text-neutral-500 dark:text-neutral-300 text-bold uppercase">
                  <FormattedMessage id="Older" />
                </div>
              )}
              <Notifications
                virtualizer={rowOlderVirtualizer}
                notifications={older}
                seeOnlyUnread={seeOnlyUnread}
              />
            </div>
          )}
          {allNotifsAreLoaded && (
            <div className="flex items-center justify-center py-5">
              <HiOutlineBellAlert className="size-5 mr-1" />
              <FormattedMessage id="No more notifications" />
            </div>
          )}
          {loadingAllNotifications && <NotificationSkeleton />}
        </div>
      )}
      {!isVisibleNotifs && loadingAllNotifications && <NotificationSkeleton />}
      {!isVisibleNotifs && !loadingAllNotifications && (
        <div className="flex items-center justify-center py-5 text-neutral-500">
          <HiOutlineChatBubbleOvalLeft className="size-5 mr-1" />
          <FormattedMessage id="There are no notifications" />
        </div>
      )}
    </div>
  );
}
