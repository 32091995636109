import Select from 'generic/components/Form/Select/Select';
import { FormattedMessage, useIntl } from 'translations/Intl';

import { Action } from '@/common/types';
import Input from '@/generic/components/Form/Input/Input';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import {
  FirmwarePackageOrganization_Constraint,
  FirmwarePackageOrganization_Update_Column,
  Firmwares_Constraint,
  Firmwares_Update_Column,
  type ModulesQuery,
  type OrganizationFirmwaresQuery,
  useInsertFirmwarePackageMutation,
  useModulesQuery,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import { Reorder } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { HiOutlineIdentification } from 'react-icons/hi2';
import OrderItem from './components/OrderItem';
import type { FirmwareInfo } from './components/OrderItem/OrderItem';

export type Firmwares = OrganizationFirmwaresQuery['FirmwarePackages'][number];

interface AddFirmwaresModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedFirmwarePackage?: Firmwares;
  setSelectedFirmwarePackage: (selectedFirmwarePackage?: Firmwares) => void;
}

export const getFirmwareKey = (name: string) => name.toLowerCase();

export const defaultVersion = new Date()
  .toISOString()
  .split('T')[0]
  ?.replaceAll('-', '_');

export default function AddFirmwaresModal({
  open,
  setOpen,
  selectedFirmwarePackage,
  setSelectedFirmwarePackage,
}: AddFirmwaresModalProps) {
  const intl = useIntl();
  const [version, setVersion] = useState(defaultVersion);
  const [selectedFirmware, setSelectedFirmware] = useState<FirmwareInfo[]>();
  const [selectedOrganizations, setSelectedOrganizations] = useState<
    Firmwares['FirmwarePackageOrganizations'][number]['Organization'][] | null
  >();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();

  const [{ fetching: loadingAddFirmwares }, addFirmwarePackage] =
    useInsertFirmwarePackageMutation();

  const [{ data: modulesAndOrganizations }] = useModulesQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.READ_ALL, ['Modules']),
      [hasuraHeader],
    ),
  });

  const [modules, setModules] = useState(
    modulesAndOrganizations?.Modules ?? [],
  );

  useEffect(() => {
    // Set the value 100 in order to first show any modules that are selected and only then the ones without values
    const sortOrder = (module: ModulesQuery['Modules'][number]) =>
      module.Firmwares.flatMap((f) =>
        f.FirmwarePackageFirmwares.map((p) => p.FirmwarePackageId),
      ).includes(selectedFirmwarePackage?.Id ?? '')
        ? (module.Firmwares.flatMap((f) => f.FirmwarePackageFirmwares).map(
            (p) => p.Order,
          )?.[0] ?? 100)
        : 100;

    setModules(
      modulesAndOrganizations?.Modules.sort(
        (a, b) => sortOrder(a) - sortOrder(b),
      ) ?? [],
    );
  }, [modulesAndOrganizations?.Modules, selectedFirmwarePackage?.Id]);

  useEffect(() => {
    if (selectedFirmwarePackage) {
      setVersion(selectedFirmwarePackage.Version);
      setSelectedOrganizations(
        selectedFirmwarePackage.FirmwarePackageOrganizations.map(
          (o) => o.Organization,
        ),
      );
      setSelectedFirmware(
        selectedFirmwarePackage.FirmwarePackageFirmwares.map((f) => ({
          FirmwareId: f.Firmware.Id,
          Version: f.Firmware.Version,
          ModuleId: f.Firmware.Module.Id,
          Path: f.Firmware.Path,
          Changelog: f.Firmware.Changelog,
        })),
      );
    } else {
      setVersion(defaultVersion);
      setSelectedOrganizations([]);
      setSelectedFirmware(undefined);
    }
  }, [selectedFirmwarePackage]);

  const resetValues = () => {
    setOpen(false);
    setSelectedFirmwarePackage(undefined);
  };

  return (
    <Modal
      action={selectedFirmwarePackage ? Action.UPDATE : Action.ADD}
      title={intl.formatMessage(
        {
          id: selectedFirmwarePackage
            ? 'Edit firmware package'
            : 'Add firmware package',
        },
        { version: selectedFirmwarePackage?.Version },
      )}
      open={open}
      wrapperClassName="sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg"
      setShowModal={resetValues}
      footer={
        <ModalFooter
          disabled={version === '' || !selectedOrganizations?.length}
          action={selectedFirmwarePackage ? Action.UPDATE : Action.ADD}
          isLoading={loadingAddFirmwares}
          onProceed={() => {
            if (selectedOrganizations) {
              addFirmwarePackage(
                {
                  Organizations: selectedOrganizations.map((o) => o.Id),
                  Update: !!selectedFirmwarePackage?.Id,
                  FirmwarePackageId: selectedFirmwarePackage?.Id,
                  FirmwarePackage: {
                    Version: version,
                    Id: selectedFirmwarePackage?.Id,
                    FirmwarePackageOrganizations: {
                      data: selectedOrganizations.map((orga) => ({
                        OrganizationId: orga.Id,
                        Latest: false,
                      })),
                      on_conflict: {
                        constraint:
                          FirmwarePackageOrganization_Constraint.FirmwarePackageOrganizationPkey,
                        update_columns: [
                          FirmwarePackageOrganization_Update_Column.FirmwarePackageId,
                          FirmwarePackageOrganization_Update_Column.OrganizationId,
                        ],
                      },
                    },
                    FirmwarePackageFirmwares: {
                      data: (selectedFirmware ?? []).map(
                        (moduleAndFirmware) => ({
                          Order: modules
                            .map((m) => m.Id)
                            .indexOf(moduleAndFirmware.ModuleId),
                          Firmware: {
                            data: {
                              Id: moduleAndFirmware.FirmwareId,
                              Version: moduleAndFirmware.Version,
                              Path: moduleAndFirmware.Path,
                              Changelog: moduleAndFirmware.Changelog,
                              ModuleId: moduleAndFirmware.ModuleId,
                            },
                            on_conflict: {
                              constraint:
                                Firmwares_Constraint.FirmwaresVersionModuleIdKey,
                              update_columns: [
                                Firmwares_Update_Column.ModuleId,
                              ],
                            },
                          },
                        }),
                      ),
                    },
                  },
                },
                hasuraHeader(HasuraPermissions.READ_ALL),
              ).then((data) => {
                toast(data, {
                  message: {
                    type: 'success',
                    content: intl.formatMessage(
                      {
                        id: selectedFirmwarePackage
                          ? 'Updated firmware package'
                          : 'Added firmware package',
                      },
                      {
                        version: version || selectedFirmwarePackage?.Version,
                      },
                    ),
                  },
                });
                if (!data.error) {
                  resetValues();
                }
              });
            }
          }}
          onCancel={resetValues}
        />
      }
    >
      <div className="text-sm text-neutral-700 dark:text-white space-y-4">
        <Input
          type="text"
          value={version}
          label={intl.formatMessage({
            id: 'Version',
          })}
          placeholder={intl.formatMessage({
            id: 'Version',
          })}
          renderIcon={({ className }) => (
            <HiOutlineIdentification className={className} />
          )}
          onChangeValue={(e) => setVersion(e)}
          required
        />
        <Select
          label="Organizations"
          value={
            modulesAndOrganizations?.Organizations.filter((o) =>
              selectedOrganizations?.map((s) => s.Id).includes(o.Id),
            ) ?? []
          }
          options={modulesAndOrganizations?.Organizations ?? []}
          onChangeSelected={(selected) => setSelectedOrganizations(selected)}
          keyParameter="Id"
          renderValue={(o) => o?.Name ?? ''}
        />
        <div className="text-lg font-bold">
          <FormattedMessage id="Drag the items to change the position" />
        </div>
        <Reorder.Group
          className="space-y-2"
          axis="y"
          values={modules}
          onReorder={setModules}
        >
          {modules.map((module) => (
            <OrderItem
              key={module.Id}
              module={module}
              selectedFirmware={selectedFirmware}
              setSelectedFirmware={setSelectedFirmware}
            />
          ))}
        </Reorder.Group>
      </div>
    </Modal>
  );
}
