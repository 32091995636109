import Switch from 'generic/components/Form/Switch';
import type { ReportMetricsQuery } from 'graphql/types';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'translations/Intl';

declare global {
  namespace React.JSX {
    interface IntrinsicElements {
      math: any;
      mrow: any;
      mfrac: any;
      mi: any;
      mo: any;
      mn: any;
    }
  }
}

interface DivisionSwitchProps {
  data?: ReportMetricsQuery['ReportPages'][number]['ReportPageMetrics'][number];
  targetValue: number;
  callback: (divideByTargetValue: boolean) => void;
}

export default function DivisionSwitch({
  callback,
  data,
  targetValue,
}: DivisionSwitchProps) {
  const limit = data?.MetricLimits[0];
  const [divideByTargetValue, setDivideByTargetValue] = useState(
    !!limit?.DivideByTargetValue,
  );

  const currentValue = data?.ReportReportPageMetrics[0]?.Value ?? 1;

  // Set the initial values when showing the switch
  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run once in order to set initial values
  useEffect(() => {
    callback(divideByTargetValue);
  }, []);

  const weightedValue = divideByTargetValue
    ? currentValue / targetValue
    : targetValue / currentValue;

  return (
    <>
      <Switch
        isEnabled={divideByTargetValue}
        onSetEnable={(enabled) => {
          setDivideByTargetValue(enabled);
          callback(enabled);
        }}
        label={<FormattedMessage id="Divide value by target value" />}
      />
      <math>
        <mrow>
          <mi>
            <FormattedMessage id="Compliance" />
          </mi>
          <mo>=</mo>
          <mfrac>
            {divideByTargetValue ? (
              <>
                <mi>
                  <FormattedMessage id="Current value" />
                </mi>
                <mi>
                  <FormattedMessage id="Target value" />
                </mi>
              </>
            ) : (
              <>
                <mi>
                  <FormattedMessage id="Target value" />
                </mi>
                <mi>
                  <FormattedMessage id="Current value" />
                </mi>
              </>
            )}
          </mfrac>
        </mrow>
        <mo>=</mo>
        <mrow>
          <mfrac>
            {divideByTargetValue ? (
              <>
                <mn>{currentValue.toFixed(2)}%</mn>
                <mn>{targetValue}%</mn>
              </>
            ) : (
              <>
                <mn>{targetValue}%</mn>
                <mn>{currentValue.toFixed(2)}%</mn>
              </>
            )}
          </mfrac>
        </mrow>
        <mo>=</mo>
        <mi>{weightedValue.toFixed(2)}</mi>
        <mo>=</mo>
        <mi>{(weightedValue * 100).toFixed(2)}%</mi>
      </math>
    </>
  );
}
