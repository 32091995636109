import { FormattedMessage, useIntl } from 'translations/Intl';

import { Action } from '@/common/types';
import Input from '@/generic/components/Form/Input';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import Subtitle from '@/generic/components/Subtitle';
import {
  type KeycloakUsersQuery,
  useDeleteKeycloakUsersMutation,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import type { Row } from '@tanstack/react-table';
import { useState } from 'react';
import { HiOutlineTrash } from 'react-icons/hi2';

interface RemoveUserModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  users: Row<KeycloakUser>[];
}

export type KeycloakUser =
  KeycloakUsersQuery['GetUsers']['groupMembers'][number];

export default function RemoveUserModal({
  open,
  setOpen,
  users,
}: RemoveUserModalProps): React.JSX.Element {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [confirmDelete, setConfirmDelete] = useState('');
  const [{ fetching }, deleteUser] = useDeleteKeycloakUsersMutation();

  const singleUser = users.length === 1;
  const confirmation = singleUser
    ? users[0]?.original.username
    : users.length.toString();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      action={Action.REMOVE}
      open={open}
      setShowModal={setOpen}
      footer={
        <ModalFooter
          disabled={confirmDelete !== confirmation}
          proceed={intl.formatMessage({
            id: 'Confirm',
          })}
          isLoading={fetching}
          action={Action.REMOVE}
          onProceed={() => {
            if (confirmDelete === confirmation) {
              deleteUser(
                {
                  Users: {
                    users: users.map(({ original }) => ({ id: original.id })),
                  },
                },
                // Invalidate the service account token as the user doesn't have the same token anymore
                hasuraHeader(HasuraPermissions.VIEW_USERGROUP, [
                  'RefreshTokenOutput',
                  'MqttBeacons',
                ]),
              ).then((data) => {
                toast(data, {
                  message: {
                    type: 'success',
                    content: singleUser
                      ? intl.formatMessage(
                          { id: 'Deleted user' },
                          { name: confirmation },
                        )
                      : intl.formatMessage(
                          { id: 'Deleted users' },
                          { count: confirmation },
                        ),
                  },
                });
                setOpen(false);
                setConfirmDelete('');
              });
            }
          }}
          onCancel={() => {
            setOpen(false);
            setConfirmDelete('');
          }}
        />
      }
    >
      <Subtitle
        value={intl.formatMessage({ id: 'Are you sure to remove this user?' })}
        className="text-base pb-2"
      />

      <p className="font-bold">
        {users.some((u) => u.original.username.includes('serviceaccount-')) && (
          <FormattedMessage id="Deleting the service account will revoke access to all embedded graphics" />
        )}
      </p>
      <p className="pb-2">
        {singleUser ? (
          <FormattedMessage
            id="Enter the username {name} in order to delete it"
            values={{
              name: <b>{confirmation}</b>,
            }}
          />
        ) : (
          <FormattedMessage
            id="Enter the number of users in order to delete them"
            values={{
              count: <b>{confirmation}</b>,
            }}
          />
        )}
      </p>

      <Input
        type="text"
        value={confirmDelete}
        id="Remove user"
        renderIcon={({ className }) => <HiOutlineTrash className={className} />}
        onChangeValue={(input: string) => setConfirmDelete(input)}
        placeholder={confirmation}
        required
        data-test-id="confirm-delete-user"
      />
    </Modal>
  );
}
