import type { DeskFeatures, DrawingDeskFeatures } from '@/common/types';
import Accordion from '@/generic/components/Accordion';
import StyledButton from '@/generic/components/Form/Button/StyledButton';
import type Point from 'ol/geom/Point';
import type OLVectorLayer from 'ol/layer/Vector';
import type VectorSource from 'ol/source/Vector';
import { useEffect, useState } from 'react';
import { FaLongArrowAltUp, FaRedo, FaUndo } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'translations/Intl';
import useDeviceDetect from 'utils/useDeviceDetect';
import { getDeskFeatureRotation } from '../../interactions/moveDesk';
import { drawingDesksLayer } from '../../mapElements';

interface DeskConfigurationProps {
  isNew: boolean;
  onRotate: (
    radians: number,
    deskFeats: (DrawingDeskFeatures | DeskFeatures)[],
  ) => void;
  beaconGeom?: Point | null;
  beaconName: string | null;
  onRadiusChange: () => void;
}

export default function DeskConfiguration({
  isNew,
  onRotate,
  beaconGeom,
  beaconName,
  onRadiusChange,
}: DeskConfigurationProps): React.JSX.Element {
  const intl = useIntl();
  const { isMobile } = useDeviceDetect();
  const [radiusValue, setRadiusValue] = useState<number | undefined>();
  const rotateDesks = (degreesRotation: number) => {
    const drawingDeskFeats =
      (
        drawingDesksLayer.olLayer as OLVectorLayer<
          VectorSource<DrawingDeskFeatures | DeskFeatures>
        >
      )
        .getSource()
        ?.getFeatures() ?? [];
    if (beaconGeom) {
      const radRotation = degreesRotation * (Math.PI / 180);
      for (const d of drawingDeskFeats) {
        d.getGeometry()?.rotate(radRotation, beaconGeom.getCoordinates());
      }
      onRotate(radRotation, drawingDeskFeats);
    }
  };

  // Wait for the element to be selected and then set the radius value
  useEffect(() => {
    if (!radiusValue) {
      setRadiusValue(
        ((
          drawingDesksLayer.olLayer as OLVectorLayer<
            VectorSource<DrawingDeskFeatures | DeskFeatures>
          >
        )
          .getSource()
          ?.getFeatures() ?? [])[0]?.getProperties().Radius,
      );
    }
  }, [radiusValue]);

  useEffect(() => {
    for (const d of (
      drawingDesksLayer.olLayer as OLVectorLayer<
        VectorSource<DrawingDeskFeatures>
      >
    )
      .getSource()
      ?.getFeatures() ?? []) {
      if (radiusValue) {
        d.set('Radius', radiusValue);
      }
    }

    onRadiusChange();
  }, [radiusValue, onRadiusChange]);

  return (
    <Accordion
      title={<FormattedMessage id="Desk configuration" />}
      initialStateOpen={!!isNew && !isMobile}
      dataTestId="desk-configuration-accordion"
    >
      <div className="p-2">
        <div className="gap-2 flex flex-col">
          <div className="flex items-center text-md w-full justify-between">
            <div>
              <FormattedMessage id="Radius" />{' '}
            </div>
            <div>
              <input
                data-test-id="radius-input"
                className="p-1 ml-2 border border-neutral-300 dark:border-neutral-700 rounded-md text-sm"
                type="number"
                value={radiusValue}
                onChange={(n) =>
                  setRadiusValue(Number.parseInt(n.target.value, 10))
                }
              />
            </div>
          </div>
          <input
            type="range"
            min={1}
            step={1}
            max={100}
            value={radiusValue}
            onChange={(n) =>
              setRadiusValue(Number.parseInt(n.target.value, 10))
            }
          />
        </div>

        <div className="pt-2 gap-2 flex flex-col">
          <div className="text-md">
            <FormattedMessage id="Rotation" />
          </div>
          <div className="flex items-center justify-between">
            <StyledButton
              id={`rotate-90-ccw-${beaconName}`}
              onClick={() => rotateDesks(90)}
              title={intl.formatMessage({
                id: 'Rotate 90 degrees CCW',
              })}
            >
              <FaUndo className="size-5" />
            </StyledButton>
            <StyledButton
              data-test-id="reset-to-0-degree"
              onClick={() => {
                const drawingDeskFeats =
                  (
                    drawingDesksLayer.olLayer as OLVectorLayer<
                      VectorSource<DrawingDeskFeatures | DeskFeatures>
                    >
                  )
                    .getSource()
                    ?.getFeatures() ?? [];
                const beaconCoordinates = beaconGeom?.getCoordinates();
                if (beaconCoordinates && drawingDeskFeats.length) {
                  const deskRotation = getDeskFeatureRotation(
                    beaconCoordinates,
                    drawingDeskFeats,
                  );
                  if (typeof deskRotation === 'number') {
                    // Reset rotation to 90°
                    rotateDesks(90 - deskRotation);
                  }
                }
              }}
              title={intl.formatMessage({
                id: 'Reset rotation',
              })}
            >
              <FaLongArrowAltUp className="size-5" />
            </StyledButton>
            <StyledButton
              id={`rotate-90-cw-${beaconName}`}
              onClick={() => rotateDesks(-90)}
              title={intl.formatMessage({
                id: 'Rotate 90 degrees CW',
              })}
            >
              <FaRedo className="size-5" />
            </StyledButton>
          </div>
        </div>
      </div>
    </Accordion>
  );
}
