import type Feature from 'ol/Feature';
import type MapBrowserEvent from 'ol/MapBrowserEvent';
import type Point from 'ol/geom/Point';
import type Polygon from 'ol/geom/Polygon';
import Modify from 'ol/interaction/Modify';
import type VectorSource from 'ol/source/Vector';

import type GeometryLayer from 'generic/layers/GeometryLayer';
import type Geometry from 'ol/geom/Geometry';
import MoveEvent, { MoveEventType } from './moveEvent';

interface MoveBeaconType {
  layer: GeometryLayer;
  source: VectorSource<Feature<Geometry | Point>>;
  rotateAnchorSource: VectorSource<Feature<Point>>;
  desksSource: VectorSource<Feature<Point>>;
  outlineSource: VectorSource<Feature<Polygon>>;
}

export default class MoveBeacon extends Modify {
  beaconToMove?: Feature<Point>;

  coordinate: number[] | null;

  deskFeatures: Feature<Point>[] | null;

  desksSource: MoveBeaconType['desksSource'];

  outlineAnchorsFeatures: Feature<Point | Polygon>[] | null;

  outlineFeatures: Feature<Polygon>[] | null;

  outlineSource: MoveBeaconType['outlineSource'];

  rotateAnchorFeatures: Feature<Point>[] | null;

  rotateAnchorSource: MoveBeaconType['rotateAnchorSource'];

  // TODO: OL 9.2.5 requires this hack of adding Feature<Geometry> otherwise there are type errors
  source: MoveBeaconType['source'];

  layer: MoveBeaconType['layer'];

  constructor(options: MoveBeaconType) {
    super({
      ...options,
      snapToPointer: true,
      style: () => {},
    });
    this.layer = options.layer;
    this.desksSource = options.desksSource;
    this.outlineSource = options.outlineSource;
    this.rotateAnchorSource = options.rotateAnchorSource;
    this.beaconToMove = undefined;
    this.coordinate = null;
    this.deskFeatures = null;
    this.outlineAnchorsFeatures = null;
    this.outlineFeatures = null;
    this.rotateAnchorFeatures = null;
    // TODO: OL 9.2.5 requires this hack of adding Feature<Geometry> otherwise there are type errors
    this.source = options.source;
  }

  handleDownEvent(evt: MapBrowserEvent<PointerEvent>): boolean {
    this.beaconToMove = evt.map.forEachFeatureAtPixel(
      evt.pixel,
      (f) => {
        const feature = f as Feature<Point>;
        if (this.source.getFeatures().indexOf(feature) > -1) {
          return feature;
        }

        return undefined;
      },
      {
        hitTolerance: 0,
      },
    );
    const selectedFeat = this.layer.getSelectedFeature();
    const beaconCoords = this.beaconToMove?.getGeometry()?.getCoordinates();
    if (
      !this.beaconToMove ||
      // if mqttBeacon from layer 'beaconsLayer' & 'beaconsModuleLayer'
      (this.beaconToMove.get('__typename') === 'MqttBeacons' &&
        this.beaconToMove !== selectedFeat) ||
      !beaconCoords
    ) {
      return false;
    }

    this.coordinate = [...beaconCoords];
    this.deskFeatures = this.desksSource.getFeatures();
    this.outlineFeatures = this.outlineSource.getFeatures();
    this.rotateAnchorFeatures = this.rotateAnchorSource.getFeatures();
    this.dispatchEvent(
      new MoveEvent(MoveEventType.START, this.beaconToMove, evt),
    );
    super.handleDownEvent(evt);
    return true;
  }

  handleDragEvent(evt: MapBrowserEvent<PointerEvent>): void {
    const evtCoordX = evt.coordinate[0];
    const evtCoordY = evt.coordinate[1];
    const coordX = this.coordinate?.[0];
    const coordY = this.coordinate?.[1];
    if (
      typeof evtCoordX !== 'number' ||
      typeof evtCoordY !== 'number' ||
      typeof coordX !== 'number' ||
      typeof coordY !== 'number'
    ) {
      return;
    }

    // Move the source's feature
    super.handleDragEvent(evt);
    // Then move associated features
    for (const featuresCollection of [
      this.deskFeatures,
      this.outlineFeatures,
      this.rotateAnchorFeatures,
      this.outlineAnchorsFeatures,
    ]) {
      for (const feat of featuresCollection ?? []) {
        feat?.getGeometry()?.translate(evtCoordX - coordX, evtCoordY - coordY);
      }
    }
    this.coordinate = evt.coordinate;
  }

  handleUpEvent(evt: MapBrowserEvent<PointerEvent>): boolean {
    this.handleDragEvent(evt);
    if (this.beaconToMove) {
      this.dispatchEvent(
        new MoveEvent(MoveEventType.END, this.beaconToMove, evt),
      );
    }
    this.beaconToMove = undefined;
    this.deskFeatures = null;
    this.rotateAnchorFeatures = null;
    super.handleUpEvent(evt);
    return false;
  }
}
