import keycloak from '@/keycloak';
import useStore from 'model/store';
import { useCallback, useEffect, useRef, useState } from 'react';
import LoadingScreen from '../LoadingScreen';

interface AuthenticationProviderProps {
  children: React.JSX.Element[];
}

export default function AuthenticationProvider({
  children,
}: AuthenticationProviderProps) {
  const didInit = useRef(false);
  const [initialized, setInitialized] = useState(false);
  const token = useStore((state) => state.token);
  const setToken = useStore((state) => state.setToken);

  keycloak.onTokenExpired = () =>
    keycloak.updateToken(30).then(() => setToken(keycloak.token));

  keycloak.onAuthRefreshSuccess = () => setToken(keycloak.token);

  const initializeKeycloak = useCallback(async () => {
    if (!didInit.current) {
      didInit.current = true;
      keycloak
        .init({
          onLoad: 'login-required',
          checkLoginIframe: false,
          enableLogging: true,
          pkceMethod: 'S256',
          flow: 'standard',
        })
        .then(() => setInitialized(true))
        .catch((e) => console.error(e));
    }
  }, []);

  useEffect(() => {
    async function checkKeycloak() {
      await initializeKeycloak();
    }
    if (!initialized && !keycloak.token) {
      checkKeycloak();
    }
    if (keycloak.token) {
      setToken(keycloak.token);
    }
  }, [setToken, initialized, initializeKeycloak]);

  if (token) {
    return children;
  }

  return <LoadingScreen />;
}
