import { FormattedMessage, useIntl } from 'translations/Intl';

import { Action } from '@/common/types';
import Button from '@/generic/components/Form/Button';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import {
  type BeaconInfos,
  type CleaningOccupancyMapQuery,
  type FloorPartsFragment,
  type MqttSystems,
  type SetSceneCleaningMutation,
  useSetSceneCleaningMutation,
  useSetSceneNoneMutation,
  useUpdateDesksLastCleanedMutation,
  useUpdateFloorCleaningDurationMutation,
  useUpdateRoomCleaningDurationMutation,
} from '@/graphql/types';
import useStore from '@/model/store';
import { lower, serializeRange, upper } from '@/utils/date';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import { addMinutes, format, isFuture } from 'date-fns';
import { useEffect, useState } from 'react';

export type DeskSharingRoom = Exclude<
  CleaningOccupancyMapQuery['desksharingRooms'],
  undefined
>[number];

export interface Floor extends FloorPartsFragment {
  CleaningDuration?: string | null;
}

export interface Room extends FloorPartsFragment {
  CleaningDuration?: string | null;
}

interface RelatedDesk {
  id: number;
  index: number;
  hotMinutes: number;
  lastCleaned: Date;
}

export interface CleaningBeaconInfo {
  beaconTopic: string;
  data: string;
  relatedDesks: RelatedDesk[];
}

export type MqttResponse = {
  successResponses: Exclude<
    SetSceneCleaningMutation['SetSceneCleaning'],
    null | undefined
  >['successResponses'];
  failedResponses: Exclude<
    SetSceneCleaningMutation['SetSceneCleaning'],
    null | undefined
  >['successResponses'];
};

interface CleaningModalProps {
  cleaningItem: Floor | DeskSharingRoom | undefined;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  isCleaningActive: boolean;
  mqttCleaningInfos: CleaningBeaconInfo[] | null;
  mqttSystem: MqttSystems | null;
  setCleaningResponse: (
    resp: {
      cleaning: MqttResponse;
      info: BeaconInfos[];
    } | null,
  ) => void;
}

function isRoom(feature: Floor | DeskSharingRoom): feature is DeskSharingRoom {
  if ((feature as DeskSharingRoom).Name) {
    return true;
  }
  return false;
}

const getNewCleaningDuration = (cleaningMinutesPolicy: number) =>
  serializeRange({
    start: { value: new Date(), inclusive: true },
    end: {
      value: new Date(new Date().getTime() + cleaningMinutesPolicy * 60 * 1000),
      inclusive: false,
    },
  });

const stopCleaningDuration = (cleaningDuration: string) =>
  serializeRange({
    start: { value: lower(cleaningDuration), inclusive: true },
    end: { value: new Date(), inclusive: false },
  });

const buttons = [
  {
    minutes: 15,
    label: <FormattedMessage id="{min} minutes" values={{ min: 15 }} />,
  },
  {
    minutes: 30,
    label: <FormattedMessage id="{min} minutes" values={{ min: 30 }} />,
  },
  {
    minutes: 45,
    label: <FormattedMessage id="{min} minutes" values={{ min: 45 }} />,
  },
  {
    minutes: 60,
    label: <FormattedMessage id="1 hour" />,
  },
  {
    minutes: 60 * 2,
    label: <FormattedMessage id="{hours} hours" values={{ hours: 2 }} />,
  },
  {
    minutes: 60 * 3,
    label: <FormattedMessage id="{hours} hours" values={{ hours: 3 }} />,
  },
];

export default function CleaningModal({
  cleaningItem,
  open,
  setOpen,
  isCleaningActive,
  mqttCleaningInfos,
  mqttSystem,
  setCleaningResponse,
}: CleaningModalProps): React.JSX.Element {
  const intl = useIntl();
  const toast = useToast();
  const cleaningTimes = useStore(
    (state) => state.organizationSettings.cleaningTimes,
  );
  const [timeOffset] = useState(new Date().getTimezoneOffset() * 60 * 1000);
  const [, setCleaningSceneMutation] = useSetSceneCleaningMutation();
  const [, setSceneNoneMutation] = useSetSceneNoneMutation();
  const [cleaningMinutesPolicy, setCleaningMinutesPolicy] = useState(60);
  const [start, setStart] = useState<string | null>(null);
  const [end, setEnd] = useState<string | null>(null);
  const hasuraHeader = useHasuraHeader();

  const [, updateFloorCleaningDurationMutation] =
    useUpdateFloorCleaningDurationMutation();

  const [, updateRoomCleaningDurationMutation] =
    useUpdateRoomCleaningDurationMutation();

  const [, updateDesksLastCleanedMutation] =
    useUpdateDesksLastCleanedMutation();

  useEffect(() => {
    if (cleaningItem?.CleaningDuration) {
      setStart(format(lower(cleaningItem.CleaningDuration), 'HH:mm:ss'));
      setEnd(format(upper(cleaningItem.CleaningDuration), 'HH:mm:ss'));
    }
  }, [cleaningItem, cleaningItem?.CleaningDuration]);

  return (
    <Modal
      action={isCleaningActive ? Action.REMOVE : Action.ADD}
      title={intl.formatMessage({
        id: isCleaningActive
          ? 'Deactivate cleaning mode'
          : 'Activate cleaning mode',
      })}
      open={open}
      setShowModal={setOpen}
      footer={
        <ModalFooter
          disabled={!mqttSystem || !mqttCleaningInfos}
          action={Action.UPDATE}
          proceed={
            isCleaningActive ? (
              <FormattedMessage id="Stop" />
            ) : (
              <FormattedMessage id="Start" />
            )
          }
          onProceed={() => {
            if (cleaningItem && mqttSystem && mqttCleaningInfos) {
              if (!isCleaningActive) {
                const cleaningInfo = mqttCleaningInfos
                  .filter((mI) => mI.data !== '0000')
                  .map((mqttCleaningInfo) => ({
                    beaconTopic: mqttCleaningInfo.beaconTopic,
                    data: [mqttCleaningInfo.data],
                  }));
                setCleaningSceneMutation(
                  {
                    BeaconInfos: cleaningInfo,
                    SceneDuration: cleaningMinutesPolicy * 60,
                    MqttSystem: mqttSystem,
                  },
                  hasuraHeader(HasuraPermissions.WRITE_CLEANING),
                ).then((data) => {
                  setCleaningResponse({
                    cleaning: {
                      successResponses:
                        data.data?.SetSceneCleaning?.successResponses ?? [],
                      failedResponses:
                        cleaningInfo
                          .map((c) => c.beaconTopic)
                          .filter(
                            (c) =>
                              // Get all beacons without a success response
                              !data.data?.SetSceneCleaning?.successResponses
                                .map((s) => s?.topic)
                                .includes(c),
                          )
                          .map((c) => ({ topic: c })) ?? [],
                    },
                    info: cleaningInfo,
                  });
                  toast(data, {
                    message: {
                      type: 'success',
                      content: isRoom(cleaningItem)
                        ? intl.formatMessage(
                            {
                              id: 'cleaning-room-started',
                            },
                            {
                              room: cleaningItem.Name,
                              count: (
                                data.data?.SetSceneCleaning?.successResponses ??
                                []
                              ).length,
                            },
                          )
                        : intl.formatMessage(
                            {
                              id: 'cleaning-started',
                            },
                            {
                              floorNumber: cleaningItem.Number,
                              count: (
                                data.data?.SetSceneCleaning?.successResponses ??
                                []
                              ).length,
                            },
                          ),
                    },
                  });
                  setOpen(false);
                });
                // TODO: Remove when cleaning function works in the lamp
                // Update LastCleaned directly in Desk because the lamp can't do it yet
                updateDesksLastCleanedMutation(
                  {
                    DeskIds: mqttCleaningInfos
                      .filter((mI) => mI.data !== '0000')
                      .flatMap((mI) =>
                        mI.relatedDesks
                          .filter((rD) => rD.hotMinutes >= cleaningTimes.clean)
                          .map((d) => d.id),
                      ),
                    LastCleaned: addMinutes(
                      // Timezone Offset is used because 'LastCleaned' is compared with
                      // History 'Duration' which uses timezone offset itself
                      new Date(new Date().valueOf() - timeOffset),
                      cleaningMinutesPolicy,
                    ),
                  },
                  hasuraHeader(HasuraPermissions.WRITE_CLEANING, [
                    'LiveCleaning',
                  ]),
                );
              } else {
                setCleaningResponse(null);
                setSceneNoneMutation(
                  {
                    Topics: mqttCleaningInfos.map((bI) => bI.beaconTopic),
                    MqttSystem: mqttSystem,
                  },
                  hasuraHeader(HasuraPermissions.WRITE_CLEANING, [
                    'Rooms',
                    'Desks',
                  ]),
                ).then((data) => {
                  toast(data, {
                    message: {
                      type: 'success',
                      content: isRoom(cleaningItem)
                        ? intl.formatMessage(
                            {
                              id: 'cleaning-room-stopped',
                            },
                            { room: cleaningItem.Name },
                          )
                        : intl.formatMessage(
                            {
                              id: 'cleaning-stopped',
                            },
                            { floorNumber: cleaningItem.Number },
                          ),
                    },
                  });
                  setOpen(false);
                });
                // Update LastCleaned directly in Desk when it's in the future to stop it
                // TODO: Remove when cleaning function works in the lamp
                updateDesksLastCleanedMutation(
                  {
                    DeskIds: mqttCleaningInfos.flatMap((mI) =>
                      mI.relatedDesks
                        .filter((rD) =>
                          isFuture(
                            new Date(rD.lastCleaned).valueOf() + timeOffset,
                          ),
                        )
                        .map((rD) => rD.id),
                    ),
                    LastCleaned: new Date(new Date().valueOf() - timeOffset),
                  },
                  hasuraHeader(HasuraPermissions.WRITE_CLEANING, [
                    'LiveCleaning',
                  ]),
                );
              }
              if (isRoom(cleaningItem)) {
                updateRoomCleaningDurationMutation(
                  {
                    Id: cleaningItem.Id,
                    CleaningDuration:
                      !isCleaningActive || !cleaningItem.CleaningDuration
                        ? getNewCleaningDuration(cleaningMinutesPolicy)
                        : stopCleaningDuration(cleaningItem.CleaningDuration),
                  },
                  hasuraHeader(HasuraPermissions.WRITE_CLEANING, ['Rooms']),
                ).finally(() => {
                  setOpen(false);
                });
              } else {
                updateFloorCleaningDurationMutation(
                  {
                    Id: cleaningItem.Id,
                    CleaningDuration:
                      !isCleaningActive || !cleaningItem.CleaningDuration
                        ? getNewCleaningDuration(cleaningMinutesPolicy)
                        : stopCleaningDuration(cleaningItem.CleaningDuration),
                  },
                  hasuraHeader(HasuraPermissions.WRITE_CLEANING, ['Floors']),
                ).finally(() => {
                  setOpen(false);
                });
              }
            }
          }}
          onCancel={() => {
            setOpen(false);
          }}
        />
      }
    >
      <div className="text-sm  text-neutral-700 dark:text-white space-y-4">
        {isCleaningActive && cleaningItem ? (
          <FormattedMessage
            id={
              isRoom(cleaningItem)
                ? 'stop-room-cleaning-warning'
                : 'stop-cleaning-warning'
            }
            values={{
              floor: !isRoom(cleaningItem) ? (
                <b>{cleaningItem.Number}</b>
              ) : undefined,
              room: isRoom(cleaningItem) ? (
                <b>{cleaningItem.Name}</b>
              ) : undefined,
              start: <b>{start}</b>,
              end: <b>{end}</b>,
            }}
          />
        ) : (
          cleaningItem && (
            <div className="flex flex-col justify-center gap-2">
              <div>
                <FormattedMessage
                  id={
                    isRoom(cleaningItem)
                      ? 'start-room-cleaning-warning'
                      : 'start-cleaning-warning'
                  }
                  values={{
                    floor: !isRoom(cleaningItem) ? (
                      <b>{cleaningItem.Number}</b>
                    ) : undefined,
                    room: isRoom(cleaningItem) ? (
                      <b>{cleaningItem.Name}</b>
                    ) : undefined,
                  }}
                />
              </div>
              <div>
                <FormattedMessage id="start-cleaning-info" />
              </div>
              <div>
                <FormattedMessage id="Please, select among the following timeouts:" />
              </div>
              <div className="grid grid-cols-3 gap-4">
                {buttons.map((bt) => (
                  <Button
                    key={bt.minutes}
                    className={`transition-all py-2 px-6 select-none rounded shadow border-neutral-200 dark:border-neutral-700 ${
                      cleaningMinutesPolicy === bt.minutes
                        ? 'bg-primary-600 text-white'
                        : ''
                    }`}
                    onClick={() => setCleaningMinutesPolicy(bt.minutes)}
                  >
                    {bt.label}
                  </Button>
                ))}
              </div>
            </div>
          )
        )}
      </div>
    </Modal>
  );
}
