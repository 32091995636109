import Subtitle from 'generic/components/Subtitle/Subtitle';
import { FormattedMessage, useIntl } from 'translations/Intl';

import { Action } from '@/common/types';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';

interface PrivateRoomModalProps {
  open: boolean;
  onConfirm: () => void;
  setShowModal: (arg: boolean) => void;
}

export default function PrivateRoomModal({
  open,
  onConfirm,
  setShowModal,
}: PrivateRoomModalProps): React.JSX.Element {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      setShowModal={setShowModal}
      action={Action.REMOVE}
      open={open}
      footer={
        <ModalFooter
          action={Action.REMOVE}
          proceed={intl.formatMessage({
            id: 'Confirm',
          })}
          onProceed={() => {
            onConfirm();
          }}
          onCancel={() => {
            setShowModal(false);
          }}
        />
      }
    >
      <Subtitle
        value={intl.formatMessage({ id: 'Remove private mode warning' })}
        className="text-base pb-2"
      />
      <p className="text-base pb-2">
        <FormattedMessage id="The entire history of the associated beacons will be irrevocably deleted when saved" />
      </p>
    </Modal>
  );
}
