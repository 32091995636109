import PermissionCheckbox from 'generic/components/PermissionCheckbox';
import { FormattedMessage, useIntl } from 'translations/Intl';

import { Action } from '@/common/types';
import Input from '@/generic/components/Form/Input/Input';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import Transition from '@/generic/components/Transition';
import {
  type ClientRoleInfos,
  type KeycloakUsersQuery,
  useInsertKeycloakGroupMutation,
  useKeycloakSubGroupMembersQuery,
  useKeycloakUserRolesQuery,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import { useEffect, useMemo, useState } from 'react';
import { FaCircleNotch } from 'react-icons/fa';
import { HiOutlineIdentification } from 'react-icons/hi2';

import type { KeycloakGroup } from '../RemoveGroupModal/RemoveGroupModal';
import MembersInput, { type User } from './MembersInput';

interface AddGroupModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedGroup?: KeycloakGroup;
  setSelectedGroup: (selectedGroup?: KeycloakGroup) => void;
}

type UserRole = ClientRoleInfos;
type Members = KeycloakUsersQuery['GetUsers']['groupMembers'][number];

export const ADMIN_GROUP = 'Admin';

export default function AddGroupModal({
  open,
  setOpen,
  selectedGroup,
  setSelectedGroup,
}: AddGroupModalProps): React.JSX.Element {
  const intl = useIntl();
  const [name, setName] = useState('');
  const [groupMembers, setGroupMembers] = useState<Members[] | null>(null);
  const [usersToAdd, setUsersToAdd] = useState<User[]>([]);
  const [usersToDelete, setUsersToDelete] = useState<User[]>([]);
  const [checkedRoles, setCheckedRoles] = useState<UserRole[]>([]);
  const [deletedRoles, setDeletedRoles] = useState<UserRole[]>([]);
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();

  const [{ fetching: loadingAddGroup }, addGroup] =
    useInsertKeycloakGroupMutation();

  const [{ data: roles, fetching: loading }] = useKeycloakUserRolesQuery();

  const [{ data: membersData, fetching: loadingGroupMembers }] =
    useKeycloakSubGroupMembersQuery({
      variables: { GroupId: selectedGroup?.id || '' },
      pause: !selectedGroup?.id,
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_USERGROUP),
        [hasuraHeader],
      ),
    });

  useEffect(() => {
    if (selectedGroup) {
      setName(selectedGroup.name);
      if (roles?.GetUserRoles.userRoles) {
        const newCheckedRoles = roles.GetUserRoles.userRoles
          .map((uR) =>
            uR.id && uR.name
              ? {
                  id: uR.id,
                  name: uR.name,
                }
              : null,
          )
          .filter(
            (uR) =>
              uR && selectedGroup.clientRoles?.mda2?.find((n) => n === uR.name),
          ) as UserRole[];
        setCheckedRoles(newCheckedRoles);
      }
    } else {
      setName('');
    }
  }, [selectedGroup, roles, roles?.GetUserRoles.userRoles]);

  useEffect(() => {
    if (!selectedGroup) {
      setCheckedRoles([]);
      setUsersToAdd([]);
      setGroupMembers(null);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (membersData?.GetUsersForSubGroup.groupMembers) {
      setGroupMembers(membersData.GetUsersForSubGroup.groupMembers);
    } else {
      setGroupMembers(null);
    }
  }, [membersData]);

  const resetValues = () => {
    setName('');
    setCheckedRoles([]);
    setDeletedRoles([]);
    setUsersToAdd([]);
    setUsersToDelete([]);
    setSelectedGroup(undefined);
    setOpen(false);
  };

  return (
    <Modal
      action={selectedGroup ? Action.UPDATE : Action.ADD}
      title={intl.formatMessage(
        {
          id: selectedGroup ? 'Edit group' : 'Add group',
        },
        { name: selectedGroup?.name },
      )}
      open={open}
      wrapperClassName="sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg"
      setShowModal={resetValues}
      footer={
        <ModalFooter
          disabled={!selectedGroup && (name === '' || loadingAddGroup)}
          action={selectedGroup ? Action.UPDATE : Action.ADD}
          loading={<FormattedMessage id="Save" />}
          isLoading={loadingAddGroup}
          onProceed={() => {
            addGroup(
              {
                Group: {
                  groupId: selectedGroup?.id,
                  groupName: name,
                  clientRolesToAdd: {
                    // Filter roles that are already set
                    mda2: !selectedGroup
                      ? checkedRoles
                      : checkedRoles.filter(
                          (role) =>
                            !selectedGroup.clientRoles?.mda2?.includes(
                              role.name,
                            ),
                        ),
                  },
                  clientRolesToDelete: {
                    // Filter roles that were not yet present
                    mda2: !selectedGroup
                      ? deletedRoles
                      : deletedRoles.filter((role) =>
                          selectedGroup.clientRoles?.mda2?.includes(role.name),
                        ),
                  },
                  // Filter members that are already in the group
                  usersToAdd: !selectedGroup
                    ? usersToAdd
                    : usersToAdd.filter(
                        (u) =>
                          !(
                            membersData?.GetUsersForSubGroup.groupMembers.map(
                              (groupMember) => groupMember.id,
                            ) || []
                          ).includes(u.id),
                      ),
                  // Filter members that were not yet present in group
                  usersToDelete: !selectedGroup
                    ? usersToDelete
                    : usersToDelete.filter((u) =>
                        (
                          membersData?.GetUsersForSubGroup.groupMembers.map(
                            (groupMember) => groupMember.id,
                          ) || []
                        ).includes(u.id),
                      ),
                },
              },
              hasuraHeader(HasuraPermissions.VIEW_USERGROUP, [
                'Group',
                'UsersOutput',
              ]),
            ).then((data) => {
              toast(data, {
                message: {
                  type: 'success',
                  content: intl.formatMessage(
                    { id: selectedGroup ? 'Updated group' : 'Added group' },
                    {
                      name: name || selectedGroup?.name,
                    },
                  ),
                },
              });
              resetValues();
            });
          }}
          onCancel={resetValues}
        />
      }
    >
      <div className="text-sm text-neutral-700 dark:text-white space-y-4">
        <Input
          type="text"
          value={name}
          label={intl.formatMessage({
            id: 'Name',
          })}
          disabled={selectedGroup?.name === ADMIN_GROUP}
          placeholder={intl.formatMessage({
            id: 'Name',
          })}
          id="group-name"
          renderIcon={({ className }) => (
            <HiOutlineIdentification className={className} />
          )}
          onChangeValue={(e) => setName(e)}
          required
          data-test-id="group-name"
        />
        <Transition show={loading && !!selectedGroup}>
          <FaCircleNotch className="size-5 animate-spin text-primary-500" />
        </Transition>
        <Transition
          show={
            selectedGroup && !!roles?.GetUserRoles
              ? selectedGroup.name !== ADMIN_GROUP
              : !!roles?.GetUserRoles
          }
        >
          <PermissionCheckbox
            roles={
              roles?.GetUserRoles.userRoles.map((r) => ({
                id: r.id ?? '0',
                name: r.name ?? '',
              })) ?? []
            }
            checkedRoles={checkedRoles}
            setCheckedRoles={setCheckedRoles}
            deletedRoles={deletedRoles}
            setDeletedRoles={setDeletedRoles}
          />
        </Transition>
        <MembersInput
          selectedGroup={selectedGroup?.name}
          members={groupMembers}
          loadingGroupMembers={loadingGroupMembers}
          usersToAdd={usersToAdd}
          setUsersToAdd={setUsersToAdd}
          usersToDelete={usersToDelete}
          setUsersToDelete={setUsersToDelete}
        />
      </div>
    </Modal>
  );
}
