import { endOfDayUTC, startOfDayUTC } from 'utils/date';

import useStore from '@/model/store';
import { useMemo } from 'react';

export default function useAnalyticsFilter() {
  const dateRange = useStore((state) => state.userSettings.dateRange);
  const dateFrom = useMemo(() => new Date(dateRange.start), [dateRange.start]);
  const dateTo = useMemo(
    () => (dateRange.end ? new Date(dateRange.end) : null),
    [dateRange.end],
  );
  const building = useStore((state) => state.userSettings.building);
  const floor = useStore((state) => state.userSettings.floor);
  const room = useStore((state) => state.userSettings.room);
  const labels = useStore((state) => state.userSettings.labels);

  return {
    Building: building?.Name,
    Floor: floor?.Number,
    Room: room?.Name,
    Start: startOfDayUTC(dateFrom),
    End: dateTo && endOfDayUTC(dateTo),
    Labels:
      (labels?.length ?? 0) > 0
        ? JSON.stringify(labels).replace('[', '{').replace(']', '}')
        : null,
  };
}
