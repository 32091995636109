import Input from 'generic/components/Form/Input';
import type { ReportMetricsQuery } from 'graphql/types';
import { useEffect, useState } from 'react';
import { HiOutlineTrophy } from 'react-icons/hi2';
import { useIntl } from 'translations/Intl';

interface TargetValueInputProps {
  data?: ReportMetricsQuery['ReportPages'][number]['ReportPageMetrics'][number]['MetricLimits'][number];
  callback: (targetValue: number) => void;
}

export default function TargetValueInput({
  callback,
  data,
}: TargetValueInputProps) {
  const intl = useIntl();
  const [targetValue, setTargetValue] = useState(data?.TargetValue ?? 100);

  // Set the initial values when showing the input
  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run once in order to set initial values
  useEffect(() => {
    callback(targetValue);
  }, []);

  return (
    <Input
      renderIcon={({ className }) => <HiOutlineTrophy className={className} />}
      type="number"
      value={targetValue}
      min={0}
      max={100}
      label={intl.formatMessage({
        id: 'Target value (%)',
      })}
      tooltipText={intl.formatMessage({
        id: 'targetvalue-description',
      })}
      onChangeValue={(v) => {
        const targetValueValue = Number.parseInt(v, 10);
        setTargetValue(targetValueValue);
        callback(targetValueValue);
      }}
      required
    />
  );
}
