import Card from '@/generic/components/Card';
import StyledButton from '@/generic/components/Form/Button/StyledButton';
import Table from '@/generic/components/Table';
import Title from '@/generic/components/Title';
import Tooltip from '@/generic/components/Tooltip';
import {
  type OrganizationOverviewQuery,
  useOrganizationOverviewQuery,
} from '@/graphql/types';
import { FormattedMessage, useIntl } from '@/translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import type { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { HiOutlinePrinter } from 'react-icons/hi2';
import BuildingFloorList from '../BuildingFloorList';
import ReportSummary from './components/ReportSummary';
import ReportMap from './components/ReportingMap';

export default function Overview() {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [{ data, fetching }] = useOrganizationOverviewQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_ADMIN),
      [hasuraHeader],
    ),
  });

  const columns: ColumnDef<
    OrganizationOverviewQuery['ReportOverviewData'][number]
  >[] = useMemo(
    () => [
      {
        id: 'building',
        header: intl.formatMessage({ id: 'Building' }),
        accessorFn: (row) => row.Building,
      },
      {
        id: 'floor',
        header: intl.formatMessage({ id: 'Floor count' }),
        accessorFn: (row) => row.FloorCount,
      },
      {
        id: 'deviceType',
        header: intl.formatMessage({ id: 'Device type' }),
        accessorFn: (row) =>
          row.DeviceType ?? intl.formatMessage({ id: 'Unknown' }),
      },
      {
        id: 'numberOfDesks',
        header: intl.formatMessage({ id: 'PIR sensors' }),
        accessorFn: (row) => row.NumberOfAvailableDesks ?? '-',
      },
      {
        id: 'deskCount',
        header: intl.formatMessage({ id: 'Desks' }),
        accessorFn: (row) => row.DeskCount,
      },
      {
        id: 'beaconCount',
        header: intl.formatMessage({ id: 'Beacons' }),
        accessorFn: (row) => row.BeaconCount,
      },
    ],
    [intl.formatMessage],
  );

  const tableData = useMemo(() => data?.ReportOverviewData ?? [], [data]);

  return (
    <Card className="print:!bg-transparent relative print:border-none print:!px-0 print:!py-0 space-y-2">
      <div className="flex justify-between items-center">
        <Title value="Overview" />
        <Tooltip
          content={
            <StyledButton
              className="print:hidden"
              onClick={() => window.print()}
            >
              <HiOutlinePrinter className="size-5" />
            </StyledButton>
          }
        >
          <FormattedMessage id="Print" />
        </Tooltip>
      </div>
      <Table
        id="report-overview"
        dataTestId="report-overview-table"
        data={tableData}
        columns={columns}
        loading={fetching}
        getRowId={(row) =>
          `${row.Building}-${row.FloorCount}-${row.DeviceType}-${row.NumberOfAvailableDesks}`
        }
        enabledFeatures={{
          enableGlobalFilter: false,
          enableColumnSelection: false,
          enablePagination: false,
          enableColumnFilter: false,
          enableCsvExport: false,
        }}
        initialState={{
          pagination: {
            // Do not paginate as it won't be visible on the PDF
            pageSize: tableData.length,
            pageIndex: 0,
          },
        }}
      />
      <BuildingFloorList
        loading={fetching}
        getTestId={(building) => `report-overview-${building.Name}`}
        buildings={data?.Buildings ?? []}
        renderMap={(_, floor) => <ReportMap floor={floor} />}
        renderTable={(building, floor) =>
          data?.ReportOverviewFloorData.filter(
            (d) => d.Building === building.Name && d.Floor === floor.Number,
          ) && (
            <ReportSummary
              data={data.ReportOverviewFloorData.filter(
                (d) => d.Building === building.Name && d.Floor === floor.Number,
              )}
            />
          )
        }
      />
    </Card>
  );
}
