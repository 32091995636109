import Select from '@/generic/components/Form/Select/Select';
import {
  type LabelPartsFragment,
  useDeleteLabelMutation,
  useInsertLabelMutation,
  useRoomLabelsQuery,
  useUpdateLabelMutation,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'translations/Intl';

export default function RoomLabelInput({
  setRoomLabelsToAdd,
  setRoomLabelsToDelete,
  roomId,
}: {
  setRoomLabelsToAdd: React.Dispatch<React.SetStateAction<string[] | null>>;
  setRoomLabelsToDelete: (value: LabelPartsFragment[]) => void;
  roomId?: number;
}) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [initialRoomLabels, setInitialRoomLabels] = useState<
    LabelPartsFragment[]
  >([]);
  const [selectedLabels, setSelectedLabels] = useState<string[] | null>([]);
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [, addLabel] = useInsertLabelMutation();
  const [, editLabel] = useUpdateLabelMutation();
  const [, deleteLabel] = useDeleteLabelMutation();

  const [{ data: roomLabels }] = useRoomLabelsQuery({
    context: useMemo(() => ({ additionalTypenames: ['Labels'] }), []),
    variables: {
      RoomId: roomId || 0,
      RequestRoom: !!roomId,
    },
  });

  const labelOptions = useMemo(
    () => roomLabels?.Labels ?? [],
    [roomLabels?.Labels],
  );

  useEffect(() => {
    if (!isInitialized && !initialRoomLabels.length && roomLabels?.RoomLabel) {
      setIsInitialized(true);
      setSelectedLabels(roomLabels.RoomLabel.map((rl) => rl.Label.Name));
      setInitialRoomLabels(roomLabels.RoomLabel.map((rl) => rl.Label));
    }
  }, [
    isInitialized,
    initialRoomLabels.length,
    roomLabels,
    roomLabels?.RoomLabel,
  ]);

  return (
    <Select
      label="Labels"
      renderValue={(v) => v ?? ''}
      optionPlaceholder={intl.formatMessage({ id: 'Label name' })}
      value={selectedLabels}
      dataTestId="room-label-select"
      onChangeSelected={(selected) => {
        setSelectedLabels(selected);

        setRoomLabelsToAdd(
          selected?.filter(
            (selectedLabel) =>
              !initialRoomLabels.find((l) => l.Name === selectedLabel),
          ) ?? null,
        );
        setRoomLabelsToDelete(
          initialRoomLabels.filter((rL) => !selected?.includes(rL.Name)),
        );
      }}
      options={labelOptions.map((l) => l.Name)}
      onOptionAdd={(newLabel) =>
        addLabel(
          { Name: newLabel },
          hasuraHeader(HasuraPermissions.WRITE_ROOM),
        ).then((resp) => {
          if (!resp.error) {
            setSelectedLabels((prev) =>
              prev ? [...prev, newLabel] : [newLabel],
            );
            setRoomLabelsToAdd((prev) =>
              prev ? [...prev, newLabel] : [newLabel],
            );
          }
          toast(resp);
        })
      }
      onOptionEdit={(label: string, oldLabel: string) => {
        const labelToEdit = labelOptions.find((l) => l.Name === oldLabel);

        return labelToEdit
          ? editLabel(
              {
                Id: labelToEdit.Id,
                Name: label,
              },
              hasuraHeader(HasuraPermissions.WRITE_ROOM),
            ).then((data) => {
              setSelectedLabels((prev) =>
                // Check if label was previously selected and add it again with new name if it was
                // Otherwise just keep the old selection
                prev?.find((p) => p === oldLabel)
                  ? [...prev.filter((s) => s !== oldLabel), label]
                  : prev,
              );
              toast(data);
            })
          : undefined;
      }}
      onOptionDelete={(label) => {
        const labelToDelete = labelOptions.find((l) => l.Name === label);

        return labelToDelete
          ? deleteLabel(
              {
                Id: labelToDelete.Id,
              },
              hasuraHeader(HasuraPermissions.WRITE_ROOM),
            ).then((data) => {
              setSelectedLabels((prev) =>
                prev ? [...prev.filter((s) => s !== labelToDelete.Name)] : [],
              );
              toast(data);
            })
          : undefined;
      }}
    />
  );
}
