import useStore from 'model/store';
import { FormattedMessage } from 'translations/Intl';

import Select from '@/generic/components/Form/Select';
import type { FloorPartsFragment } from '@/graphql/types';
import { useMemo } from 'react';

interface FloorSelectProps {
  floors?: FloorPartsFragment[];
  isDeselectable?: boolean;
  onFloorSelect?: (f: FloorPartsFragment | null) => void;
  floorNumber?: number | null;
}

export default function FloorSelect({
  floors = [],
  isDeselectable = false,
  onFloorSelect,
  floorNumber,
}: FloorSelectProps): React.JSX.Element {
  const floor = useStore((state) => state.userSettings.floor);
  const setFloor = useStore((state) => state.userApi.setFloor);
  const params = useMemo(() => new URLSearchParams(), []);

  return (
    <Select
      dataTestId="select-floor"
      value={
        floors.find((f) =>
          floorNumber !== undefined
            ? f.Number === floorNumber
            : f.Id === floor?.Id,
        ) || (!isDeselectable ? floors[0] : null)
      }
      label="Floor"
      onChangeSelected={(selected) => {
        if (onFloorSelect) {
          onFloorSelect(selected ?? null);
        } else if (selected) {
          setFloor({ Id: selected.Id, Number: selected.Number });
        } else {
          setFloor(undefined);
          params.delete('floor');
        }
      }}
      options={floors}
      isDeselectable={isDeselectable}
      renderValue={(f) => f?.Number.toString() ?? ''}
      keyParameter="Id"
      defaultValue={<FormattedMessage id="All" />}
    />
  );
}
