// import { curveBasis } from '@visx/curve';
// import { LinearGradient } from '@visx/gradient';
// import { GridRows } from '@visx/grid';
// import { Group } from '@visx/group';
// import { ParentSize } from '@visx/responsive';
// import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
// import { BarStack, LinePath } from '@visx/shape';
// import type { BarGroupBar, SeriesPoint } from '@visx/shape/lib/types';
// import { defaultStyles, useTooltip, TooltipWithBounds } from '@visx/tooltip';
// import type { Point } from 'framer-motion';
// import AnimatedRect from 'generic/components/Chart/AnimatedRect';
// import { Themes, type MarginProps } from '@/common/types';
// import Axis from '@/generic/components/Chart/Axis';
// import Legend from '@/generic/components/Chart/Legend';
// import DaySelect from '@/generic/components/DaySelect';
// import LoadingSpinner from '@/generic/components/LoadingSpinner';
// import {
//   useHistoryOccupancyQuery,
//   type HistoryOccupancyQuery,
// } from '@/graphql/types';
// import useStore from '@/model/store';
// import getColor from '@/utils/getColor';
// import useHasuraHeader, {
//   HasuraPermissions,
// } from '@/utils/graphql/useHasuraHeaders';
// import { useMemo, useState } from 'react';
// import { FormattedMessage, useIntl } from 'translations/Intl';
// import { endOfDayUTC, startOfDayUTC } from 'utils/date';
// import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';
// import { AnimatedPath } from '../BrushCart/Components/AreaChart';

// let tooltipTimeout: NodeJS.Timeout;

// interface ResponsiveBarOccupancyProps {
//   margin?: MarginProps;
//   meetingRoomOccupancy?: boolean;
//   selected?: {
//     floor?: number;
//     building?: string;
//     start?: Date;
//     end?: Date;
//   };
// }

// interface BarOccupancyProps extends ResponsiveBarOccupancyProps {
//   height: number;
//   width: number;
// }

// type ChartData = Exclude<
//   HistoryOccupancyQuery['f_history_desks_occupancy_weekly'],
//   undefined
// >[number];

// type MeetingChartData = Exclude<
//   HistoryOccupancyQuery['f_history_rooms_occupancy_weekly'],
//   undefined
// >[number];

// function isRoom(
//   feature: ChartData | MeetingChartData,
// ): feature is MeetingChartData {
//   if ((feature as MeetingChartData)?.MaxPercentageUsedMeetingRooms) {
//     return true;
//   }
//   return false;
// }

// function BarOccupancy({
//   height,
//   width,
//   margin = {
//     // 768 -> MD breakpoint
//     top: 100,
//     left: 70,
//     right: 32,
//     bottom: 60,
//   },
//   meetingRoomOccupancy = false,
//   selected,
// }: BarOccupancyProps) {
//   type State = (typeof keys)[number];

//   type TooltipData = Omit<BarGroupBar<State>, 'key' | 'value'> & {
//     bar: SeriesPoint<ChartData | MeetingChartData>;
//     key: State;
//   };

//   const intl = useIntl();
//   const hasuraHeader = useHasuraHeader();
//   const [dayOfWeek, setDayOfWeek] = useState<number | null>(null);
//   const theme = useStore((state) => state.userSettings.theme);
//   const [defaultData] = useState([
//     {
//       Hour: 0,
//       DayOfWeek: 0,
//       PercentageHotMinutes: 0,
//       PercentageMaxMinutes: 0,
//       PercentageWarmMinutes: 0,
//       PercentageMaxOccupancy: 0,
//       NumberOfDesks: 0,
//       __typename: 'DesksUsagePerHour' as const,
//     },
//   ]);
//   const [defaultMeetingData] = useState([
//     {
//       Hour: 0,
//       DayOfWeek: 0,
//       UsedMeetingRooms: 0,
//       TotalMeetingRooms: 0,
//       PercentageUsedMeetingRooms: 0,
//       MaxPercentageUsedMeetingRooms: 0,
//       __typename: 'MeetingRoomsHistoryOccupancy' as const,
//     },
//   ]);

//   const [{ data: historyData, fetching: loading }] = useHistoryOccupancyQuery({
//     variables: {
//       ...(selected
//         ? {
//             Floor: selected.floor,
//             Building: selected.building,
//             Start: selected.start && startOfDayUTC(selected.start),
//             End: selected.end && endOfDayUTC(selected.end),
//           }
//         : useAnalyticsFilter()),
//       HourlyRooms: dayOfWeek !== null && meetingRoomOccupancy,
//       WeeklyRooms: dayOfWeek === null && meetingRoomOccupancy,
//       HourlyDesks: dayOfWeek !== null && !meetingRoomOccupancy,
//       WeeklyDesks: dayOfWeek === null && !meetingRoomOccupancy,
//     },
//     context: useMemo(
//       () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
//       [hasuraHeader],
//     ),
//   });

//   const data = useMemo(() => {
//     if (!meetingRoomOccupancy) {
//       if (
//         dayOfWeek === null &&
//         historyData?.f_history_desks_occupancy_weekly &&
//         historyData.f_history_desks_occupancy_weekly.length > 0
//       ) {
//         return historyData.f_history_desks_occupancy_weekly;
//       }

//       if (
//         dayOfWeek !== null &&
//         historyData?.f_history_desks_occupancy_hourly &&
//         historyData.f_history_desks_occupancy_hourly.length > 0
//       ) {
//         const dailyData = historyData.f_history_desks_occupancy_hourly.filter(
//           (d) => d.DayOfWeek === dayOfWeek,
//         );

//         return dailyData.length > 0 ? dailyData : defaultData;
//       }

//       return defaultData;
//     }

//     return defaultData;
//   }, [
//     dayOfWeek,
//     defaultData,
//     historyData,
//     historyData?.f_history_desks_occupancy_hourly,
//     historyData?.f_history_desks_occupancy_weekly,
//     meetingRoomOccupancy,
//   ]);

//   const meetingData = useMemo(() => {
//     if (meetingRoomOccupancy) {
//       if (
//         dayOfWeek === null &&
//         historyData?.f_history_rooms_occupancy_weekly &&
//         historyData.f_history_rooms_occupancy_weekly.length > 0
//       ) {
//         return historyData.f_history_rooms_occupancy_weekly;
//       }

//       if (
//         dayOfWeek !== null &&
//         historyData?.f_history_rooms_occupancy_hourly &&
//         historyData.f_history_rooms_occupancy_hourly.length > 0
//       ) {
//         const dailyData = historyData.f_history_rooms_occupancy_hourly.filter(
//           (d) => d.DayOfWeek === dayOfWeek,
//         );

//         return dailyData.length > 0 ? dailyData : defaultMeetingData;
//       }
//       return defaultMeetingData;
//     }

//     return defaultMeetingData;
//   }, [
//     dayOfWeek,
//     defaultMeetingData,
//     historyData,
//     historyData?.f_history_rooms_occupancy_hourly,
//     historyData?.f_history_rooms_occupancy_weekly,
//     meetingRoomOccupancy,
//   ]);

//   const xMax = Math.max(width - margin.left - margin.right, 0);
//   const yMax = height - margin.top - margin.bottom;

//   const keys = meetingRoomOccupancy
//     ? ['PercentageUsedMeetingRooms', 'MaxPercentageUsedMeetingRooms']
//     : ['PercentageHotMinutes', 'PercentageWarmMinutes', 'PercentageMaxMinutes'];

//   const hoursRange = meetingRoomOccupancy
//     ? meetingData.map((d) => d.Hour)
//     : data.map((d) => d.Hour);

//   const xScale = scaleBand<number>({
//     range: [0, xMax],
//     domain: hoursRange,
//     paddingInner: 0.2,
//   });

//   const yScale = scaleLinear<number>({
//     range: [yMax, 0],
//     domain: [0, 100],
//     nice: true,
//   });

//   const colorScale = scaleOrdinal({
//     domain: keys,
//     range: meetingRoomOccupancy
//       ? [
//           getColor('RED'), // PercentageUsedMeetingRooms
//           theme.color === Themes.LIGHT // MaxPercentageUsedMeetingRooms
//             ? getColor('NEUTRAL200')
//             : getColor('NEUTRAL600'),
//         ]
//       : [
//           getColor('RED'), // PercentageHotMinutes
//           getColor('YELLOW'), // PercentageWarmMinutes
//           theme.color === Themes.LIGHT
//             ? getColor('NEUTRAL200')
//             : getColor('NEUTRAL600'), // PercentageHotMinutes / PercentageMaxMinutes
//           theme.color === Themes.LIGHT
//             ? getColor('NEUTRAL600')
//             : getColor('NEUTRAL300'), // PercentageMaxOccupancy
//         ],
//   });

//   const {
//     tooltipOpen,
//     tooltipLeft,
//     tooltipTop,
//     tooltipData,
//     hideTooltip,
//     showTooltip,
//   } = useTooltip<ChartData | MeetingChartData>();

//   const onMouseMove = (
//     event: Point | null,
//     bar: TooltipData,
//     data?: ChartData | MeetingChartData,
//   ) => {
//     if (tooltipTimeout) clearTimeout(tooltipTimeout);
//     const left = bar.x + bar.width / 2;
//     showTooltip({
//       tooltipData: data,
//       tooltipTop: event?.y,
//       tooltipLeft: left,
//     });
//   };

//   const onMouseOut = () => {
//     tooltipTimeout = setTimeout(() => {
//       hideTooltip();
//     }, 300);
//   };

//   return (
//     <>
//       <LoadingSpinner loading={loading} />
//       <div
//         className="relative"
//         data-test-id={`occupancy-chart-${meetingRoomOccupancy}`}
//       >
//         <svg width={width} height={height}>
//           <Group top={margin.top} left={margin.left}>
//             <LinearGradient
//               id="area-red-gradient"
//               from={getColor('RED')}
//               to={getColor('RED')}
//               toOpacity={0.8}
//             />
//             <LinearGradient
//               id="area-yellow-gradient"
//               from={getColor('YELLOW')}
//               to={getColor('YELLOW')}
//               toOpacity={0.8}
//             />
//             <GridRows
//               numTicks={10}
//               scale={yScale}
//               width={xMax}
//               height={yMax}
//               strokeDasharray="1,3"
//               stroke={getColor('NEUTRAL600')}
//               strokeOpacity={0.6}
//             />
//             {!meetingRoomOccupancy && (
//               <BarStack
//                 data={data}
//                 keys={['PercentageMaxMinutes']}
//                 x={(d) => d.Hour}
//                 xScale={xScale}
//                 yScale={yScale}
//                 color={colorScale}
//               >
//                 {(barStacks) =>
//                   barStacks.map((barStack) =>
//                     barStack.bars.map((bar) => (
//                       <AnimatedRect
//                         onMouseMove={(event) =>
//                           onMouseMove(event, bar, data[bar.index])
//                         }
//                         onMouseOut={onMouseOut}
//                         bar={bar}
//                         rounded
//                         key={`bar-stack-${barStack.index}-${bar.index}`}
//                       />
//                     )),
//                   )
//                 }
//               </BarStack>
//             )}
//             {meetingRoomOccupancy ? (
//               <BarStack
//                 data={meetingData}
//                 keys={[
//                   'PercentageUsedMeetingRooms',
//                   'MaxPercentageUsedMeetingRooms',
//                 ]}
//                 x={(d) => d.Hour}
//                 xScale={xScale}
//                 yScale={yScale}
//                 color={colorScale}
//               >
//                 {(barStacks) =>
//                   barStacks.map((barStack) =>
//                     barStack.bars.map((bar) => (
//                       <AnimatedRect
//                         onMouseMove={(event) =>
//                           onMouseMove(event, bar, meetingData[bar.index])
//                         }
//                         onMouseOut={onMouseOut}
//                         bar={{
//                           ...bar,
//                           color:
//                             bar.key === 'PercentageUsedMeetingRooms'
//                               ? 'url(#area-red-gradient)'
//                               : bar.color,
//                         }}
//                         key={`bar-stack2-${barStack.index}-${bar.index}`}
//                         dataTestId={`bar-occupancy-${data[bar.index]?.Hour}`}
//                       />
//                     )),
//                   )
//                 }
//               </BarStack>
//             ) : (
//               <BarStack
//                 data={data}
//                 keys={['PercentageHotMinutes', 'PercentageWarmMinutes']}
//                 x={(d) => d.Hour}
//                 xScale={xScale}
//                 yScale={yScale}
//                 color={colorScale}
//               >
//                 {(barStacks) =>
//                   barStacks.map((barStack) =>
//                     barStack.bars.map((bar) => (
//                       <AnimatedRect
//                         onMouseMove={(event) =>
//                           onMouseMove(event, bar, data[bar.index])
//                         }
//                         onMouseOut={onMouseOut}
//                         bar={{
//                           ...bar,
//                           color:
//                             bar.key === 'PercentageWarmMinutes'
//                               ? 'url(#area-yellow-gradient)'
//                               : 'url(#area-red-gradient)',
//                         }}
//                         key={`bar-stack3-${barStack.index}-${bar.index}`}
//                         dataTestId={`bar-occupancy-${data[bar.index]?.Hour}`}
//                       />
//                     )),
//                   )
//                 }
//               </BarStack>
//             )}
//             {!meetingRoomOccupancy && (
//               <LinePath
//                 data={data}
//                 curve={curveBasis}
//                 x={(d) => xScale(d.Hour) ?? 0}
//                 y={(d) => yScale(d.PercentageMaxOccupancy) ?? 0}
//               >
//                 {({ path }) => (
//                   <AnimatedPath
//                     path={path}
//                     data={data}
//                     stroke={colorScale('PercentageMaxOccupancy')}
//                   />
//                 )}
//               </LinePath>
//             )}
//             <Axis
//               lowLevelChart
//               orientation="left"
//               scale={yScale}
//               tickFormat={(y) => `${y}%`}
//               label={intl.formatMessage({ id: 'Occupancy' })}
//             />
//             <Axis
//               lowLevelChart
//               orientation="bottom"
//               numTicks={12}
//               label={intl.formatMessage({
//                 id: 'Hour of the day',
//               })}
//               scale={xScale}
//               top={yMax}
//             />
//           </Group>
//         </svg>
//         <div className="absolute w-full flex flex-wrap justify-center top-16 text-xs">
//           <Legend
//             scaleType="ordinal"
//             labelFormat={(d) =>
//               intl.formatMessage({
//                 id: d,
//               })
//             }
//             scale={colorScale}
//           />
//         </div>
//         <div className="absolute top-6 w-full flex justify-center px-2">
//           <DaySelect dayOfWeek={dayOfWeek} setDayOfWeek={setDayOfWeek} />
//         </div>
//         {tooltipOpen && tooltipData && (
//           <TooltipWithBounds
//             top={tooltipTop}
//             left={tooltipLeft}
//             style={{
//               ...defaultStyles,
//               background:
//                 theme.color === Themes.LIGHT
//                   ? getColor('WHITE')
//                   : getColor('NEUTRAL900'),
//             }}
//           >
//             <div
//               className="dark:text-neutral-200"
//               data-test-id={`occupancy-chart-tooltip-${meetingRoomOccupancy}`}
//             >
//               {`${tooltipData.Hour}:00 - ${
//                 (tooltipData.Hour ?? 0) + 1 === 24
//                   ? '0'
//                   : (tooltipData.Hour ?? 0) + 1
//               }:00`}
//               {isRoom(tooltipData) ? (
//                 <>
//                   <div className="flex">
//                     <p
//                       style={{
//                         color: colorScale('MaxPercentageUsedMeetingRooms'),
//                       }}
//                     >
//                       <FormattedMessage id="MaxPercentageUsedMeetingRooms" />
//                     </p>
//                     {': '}
//                     {`${tooltipData.MaxPercentageUsedMeetingRooms}%`}
//                   </div>
//                   <div className="flex">
//                     <p
//                       style={{
//                         color: colorScale('PercentageUsedMeetingRooms'),
//                       }}
//                     >
//                       <FormattedMessage id="PercentageUsedMeetingRooms" />
//                     </p>
//                     {': '}
//                     {`${tooltipData.PercentageUsedMeetingRooms}%`}
//                   </div>
//                 </>
//               ) : (
//                 <>
//                   <div className="flex">
//                     <p
//                       style={{
//                         color: colorScale('PercentageMaxOccupancy'),
//                       }}
//                     >
//                       <FormattedMessage id="PercentageMaxOccupancy" />
//                     </p>
//                     {': '}
//                     {`${tooltipData.PercentageMaxOccupancy}%`}
//                   </div>
//                   <div className="flex">
//                     <p
//                       style={{
//                         color: colorScale('PercentageMaxMinutes'),
//                       }}
//                     >
//                       <FormattedMessage id="PercentageMaxMinutes" />
//                     </p>
//                     {': '}
//                     {`${tooltipData.PercentageMaxMinutes}%`}
//                   </div>
//                   <div className="flex">
//                     <p
//                       style={{
//                         color: colorScale('PercentageHotMinutes'),
//                       }}
//                     >
//                       <FormattedMessage id="PercentageHotMinutes" />
//                     </p>
//                     {': '}
//                     {`${tooltipData.PercentageHotMinutes}%`}
//                   </div>
//                   <div className="flex">
//                     <p
//                       style={{
//                         color: colorScale('PercentageWarmMinutes'),
//                       }}
//                     >
//                       <FormattedMessage id="PercentageWarmMinutes" />
//                     </p>
//                     {': '}
//                     {`${tooltipData.PercentageWarmMinutes}%`}
//                   </div>
//                 </>
//               )}
//             </div>
//           </TooltipWithBounds>
//         )}
//       </div>
//     </>
//   );
// }

// export default function ResponsiveBarOccupancy(
//   props: ResponsiveBarOccupancyProps,
// ) {
//   return (
//     <ParentSize>
//       {({ height, width }) => (
//         <BarOccupancy {...props} height={height} width={width} />
//       )}
//     </ParentSize>
//   );
// }

import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';
import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';

import { type MarginProps, Themes } from '@/common/types';
import Axis from '@/generic/components/Chart/Axis';
import Legend from '@/generic/components/Chart/Legend';
import DaySelect from '@/generic/components/DaySelect';
import LoadingSpinner from '@/generic/components/LoadingSpinner';
import { useHistoryOccupancyQuery } from '@/graphql/types';
import useStore from '@/model/store';
import getColor from '@/utils/getColor';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import { curveBasis } from '@visx/curve';
import { ParentSize } from '@visx/responsive';
import { scaleOrdinal } from '@visx/scale';
import {
  AnimatedBarSeries,
  AnimatedBarStack,
  AnimatedLineSeries,
  Grid,
  XYChart,
  Tooltip as XYTooltip,
} from '@visx/xychart';
import { useMemo, useState } from 'react';
import { endOfDayUTC, startOfDayUTC } from 'utils/date';
import useXYChartTheme from 'utils/useXYChartTheme';

interface ResponsiveBarOccupancyProps {
  margin?: MarginProps;
  meetingRoomOccupancy?: boolean;
  selected?: {
    floor?: number;
    building?: string;
    start?: Date;
    end?: Date;
  };
}

interface BarOccupancyProps extends ResponsiveBarOccupancyProps {
  height: number;
}

function BarOccupancy({
  height,
  margin = {
    top: 100,
    left: 70,
    right: 32,
    bottom: 60,
  },
  meetingRoomOccupancy = false,
  selected,
}: BarOccupancyProps) {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [numTicks] = useState(5);
  const [dayOfWeek, setDayOfWeek] = useState<number | null>(null);
  const theme = useStore((state) => state.userSettings.theme);
  const xyTheme = useXYChartTheme({
    colorRange: meetingRoomOccupancy
      ? [
          theme.color === Themes.LIGHT // MaxPercentageUsedMeetingRooms
            ? getColor('NEUTRAL200')
            : getColor('NEUTRAL600'),
          getColor('RED'), // PercentageUsedMeetingRooms
        ]
      : [
          theme.color === Themes.LIGHT
            ? getColor('NEUTRAL200')
            : getColor('NEUTRAL600'), // PercentageHotMinutes / PercentageMaxMinutes
          getColor('RED'), // PercentageHotMinutes
          getColor('YELLOW'), // PercentageWarmMinutes
          theme.color === Themes.LIGHT
            ? getColor('NEUTRAL600')
            : getColor('NEUTRAL300'), // PercentageMaxOccupancy
        ],
  });
  const [defaultData] = useState([
    {
      Hour: 0,
      DayOfWeek: 0,
      PercentageHotMinutes: 0,
      PercentageMaxMinutes: 0,
      PercentageWarmMinutes: 0,
      PercentageMaxOccupancy: 0,
      NumberOfDesks: 0,
      __typename: 'DesksUsagePerHour' as const,
    },
  ]);
  const [defaultMeetingData] = useState([
    {
      Hour: 0,
      DayOfWeek: 0,
      UsedMeetingRooms: 0,
      TotalMeetingRooms: 0,
      PercentageUsedMeetingRooms: 0,
      MaxPercentageUsedMeetingRooms: 0,
      __typename: 'MeetingRoomsHistoryOccupancy' as const,
    },
  ]);

  const [{ data: historyData, fetching: loading }] = useHistoryOccupancyQuery({
    variables: {
      ...(selected
        ? {
            Floor: selected.floor,
            Building: selected.building,
            Start: selected.start && startOfDayUTC(selected.start),
            End: selected.end && endOfDayUTC(selected.end),
          }
        : useAnalyticsFilter()),
      HourlyRooms: dayOfWeek !== null && meetingRoomOccupancy,
      WeeklyRooms: dayOfWeek === null && meetingRoomOccupancy,
      HourlyDesks: dayOfWeek !== null && !meetingRoomOccupancy,
      WeeklyDesks: dayOfWeek === null && !meetingRoomOccupancy,
    },
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
      [hasuraHeader],
    ),
  });

  const data = useMemo(() => {
    if (!meetingRoomOccupancy) {
      if (
        dayOfWeek === null &&
        historyData?.f_history_desks_occupancy_weekly &&
        historyData.f_history_desks_occupancy_weekly.length > 0
      ) {
        return historyData.f_history_desks_occupancy_weekly;
      }

      if (
        dayOfWeek !== null &&
        historyData?.f_history_desks_occupancy_hourly &&
        historyData.f_history_desks_occupancy_hourly.length > 0
      ) {
        const dailyData = historyData.f_history_desks_occupancy_hourly.filter(
          (d) => d.DayOfWeek === dayOfWeek,
        );

        return dailyData.length > 0 ? dailyData : defaultData;
      }

      return defaultData;
    }

    return defaultData;
  }, [
    dayOfWeek,
    defaultData,
    historyData,
    historyData?.f_history_desks_occupancy_hourly,
    historyData?.f_history_desks_occupancy_weekly,
    meetingRoomOccupancy,
  ]);

  const meetingData = useMemo(() => {
    if (meetingRoomOccupancy) {
      if (
        dayOfWeek === null &&
        historyData?.f_history_rooms_occupancy_weekly &&
        historyData.f_history_rooms_occupancy_weekly.length > 0
      ) {
        return historyData.f_history_rooms_occupancy_weekly;
      }

      if (
        dayOfWeek !== null &&
        historyData?.f_history_rooms_occupancy_hourly &&
        historyData.f_history_rooms_occupancy_hourly.length > 0
      ) {
        const dailyData = historyData.f_history_rooms_occupancy_hourly.filter(
          (d) => d.DayOfWeek === dayOfWeek,
        );

        return dailyData.length > 0 ? dailyData : defaultMeetingData;
      }
      return defaultMeetingData;
    }

    return defaultMeetingData;
  }, [
    dayOfWeek,
    defaultMeetingData,
    historyData,
    historyData?.f_history_rooms_occupancy_hourly,
    historyData?.f_history_rooms_occupancy_weekly,
    meetingRoomOccupancy,
  ]);

  return (
    <>
      <LoadingSpinner loading={loading} />
      <div
        className="relative"
        data-test-id={`occupancy-chart-${meetingRoomOccupancy}`}
      >
        <XYChart
          margin={margin}
          theme={xyTheme}
          height={height}
          xScale={{ type: 'band', paddingInner: 0.2 }}
          yScale={{ type: 'linear', domain: [0, 100] }}
        >
          <Grid
            rows
            numTicks={numTicks}
            strokeDasharray="1,3"
            stroke={getColor('NEUTRAL600')}
            columns={false}
          />
          {meetingRoomOccupancy ? (
            <AnimatedBarStack>
              <AnimatedBarSeries
                radius={4}
                radiusTop
                dataKey="MaxPercentageUsedMeetingRooms"
                data={meetingData}
                xAccessor={(d) => d.Hour}
                yAccessor={(d) => d.MaxPercentageUsedMeetingRooms}
              />
            </AnimatedBarStack>
          ) : (
            <AnimatedBarStack>
              <AnimatedBarSeries
                radius={4}
                radiusTop
                dataKey="PercentageMaxMinutes"
                data={data}
                xAccessor={(d) => d.Hour}
                yAccessor={(d) => d.PercentageMaxMinutes}
              />
            </AnimatedBarStack>
          )}
          <AnimatedBarStack>
            {meetingRoomOccupancy ? (
              <AnimatedBarSeries
                radius={4}
                radiusTop
                dataKey="PercentageUsedMeetingRooms"
                data={meetingData}
                xAccessor={(d) => d.Hour}
                yAccessor={(d) => d.PercentageUsedMeetingRooms}
              />
            ) : (
              <>
                <AnimatedBarSeries
                  dataKey="PercentageHotMinutes"
                  data={data}
                  xAccessor={(d) => d.Hour}
                  yAccessor={(d) => d.PercentageHotMinutes}
                />
                <AnimatedBarSeries
                  radius={4}
                  radiusTop
                  dataKey="PercentageWarmMinutes"
                  data={data}
                  xAccessor={(d) => d.Hour}
                  yAccessor={(d) => d.PercentageWarmMinutes}
                />
              </>
            )}
          </AnimatedBarStack>
          {!meetingRoomOccupancy && (
            <AnimatedLineSeries
              dataKey="PercentageMaxOccupancy"
              data={data}
              xAccessor={(d) => d.Hour}
              yAccessor={(d) => d.PercentageMaxOccupancy}
              curve={curveBasis}
            />
          )}
          <Axis
            orientation="left"
            tickFormat={(d) => `${d}%`}
            numTicks={numTicks}
            label={
              meetingRoomOccupancy
                ? intl.formatMessage(
                    {
                      id: 'Meeting rooms usage in percent',
                    },
                    {
                      meetingRooms: meetingData[0]?.TotalMeetingRooms ?? 0,
                    },
                  )
                : intl.formatMessage(
                    {
                      id: 'Desks usage in percent',
                    },
                    {
                      desks: data[0]?.NumberOfDesks ?? 0,
                    },
                  )
            }
          />
          <Axis
            orientation="bottom"
            numTicks={12}
            label={intl.formatMessage({
              id: 'Hour of the day',
            })}
          />
          <XYTooltip<(typeof data)[number]>
            showVerticalCrosshair
            renderTooltip={({ tooltipData, colorScale }) => (
              <div
                className="dark:text-neutral-200"
                data-test-id={`occupancy-chart-tooltip-${meetingRoomOccupancy}`}
              >
                {`${tooltipData?.nearestDatum?.datum.Hour}:00 - ${
                  (tooltipData?.nearestDatum?.datum.Hour ?? 0) + 1 === 24
                    ? '0'
                    : (tooltipData?.nearestDatum?.datum.Hour ?? 0) + 1
                }:00`}
                {Object.keys(tooltipData?.datumByKey ?? {}).map((state) => {
                  const entry = meetingRoomOccupancy
                    ? meetingData.filter(
                        (b) => b.Hour === tooltipData?.nearestDatum?.datum.Hour,
                      )
                    : data.filter(
                        (b) => b.Hour === tooltipData?.nearestDatum?.datum.Hour,
                      );

                  const occupancy =
                    tooltipData?.nearestDatum?.datum &&
                    entry.length > 0 &&
                    // @ts-expect-error Not sure how to fix
                    entry[0][state];

                  return (
                    <div key={state}>
                      <span
                        style={{
                          color: colorScale?.(state),
                          textDecoration:
                            tooltipData?.nearestDatum?.key === state
                              ? 'underline'
                              : undefined,
                        }}
                      >
                        <FormattedMessage id={state as IntlMessageKeys} />
                      </span>{' '}
                      {Number.isNaN(occupancy) ? '–' : `${occupancy}%`}
                    </div>
                  );
                })}
              </div>
            )}
          />
        </XYChart>
        <div className="absolute w-full flex flex-wrap justify-center top-16 text-xs">
          <Legend
            scaleType="ordinal"
            labelFormat={(d) =>
              intl.formatMessage({
                id: d,
              })
            }
            scale={scaleOrdinal({
              domain: meetingRoomOccupancy
                ? [
                    'PercentageUsedMeetingRooms',
                    'MaxPercentageUsedMeetingRooms',
                  ]
                : [
                    'PercentageHotMinutes',
                    'PercentageWarmMinutes',
                    'PercentageMaxMinutes',
                    'PercentageMaxOccupancy',
                  ],
              range: meetingRoomOccupancy
                ? [
                    getColor('RED'),
                    theme.color === Themes.LIGHT
                      ? getColor('NEUTRAL200')
                      : getColor('NEUTRAL600'),
                  ]
                : [
                    getColor('RED'),
                    getColor('YELLOW'),
                    theme.color === Themes.LIGHT
                      ? getColor('NEUTRAL200')
                      : getColor('NEUTRAL600'),
                    theme.color === Themes.LIGHT
                      ? getColor('NEUTRAL600')
                      : getColor('NEUTRAL300'),
                  ],
            })}
          />
        </div>
        <div className="absolute top-6 w-full flex justify-center px-2">
          <DaySelect dayOfWeek={dayOfWeek} setDayOfWeek={setDayOfWeek} />
        </div>
      </div>
    </>
  );
}

export default function ResponsiveBarOccupancy(
  props: ResponsiveBarOccupancyProps,
) {
  return (
    <ParentSize>
      {({ height }) => <BarOccupancy {...props} height={height} />}
    </ParentSize>
  );
}
