import { type MarginProps, Themes } from '@/common/types';
import AnimatedPie from '@/generic/components/Chart/AnimatedPie';
import type { LiveOccupancyQuery } from '@/graphql/types';
import useStore from '@/model/store';
import getColor from '@/utils/getColor';
import type { Point } from '@visx/brush/lib/types';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleOrdinal } from '@visx/scale';
import Pie from '@visx/shape/lib/shapes/Pie';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';
import { useMemo } from 'react';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';

interface ResponsiveLiveChartProps {
  margin?: MarginProps;
  liveData?: LiveOccupancyQuery;
}

interface LiveChartProps extends ResponsiveLiveChartProps {
  width: number;
  height: number;
}

interface DatumData {
  id: IntlMessageKeys;
  value: number;
  places: number;
}

interface TooltipDatumProps {
  data: DatumData;
}

function LiveChart({
  width,
  height,
  margin = { top: 10, left: 0, right: 0, bottom: 10 },
  liveData,
}: LiveChartProps) {
  const theme = useStore((state) => state.userSettings.theme);
  const intl = useIntl();

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<TooltipDatumProps>();

  const handleMouseMove = (coords: Point | null, datum: TooltipDatumProps) => {
    showTooltip({
      tooltipLeft: coords?.x,
      tooltipTop: coords?.y,
      tooltipData: datum,
    });
  };

  const data = useMemo(
    () =>
      ((liveData?.f_live_desks_occupancy.length ?? 0) > 0 &&
        liveData?.f_live_desks_occupancy) ||
      null,
    [liveData],
  );

  const formattedData: DatumData[] = useMemo(
    () => [
      {
        id: 'used',
        value: data?.[0]?.HotPercentage ?? 0,
        places: data?.[0]?.HotCount ?? 0,
      },
      {
        id: 'warm',
        value: data?.[0]?.WarmPercentage ?? 0,
        places: data?.[0]?.WarmCount ?? 0,
      },
      {
        id: 'free',
        value: data?.[0]?.ColdPercentage ?? 0,
        places: data?.[0]?.ColdCount ?? 0,
      },
      {
        id: 'offline',
        value: data?.[0]?.OfflinePercentage ?? 100,
        places: data?.[0]?.OfflineCount ?? 0,
      },
    ],
    [
      data?.[0]?.OfflinePercentage,
      data?.[0]?.ColdCount,
      data?.[0]?.HotCount,
      data?.[0]?.ColdPercentage,
      data?.[0]?.HotPercentage,
      data?.[0]?.OfflineCount,
      data?.[0]?.WarmCount,
      data?.[0]?.WarmPercentage,
    ],
  );

  const getUsageColor = scaleOrdinal({
    domain: ['warm', 'used', 'free', 'offline'],
    range: [
      getColor('YELLOW'),
      getColor('RED'),
      getColor('GREEN'),
      getColor('NEUTRAL200'),
    ],
  });

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 40;

  return (
    <div className="relative flex flex-col">
      <div className="flex items-center space-x-4 flex-col lg:flex-row">
        <div className="w-full lg:w-1/3">
          <svg width={width} height={height}>
            <Group top={centerY + margin.top} left={centerX + margin.left}>
              <Pie
                data={formattedData}
                pieValue={(d) => d.value}
                outerRadius={radius}
                innerRadius={radius - donutThickness}
                cornerRadius={3}
                pieSort={() => 1}
                padAngle={0.005}
              >
                {(pie) => (
                  <AnimatedPie<DatumData>
                    pie={pie}
                    onMouseMove={handleMouseMove}
                    onMouseOut={hideTooltip}
                    label={false}
                    getKey={(arc) =>
                      `${intl.formatMessage({
                        id: arc.data.id,
                      })} ${arc.data.value}% (${arc.data.places})`
                    }
                    getColor={(arc) => getUsageColor(arc.data.id)}
                  />
                )}
              </Pie>
            </Group>
          </svg>
          <div className="absolute text-2xl items-center text-neutral-900 dark:text-neutral-100 font-extrabold tracking-tight uppercase flex flex-col top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="text-green-600">
              {data?.[0]?.ColdCount} <FormattedMessage id="free" />
            </div>
            <div className="text-lg">
              {data?.[0] &&
                data[0].ColdCount +
                  data[0].WarmCount +
                  data[0].HotCount +
                  data[0].OfflineCount}{' '}
              <FormattedMessage id="Total" />
            </div>
          </div>
        </div>
      </div>
      {tooltipOpen && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            background:
              theme.color === Themes.LIGHT
                ? getColor('WHITE')
                : getColor('NEUTRAL900'),
          }}
        >
          <div className="dark:text-neutral-200">
            <FormattedMessage id={tooltipData?.data.id} />{' '}
            <strong>
              {tooltipData?.data.value}% ({tooltipData?.data.places}{' '}
              {tooltipData?.data.places === 1 ? (
                <FormattedMessage id="Desk" />
              ) : (
                <FormattedMessage id="Desks" />
              )}
              )
            </strong>
          </div>
        </TooltipWithBounds>
      )}
    </div>
  );
}

export default function ResponsiveLiveChart(props: ResponsiveLiveChartProps) {
  return (
    <ParentSize>
      {({ width, height }) => (
        <LiveChart {...props} width={width} height={height} />
      )}
    </ParentSize>
  );
}
