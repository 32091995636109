import { useIntl } from 'translations/Intl';

import Card from '@/generic/components/Card';
import Select from '@/generic/components/Form/Select';
import Subtitle from '@/generic/components/Subtitle';
import useStore from '@/model/store';

import { Languages } from 'common/types';
import BackgroundSwitch from './components/BackgroundSwitch';
import ColorSwitch from './components/ColorSwitch';
import ModeSwitch from './components/ModeSwitch';

export default function UserView() {
  const intl = useIntl();
  const user = useStore((state) => state.user);
  const language = useStore((state) => state.userSettings.language);
  const setLanguage = useStore((state) => state.userApi.setLanguage);

  const languages = [
    { id: Languages.DE, value: intl.formatMessage({ id: 'German' }) },
    { id: Languages.EN, value: intl.formatMessage({ id: 'English' }) },
  ];

  return (
    <Card>
      <div className="flex flex-col space-y-4">
        <div>
          {user?.email && (
            <>
              <Subtitle
                value={intl.formatMessage({
                  id: 'Email',
                })}
              />
              <div>{user.email}</div>
            </>
          )}
        </div>
        <div className="w-36">
          <Subtitle
            value={intl.formatMessage({
              id: 'Language',
            })}
          />
          <Select
            value={languages.find((l) => l.id === language)}
            onChangeSelected={(selected) =>
              selected && setLanguage(selected.id)
            }
            options={languages}
            isDeselectable={false}
            renderValue={(lng) => lng?.value ?? ''}
            keyParameter="id"
          />
        </div>
        <div>
          <Subtitle
            value={intl.formatMessage({
              id: 'Theme colors',
            })}
          />
          <ColorSwitch />
        </div>
        <div>
          <Subtitle
            value={intl.formatMessage({
              id: 'Mode',
            })}
          />
          <ModeSwitch />
        </div>
        <div>
          <Subtitle
            value={intl.formatMessage({
              id: 'Background',
            })}
          />
          <BackgroundSwitch />
        </div>
      </div>
    </Card>
  );
}
