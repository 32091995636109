import type { FloorReservationsQuery } from 'graphql/types';

import useStore from '@/model/store';
import getColor from '@/utils/getColor';
import renderOfflineIcon from '@/utils/renderOfflineIcon';
import renderReservedIcon from '@/utils/renderReservedIcon';
import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import GeoJSON, { type GeoJSONFeature } from 'ol/format/GeoJSON';
import type Geometry from 'ol/geom/Geometry';
import type Polygon from 'ol/geom/Polygon';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

import type { LayerOptions } from './Layer';
import type TypedFeature from './TypedFeature';
import VectorLayer from './VectorLayer';

export type DeskReservationFeature = {
  Desk:
    | NonNullable<FloorReservationsQuery['Desks']>[number]
    | NonNullable<FloorReservationsQuery['DeskReservations']>[number]['Desk'];
  Duration?: NonNullable<
    FloorReservationsQuery['DeskReservations']
  >[number]['Duration'];
  Reserved: boolean;
  Offline: boolean;
  IsPrivate: boolean;
} & Omit<
  NonNullable<FloorReservationsQuery['DeskReservations']>[number],
  'Duration'
>;

export type DeskReservationFeatureType = TypedFeature<
  DeskReservationFeature,
  Geometry
>;

const styleFunction = (
  feature: DeskReservationFeatureType,
  resolution: number,
  hoveredFeature: DeskReservationFeatureType | undefined,
  isMobile: boolean,
  map?: OLMap,
) => {
  const isHovered = feature.getGeometry() === hoveredFeature?.getGeometry();
  const {
    Reserved: reserved,
    Offline: offline,
    IsPrivate: isPrivate,
    Desk: desk,
  } = feature.getProperties();
  const isFavorite = desk.Name === useStore.getState().selectedFeature;

  const getStrokeWidth = () => {
    if (isMobile && isHovered) return 3;
    if (isHovered) return 2;
    return 1;
  };

  const getDeskTransparency = () => {
    if (isMobile && isHovered) return '.9';
    if (isHovered) return '.7';
    return '.5';
  };

  const getDeskColor = () => {
    if (offline) return 'NEUTRAL400';
    if (isPrivate) return 'NEUTRAL600';
    if (isFavorite) return 'YELLOW';
    if (reserved) return 'RED';
    return 'GREEN';
  };

  const extraStyle = !reserved
    ? renderOfflineIcon(feature, offline, map)
    : renderReservedIcon(feature, reserved, map);

  return [
    new Style({
      image: new Circle({
        radius: feature.getProperties().Desk.Radius / resolution,
        fill: new Fill({
          color: getColor(getDeskColor(), getDeskTransparency()),
        }),
        stroke: new Stroke({
          color: getColor(
            getDeskColor(),
            isHovered && !offline && !isPrivate ? '1' : '.5',
          ),
          width: getStrokeWidth(),
          lineDash: isMobile && isHovered ? [7, 10] : undefined,
        }),
      }),
    }),
    extraStyle,
  ];
};

interface ClimateRoomLayerOptions extends LayerOptions {
  olLayer: OLVectorLayer<VectorSource<Feature<Polygon>>>;
}

export default class DeskReservationLayer extends VectorLayer<Polygon> {
  features?: DeskReservationFeature[];

  hoveredFeature?: DeskReservationFeatureType;

  isMobile: boolean;

  init(map: OLMap): void {
    super.init(map);
    this.map = map;
  }

  constructor() {
    super({
      ...{
        olLayer: new OLVectorLayer({
          source: new VectorSource(),
          style: (feature, resolution) =>
            styleFunction(
              feature as DeskReservationFeatureType,
              resolution,
              this.hoveredFeature,
              this.isMobile,
              this.map,
            ),
        }),
      },
    } as ClimateRoomLayerOptions);

    this.hoveredFeature = undefined;
    this.isMobile = false;
  }

  setFeatures(feats: DeskReservationFeature[]): void {
    this.features = feats;
    const source = this.olLayer.getSource() as VectorSource<Feature<Polygon>>;
    const newFeatures = new GeoJSON().readFeatures(
      DeskReservationLayer.toGeoJson(this.features),
    );
    source.clear();
    source.addFeatures(newFeatures as Feature<Polygon>[]);
  }

  static toGeoJson(feats?: DeskReservationFeature[]): GeoJSONFeature {
    const features = feats?.map((feat) => {
      const { Desk: geo } = feat;
      return {
        type: 'Feature',
        geometry: geo.Geometry,
        properties: {
          ...feat,
        },
      };
    });
    return {
      type: 'FeatureCollection',
      features,
    };
  }
}
