import LoadingSpinner from '@/generic/components/LoadingSpinner';
import LoadingScreen from '@/generic/components/layout/LoadingScreen';
import { withFaroRouterInstrumentation } from '@grafana/faro-react';
import App from 'App';
import Health from 'pages/Health/Health';
import NotFoundView from 'pages/NotFoundView';
import { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';
import Authenticated from './pages/Authenticated';
import Error from './pages/Error';
import Home from './pages/Home';
import Layout from './pages/Layout';
import PrivateRoute from './pages/PrivateRoute';
import {
  ADMIN_ROUTES,
  BUBBLE_ROUTES,
  COMPONENT_ROUTES,
  SERVICES_ROUTES,
} from './routes';

const MQTT = lazy(() => import('pages/MQTT'));
const GraphQl = lazy(() => import('pages/GraphQl'));

export default withFaroRouterInstrumentation(
  createBrowserRouter([
    {
      path: '/',
      element: <App />,
      children: [
        {
          path: 'health',
          element: <Health />,
        },
        {
          path: '*',
          element: <Authenticated />,
          errorElement: <Error />,
          children: [
            {
              path: 'graphql',
              element: (
                <PrivateRoute roleRequired={HasuraPermissions.VIEW_GRAPHQL} />
              ),
              children: [
                {
                  index: true,
                  element: (
                    <Suspense fallback={<LoadingScreen />}>
                      <GraphQl />
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'component',
              children: COMPONENT_ROUTES.map(({ path, component }) => ({
                path,
                element: component,
              })),
            },
            {
              path: '*',
              element: <Layout />,
              children: [
                {
                  index: true,
                  element: <Home />,
                },
                {
                  path: 'mqtt',
                  element: (
                    <PrivateRoute roleRequired={HasuraPermissions.VIEW_MQTT} />
                  ),
                  children: [
                    {
                      index: true,
                      element: (
                        <Suspense fallback={<LoadingSpinner loading />}>
                          <MQTT />
                        </Suspense>
                      ),
                    },
                  ],
                },
                ...[...BUBBLE_ROUTES, ...SERVICES_ROUTES, ...ADMIN_ROUTES].map(
                  ({ path, userRole, component, subMenu }) => ({
                    path,
                    element: <PrivateRoute roleRequired={userRole} />,
                    children: [
                      {
                        path: '*',
                        element: component,
                        children: subMenu?.map((s) => ({
                          path: s.path,
                          element: <PrivateRoute roleRequired={s.userRole} />,
                          children: [
                            {
                              path: s.end ? undefined : '*',
                              element: s.component,
                              index: s.end,
                            },
                            {
                              path: '*',
                              element: <NotFoundView />,
                            },
                          ],
                        })),
                      },
                    ],
                  }),
                ),
                {
                  path: '*',
                  element: <NotFoundView />,
                },
              ],
            },
          ],
        },
      ],
    },
  ]),
);
