import Table from '@/generic/components/Table';
import type { OrganizationOverviewQuery } from '@/graphql/types';
import { useIntl } from '@/translations/Intl';
import type { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';

interface ReportSummaryProps {
  data: OrganizationOverviewQuery['ReportOverviewFloorData'];
}

export default function ReportSummary({ data }: ReportSummaryProps) {
  const intl = useIntl();

  const columns: ColumnDef<
    OrganizationOverviewQuery['ReportOverviewFloorData'][number]
  >[] = useMemo(
    () => [
      {
        id: 'deviceType',
        header: intl.formatMessage({ id: 'Device Type' }),
        accessorFn: (row) =>
          row.DeviceType ?? intl.formatMessage({ id: 'Unknown' }),
      },
      {
        id: 'numberOfDesks',
        header: intl.formatMessage({ id: 'PIR sensors' }),
        accessorFn: (row) => row.NumberOfAvailableDesks ?? '-',
      },
      {
        id: 'deskCount',
        header: intl.formatMessage({ id: 'Desks' }),
        accessorFn: (row) => row.DeskCount,
      },
      {
        id: 'beaconCount',
        header: intl.formatMessage({ id: 'Beacons' }),
        accessorFn: (row) => row.BeaconCount,
      },
    ],
    [intl.formatMessage],
  );

  return (
    <Table
      id={`report-${data[0]?.Building}-${data[0]?.Floor}-overview`}
      data={data}
      columns={columns}
      getRowId={(row) =>
        `row-${row.Building}-${row.Floor}-${row.DeviceType}-${row.NumberOfAvailableDesks}`
      }
      enabledFeatures={{
        enableGlobalFilter: false,
        enableColumnSelection: false,
        enablePagination: false,
        enableColumnFilter: false,
        enableCsvExport: false,
      }}
    />
  );
}
