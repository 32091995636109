import Tooltip from 'generic/components/Tooltip';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';

import Switch from '@/generic/components/Form/Switch';

export interface SwitchInterface {
  name: IntlMessageKeys;
  isEnabled: boolean;
  onSetEnable: (enabled: boolean) => void;
  renderIcon: ({ className }: { className: string }) => React.JSX.Element;
  info?: IntlMessageKeys;
}

interface LayerInfoTogglersProps {
  switches: SwitchInterface[];
}

export default function LayerInfoTogglers({
  switches,
}: LayerInfoTogglersProps): React.JSX.Element {
  return (
    <>
      {switches.map((s) => (
        <Switch
          labelPosition="left"
          labelClassName="justify-between"
          key={s.name}
          isEnabled={s.isEnabled}
          onSetEnable={s.onSetEnable}
          label={
            <div className="flex items-center">
              <div className="bg-primary-300 flex-shrink-0 flex items-center justify-center size-8 rounded-full">
                {s.renderIcon({ className: 'mx-auto size-6 text-primary-700' })}
              </div>
              <div className="ml-2">
                <FormattedMessage id={s.name} />
              </div>
              {s.info && (
                <div className="ml-2">
                  <Tooltip>
                    <FormattedMessage id={s.info} />
                  </Tooltip>
                </div>
              )}
            </div>
          }
        />
      ))}
    </>
  );
}
