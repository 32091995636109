import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider';

import getClimateStatus from '@/utils/getClimateStatus';
import type { SensorValue } from 'common/types';
import Handle from './components/Handle';
import SliderRail from './components/SliderRail';
import Tick from './components/Tick';
import Track from './components/Track';

export const INVALID_NUMBER = -10;

export default function ClimateSlider({
  range,
  handleChange,
  title,
  domain,
  unit,
}: {
  range: SensorValue;
  handleChange: (handles: readonly number[]) => void;
  title: string;
  domain: number[];
  unit: string;
}) {
  const hasGoodStart = range.good.start;
  const hasAcceptable = range.acceptable.start && range.acceptable.end;

  const getDefaultValues = () => {
    const goodStart = range.good.start?.value ?? INVALID_NUMBER;
    const goodEnd = range.good.end?.value ?? INVALID_NUMBER;
    const acceptableStart = range.acceptable.start?.value ?? INVALID_NUMBER;
    const acceptableEnd = range.acceptable.end?.value ?? INVALID_NUMBER;

    const poorStart = range.poor.start?.value ?? INVALID_NUMBER;

    if (!hasAcceptable) {
      // Humidity
      return [goodStart, poorStart];
    }
    if (hasGoodStart) {
      // Temperature
      return [acceptableStart, goodStart, goodEnd, acceptableEnd];
    }
    // Tvoc, Co2, Audio
    return [domain[0] ?? INVALID_NUMBER, acceptableStart, acceptableEnd];
  };

  const defaultValues = getDefaultValues().filter((v) => v !== INVALID_NUMBER);

  const getState = (value: number) => {
    const climateStatus = getClimateStatus(value, {
      good: {
        start: range.good.start?.value ?? undefined,
        end: range.good.end?.value ?? undefined,
      },
      acceptable: {
        start: range.acceptable.start?.value ?? undefined,
        end: range.acceptable.end?.value ?? undefined,
      },
      poor: {
        start: range.poor.start?.value ?? undefined,
        end: range.poor.end?.value ?? undefined,
      },
    });

    return climateStatus;
  };

  const onChange = (values: readonly number[]) => {
    const v = [...values];
    if (!hasGoodStart) {
      // If no start good is defined, prevent resetting lower limit
      v[0] = domain[0] ?? 0;
    }
    return handleChange(v);
  };

  return (
    <div className="col-span-1 h-12">
      <div className="font-bold pb-3">{title}</div>
      <Slider
        mode={2}
        step={1}
        domain={domain}
        className="relative w-full"
        onChange={onChange}
        onUpdate={onChange}
        values={defaultValues.slice()}
      >
        <Rail>{() => <SliderRail />}</Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div>
              {handles.map((handle) => (
                <Handle
                  unit={unit}
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                  hide={domain.includes(handle.value)}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks }) => {
            return (
              <div>
                {tracks.map(({ id, source, target }) => (
                  <Track
                    state={getState(source.value)}
                    key={id}
                    source={source}
                    target={target}
                  />
                ))}
              </div>
            );
          }}
        </Tracks>
        <Ticks count={5}>
          {({ ticks }) => (
            <div>
              {ticks.map((tick) => (
                <Tick
                  key={tick.id}
                  tick={tick}
                  count={ticks.length}
                  format={(d) => `${d} ${unit}`}
                />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </div>
  );
}
