import getColor from '@/utils/getColor';
import renderOfflineIcon from '@/utils/renderOfflineIcon';
import type { LiveCleaning } from 'graphql/types';
import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import GeoJSON, { type GeoJSONFeature } from 'ol/format/GeoJSON';
import type Geometry from 'ol/geom/Geometry';
import Polygon from 'ol/geom/Polygon';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import type { LayerOptions } from './Layer';
import type TypedFeature from './TypedFeature';
import VectorLayer from './VectorLayer';

interface ColorProps {
  toCleanColor: string;
  toCheckColor: string;
}

type CleaningFeature = Omit<LiveCleaning, 'Building' | 'Floor' | 'Id'> & {
  Id?: number;
};

export type CleaningFeatureType = TypedFeature<CleaningFeature, Geometry>;

const colorFunction = (
  feature: CleaningFeatureType,
  colors: ColorProps,
  timeBeforeCheck: number,
  timeBeforeClean: number,
  offline: boolean,
  opacity?: string,
) => {
  if (offline) {
    return getColor('NEUTRAL400', opacity);
  }
  if (feature.getProperties().HotMinutes >= timeBeforeClean) {
    const rgb = colors.toCleanColor;
    return `rgba(${rgb.substring(
      rgb.indexOf('(') + 1,
      rgb.indexOf(')'),
    )}, ${opacity})`;
  }
  if (feature.getProperties().HotMinutes >= timeBeforeCheck) {
    const rgb = colors.toCheckColor;
    return `rgba(${rgb.substring(
      rgb.indexOf('(') + 1,
      rgb.indexOf(')'),
    )}, ${opacity})`;
  }
  return getColor('NEUTRAL300', opacity);
};

const styleFunction = (
  feature: CleaningFeatureType,
  resolution: number,
  colors: ColorProps,
  timeBeforeCheck: number,
  timeBeforeClean: number,
  hoveredFeature: boolean,
  map?: OLMap,
) => {
  const offline = feature.getProperties().IsOffline;
  // Room style
  if (feature.getGeometry() instanceof Polygon) {
    return [
      new Style({
        stroke: new Stroke({
          color: colorFunction(
            feature,
            colors,
            timeBeforeCheck,
            timeBeforeClean,
            offline,
            hoveredFeature ? '.9' : '.8',
          ),
          width: 2,
        }),
        fill: new Fill({
          color: colorFunction(
            feature,
            colors,
            timeBeforeCheck,
            timeBeforeClean,
            offline,
            hoveredFeature ? '.9' : '.75',
          ),
        }),
      }),
      renderOfflineIcon(feature, offline, map),
    ];
  }
  // Desk style
  return [
    new Style({
      image: new Circle({
        radius: (feature.getProperties().Radius ?? 0) / resolution,
        fill: new Fill({
          color: colorFunction(
            feature,
            colors,
            timeBeforeCheck,
            timeBeforeClean,
            offline,
            hoveredFeature ? '.9' : '.75',
          ),
        }),
        stroke: new Stroke({
          color: colorFunction(
            feature,
            colors,
            timeBeforeCheck,
            timeBeforeClean,
            offline,
            hoveredFeature ? '.9' : '.8',
          ),
          width: 2,
        }),
      }),
    }),
    renderOfflineIcon(feature, offline, map),
  ];
};

interface CleaningLayerOptions extends LayerOptions {
  olLayer: OLVectorLayer<VectorSource<Feature<Polygon>>>;
}

class CleaningLayer extends VectorLayer<Polygon> {
  colors: ColorProps;

  features?: CleaningFeature[];

  hoveredFeature?: CleaningFeatureType;

  timeBeforeCheck?: number;

  timeBeforeClean?: number;

  init(map: OLMap): void {
    super.init(map);
    this.map = map;
  }

  constructor() {
    super({
      ...{
        olLayer: new OLVectorLayer({
          source: new VectorSource(),
          style: (feature, resolution) =>
            styleFunction(
              feature as CleaningFeatureType,
              resolution,
              this.colors,
              this.timeBeforeCheck || 0,
              this.timeBeforeClean || 0,
              feature.getGeometry() === this.hoveredFeature?.getGeometry(),
              this.map,
            ),
        }),
      },
      name: 'cleaningLayer',
    } as CleaningLayerOptions);
    this.colors = { toCheckColor: '', toCleanColor: '' };
    this.timeBeforeCheck = 0;
    this.timeBeforeClean = 0;
  }

  setFeatures(feats: CleaningFeature[]): void {
    this.features = feats;
    const source = this.olLayer.getSource() as VectorSource<Feature<Polygon>>;
    const newFeatures = new GeoJSON().readFeatures(
      CleaningLayer.toGeoJson(this.features),
    );
    source.clear();
    source.addFeatures(newFeatures as Feature<Polygon>[]);
  }

  setColors(newColors: ColorProps): void {
    this.colors = newColors;
  }

  static toGeoJson(feats?: CleaningFeature[]): GeoJSONFeature {
    const features = feats?.map((feat) => {
      const { Geometry: geom } = feat;
      return {
        type: 'Feature',
        geometry: geom,
        properties: {
          ...feat,
        },
      };
    });
    return {
      type: 'FeatureCollection',
      features,
    };
  }
}

export default CleaningLayer;
