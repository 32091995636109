import { MenuItem as HUIMenuItem } from '@headlessui/react';
import { NavLink, type To } from 'react-router-dom';

interface MenuItemProps {
  children: React.ReactNode;
  className?: string;
  to: To;
  dataTestId?: string;
}

export default function MenuItem({
  children,
  to,
  className = '',
  dataTestId,
}: MenuItemProps) {
  return (
    <HUIMenuItem>
      {({ focus }) => (
        <NavLink
          data-test-id={dataTestId}
          to={`../${to}`}
          className={({ isActive }) =>
            `${
              focus || isActive
                ? 'bg-neutral-100 text-neutral-900 dark:text-neutral-500'
                : 'text-neutral-700 dark:text-neutral-200'
            } font-normal text-sm px-4 py-2 cursor-pointer group flex text-left w-full ${className}`
          }
        >
          {children}
        </NavLink>
      )}
    </HUIMenuItem>
  );
}
