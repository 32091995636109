import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';

import { type MarginProps, Themes } from '@/common/types';
import { RAINBOW } from '@/constants';
import AnimatedPie from '@/generic/components/Chart/AnimatedPie';
import useStore from '@/model/store';
import getColor from '@/utils/getColor';
import type { Point } from '@visx/brush/lib/types';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleOrdinal } from '@visx/scale';
import Pie from '@visx/shape/lib/shapes/Pie';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';

interface ResponsivePieChartProps {
  margin?: MarginProps;
  data: Data[];
  tooltipEnabled?: boolean;
  colors?: string[];
  showLabel?: boolean;
  dataFormat?: (d: Data) => string;
}

interface Data {
  id: string;
  value: number;
}

interface PieChartProps extends ResponsivePieChartProps {
  height: number;
  width: number;
}

interface TooltipDatumProps {
  data: Data;
}

function PieChart({
  height,
  width,
  data,
  margin = { top: 0, left: 0, right: 0, bottom: 0 },
  tooltipEnabled = true,
  colors,
  showLabel = false,
  dataFormat = (d) => d.id,
}: PieChartProps) {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<TooltipDatumProps>();
  const theme = useStore((state) => state.userSettings.theme);

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;

  const handleMouseMove = (coords: Point | null, datum: TooltipDatumProps) => {
    showTooltip({
      tooltipLeft: coords?.x,
      tooltipTop: coords?.y,
      tooltipData: datum,
    });
  };

  const getUsageColor = scaleOrdinal({
    domain: data.map((d) => d.id),
    range: colors ?? RAINBOW,
  });

  return (
    <div className="relative">
      <svg width={width} height={height}>
        <Group top={centerY + margin.top} left={centerX + margin.left}>
          <Pie
            data={data}
            pieValue={(d) => d.value}
            // Adjust for padding
            outerRadius={radius - 0.5}
            cornerRadius={0}
            pieSort={() => 1}
            padAngle={0.005}
          >
            {(pie) => (
              <AnimatedPie<Data>
                pie={pie}
                onMouseMove={handleMouseMove}
                onMouseOut={hideTooltip}
                label={showLabel}
                getKey={(arc) => dataFormat(arc.data)}
                getColor={(arc) => getUsageColor(arc.data.id)}
              />
            )}
          </Pie>
        </Group>
      </svg>
      {tooltipOpen && tooltipEnabled && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            background:
              theme.color === Themes.LIGHT
                ? getColor('WHITE')
                : getColor('NEUTRAL900'),
          }}
        >
          <div className="dark:text-neutral-200">
            <FormattedMessage id={tooltipData?.data.id as IntlMessageKeys} />{' '}
            <strong>{tooltipData?.data.value}</strong>
          </div>
        </TooltipWithBounds>
      )}
    </div>
  );
}

export default function ResponsivePieChart(props: ResponsivePieChartProps) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <PieChart {...props} height={height} width={width} />
      )}
    </ParentSize>
  );
}
