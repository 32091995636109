import Card from '@/generic/components/Card';
import EmbeddedButton from '@/generic/components/EmbeddedButton';
import EmbeddedWrapper from '@/generic/components/EmbeddedWrapper';
import LivePing from '@/generic/components/LivePing';
import PrivateWrapper from '@/generic/components/PrivateWrapper';
import Subtitle from '@/generic/components/Subtitle';
import Loader from '@/generic/components/layout/BarLoader';
import { useBuildingsQuery } from '@/graphql/types';
import { HasuraPermissions } from '@/utils/graphql/useHasuraHeaders';
import { Fragment, useMemo } from 'react';

import TotalVisitors from '../TotalVisitors';
import BuildingOccupancy from './BuildingOccupancy';

export default function LiveOccupancy(): React.JSX.Element {
  const [{ data: buildingList, fetching: loading }] = useBuildingsQuery();

  const buildings = useMemo(
    () =>
      buildingList?.Buildings && buildingList.Buildings.length > 0
        ? buildingList.Buildings
        : [{ Id: 1, Name: '-' }],
    [buildingList],
  );

  return (
    <>
      {buildings.map((building) => (
        <Fragment key={`building-${building.Id}`}>
          <div
            className="flex flex-col w-full col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 space-y-2"
            data-test-id={building.Name}
          >
            <Card className="md:max-w-lg">
              <EmbeddedWrapper>
                <Loader loading={loading} />
              </EmbeddedWrapper>
              <div
                data-test-id={`occupancy-card-${building.Name}`}
                className="flex items-center justify-between group"
              >
                <div className="flex space-x-3">
                  <LivePing />
                  <Subtitle value={building.Name} />
                </div>
                <EmbeddedButton
                  urlParams={[['building', building.Name]]}
                  component="buildingoccupancy"
                />
              </div>
              <BuildingOccupancy building={building} />
            </Card>
            <PrivateWrapper
              roleRequired={HasuraPermissions.FEATURE_PEOPLECOUNT}
            >
              <Card className="flex flex-col items-center justify-center">
                <TotalVisitors buildingName={building.Name} />
              </Card>
            </PrivateWrapper>
          </div>
        </Fragment>
      ))}
    </>
  );
}
