import { DatePickerInput } from '@/generic/components/Form/DatePicker';
import Subtitle from '@/generic/components/Subtitle';
import useStore from '@/model/store';
import { type RefObject, useMemo } from 'react';
import { useIntl } from 'translations/Intl';

interface ReservationDateProps {
  reservationStartDate: Date;
  reservationEndDate: Date;
  onStartChange: (selectedDate: Date[]) => void;
  onEndChange: (selectedDate: Date[]) => void;
  flatpickrStartRef?: RefObject<null>;
  flatpickrEndRef?: RefObject<null>;
}

export default function ReservationDate({
  reservationStartDate,
  reservationEndDate,
  onStartChange,
  onEndChange,
  flatpickrStartRef,
  flatpickrEndRef,
}: ReservationDateProps) {
  const intl = useIntl();
  const reservationLimit = useStore(
    (state) => state.organizationSettings.reservationLimit,
  );

  const maxDate = useMemo(() => {
    if (reservationLimit) {
      const today = new Date();
      const limitForReservation = new Date();
      // ReservationLimit + 1 because maxDate in flatpickr is inclusive
      limitForReservation.setDate(today.getDate() + (reservationLimit + 1));
      return limitForReservation;
    }
    return undefined;
  }, [reservationLimit]);

  return (
    <div className="flex flex-col space-y-2">
      <Subtitle value={intl.formatMessage({ id: 'Reservation dates' })} />
      <div className="flex md:block flex-wrap space-x-2 md:space-x-0">
        <DatePickerInput
          label="Date from"
          options={{
            enableTime: true,
            defaultDate: [reservationStartDate],
            dateFormat: 'M j H:i',
            minDate: 'today',
            maxDate,
            onChange: (selectedDate) => onStartChange(selectedDate),
            flatpickrRef: flatpickrStartRef,
          }}
        />
        <DatePickerInput
          label="Date to"
          options={{
            enableTime: true,
            defaultDate: [reservationEndDate],
            dateFormat: 'M j H:i',
            minDate: 'today',
            maxDate,
            onChange: (selectedDate) => onEndChange(selectedDate),
            flatpickrRef: flatpickrEndRef,
          }}
        />
      </div>
    </div>
  );
}
