import { getBeaconId } from '@/pages/AdminView/BuildingView/components/FloorList/components/FloorRoomView/components/FloorRoomMap/components/SelectNewBeaconCard/components/EnrollDevices/components/TopicCell/TopicCell';
import format from '@/utils/format';
import Banner from 'generic/components/Banner/Banner';
import PrivateWrapper from 'generic/components/PrivateWrapper';
import Transition from 'generic/components/Transition/Transition';
import {
  useBeaconByNameQuery,
  useMaintenanceMessageSubscription,
  useOrganizationLicenseExpiredQuery,
} from 'graphql/types';
import useStore from 'model/store';
import { useEffect, useMemo, useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { FormattedMessage } from 'translations/Intl';
import { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';
import useMqttMessage, {
  Category,
  type Severity,
} from 'utils/mqtt/useMqttMessage';
import Header from '../../generic/components/layout/Header';

export default function Layout() {
  const [maintenanceBannerVisible, setMaintenanceBannerVisible] =
    useState(false);
  const [licenseBannerVisible, setLicenseBannerVisible] = useState(false);
  const [mqttPingBannerVisible, setMqttPingBannerVisible] = useState(false);

  const organization = useStore((state) => state.user)?.organization;

  // "+" means all beacons
  const { topic, timestamp, message } = useMqttMessage({ beaconId: '+' });

  const beacon = getBeaconId(topic ?? '');

  const [{ data: maintenanceMessages }] = useMaintenanceMessageSubscription({
    // Show the banner two days prior to the maintenance event
    variables: {
      Date: useMemo(() => new Date(), []),
    },
  });

  const [{ data: beaconInfo }] = useBeaconByNameQuery({
    variables: { BeaconName: beacon },
    pause: !beacon,
  });

  const floorNumber = beaconInfo?.MqttBeacons[0]?.Floor?.Number;
  const buildingName = beaconInfo?.MqttBeacons[0]?.Floor?.Building.Name;
  const roomNames = [
    ...new Set(
      ...(beaconInfo?.MqttBeacons[0]?.Sensors ?? [])
        .map((s) => s.RoomSensors.map((rs) => rs.Room.Name))
        .filter((rS) => rS.length), // filter empty relations
    ),
  ]
    .sort((a, b) => a.localeCompare(b))
    .join(',');

  const [{ data: licenseExpired }] = useOrganizationLicenseExpiredQuery();

  useEffect(() => {
    if (maintenanceMessages?.MaintenanceMessages.length) {
      setMaintenanceBannerVisible(true);
    }
  }, [maintenanceMessages?.MaintenanceMessages.length]);

  useEffect(() => {
    // Timestamp is only used for getting new ping messages with the same topic
    if (
      beaconInfo?.MqttBeacons.length &&
      timestamp &&
      // Do not look at OTA info messages (severity 6) as they only show download status
      (message?.category !== Category.OTA || (message?.severity ?? 0) < 4)
    ) {
      setMqttPingBannerVisible(true);

      setTimeout(() => {
        setMqttPingBannerVisible(false);
      }, 15_000);
    }
  }, [
    beaconInfo?.MqttBeacons.length,
    timestamp,
    message?.category,
    message?.severity,
  ]);

  useEffect(() => {
    // When an organization is expired, the "x-hasura-org-id" attribute will be prefixed with "expired-"
    if (organization?.includes('expired-')) {
      setLicenseBannerVisible(true);
    }

    if (typeof licenseExpired?.Organizations[0]?.LicenseExpired === 'boolean') {
      setLicenseBannerVisible(licenseExpired.Organizations[0].LicenseExpired);
    }
  }, [licenseExpired?.Organizations[0]?.LicenseExpired, organization]);

  return (
    <div className="min-h-screen pb-16 md:pb-0">
      <Transition show={maintenanceBannerVisible}>
        {maintenanceMessages?.MaintenanceMessages.map(
          ({ DurationLower: start, DurationUpper: end }) => (
            <Banner
              key={start}
              setOpen={setMaintenanceBannerVisible}
              className="print:hidden"
            >
              <FormattedMessage
                id="Active maintenance"
                values={{
                  from: format(new Date(start), 'PPp'),
                  to: format(new Date(end), 'PPp'),
                }}
              />
            </Banner>
          ),
        )}
      </Transition>
      <Transition show={licenseBannerVisible}>
        <Banner>
          <FormattedMessage id="License expired" />
        </Banner>
      </Transition>
      <PrivateWrapper roleRequired={HasuraPermissions.VIEW_ADMIN}>
        <Transition show={mqttPingBannerVisible}>
          <Banner severity={message?.severity ?? (0 as Severity)}>
            <NavLink
              to={`../status?beacon=${encodeURIComponent(beacon ?? '')}`}
              className={`text-white hover:text-primary-700 ${(message?.severity ?? 0) < 4 ? 'hover:text-red-300' : ''}`}
              onClick={() => setMqttPingBannerVisible(false)}
              data-test-id="identify-banner-button"
            >
              <div className="flex flex-col">
                <div>
                  <FormattedMessage
                    id="Beacon alert"
                    values={{ beacon, message: message?.payload.message }}
                  />
                </div>
                <div className="text-sm">
                  <Transition
                    show={
                      !!buildingName &&
                      typeof floorNumber === 'number' &&
                      !!roomNames
                    }
                  >
                    <FormattedMessage
                      id="Building Floor Room"
                      values={{
                        building: buildingName,
                        number: floorNumber,
                        room: roomNames,
                      }}
                    />
                  </Transition>
                  <Transition
                    show={
                      !!buildingName &&
                      typeof floorNumber === 'number' &&
                      !roomNames
                    }
                  >
                    <FormattedMessage
                      id="Building Floor"
                      values={{
                        building: buildingName,
                        number: floorNumber,
                      }}
                    />
                  </Transition>
                </div>
              </div>
            </NavLink>
          </Banner>
        </Transition>
      </PrivateWrapper>
      <main className="min-h-screen">
        <Header />
        <div className="py-4 md:py-6 lg:py-4 px-4 lg:px-8 print:!px-0 print:!py-0">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
