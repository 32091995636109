import type { Locales } from '@/common/types';
import { de, enGB } from 'date-fns/locale';

export const LOCALES: Locales = { enGB, de };
// LocalStorage keys
export const LS_DESK_ROTATION = 'mda-desk-rotation';
export const LS_DESK_RADIUS = 'mda-desk-radius';
export const LS_DESK_OFFSETS = 'mda-desk-offsets';
export const LS_SHOW_ONLY_READ_NOTIF = 'notif-only-read';
export const LS_HIDE_CANCELLED_RESERVATIONS = 'reservations-hide-cancelled';
export const LS_SHOW_ASSET_POSITION = 'show-asset-postion';
export const LS_SHOW_ROOM_OCCUPANCY = 'show-room-occupancy';
export const LS_SHOW_ROOM_BEACONS = 'show-room-beacons';
export const LS_SHOW_PEOPLE_FLOW = 'show-people-flow';
export const LS_SHOW_ROOM_SENSOR = 'show-room-sensor';
export const LS_SHOW_BEACON_LABEL = 'mda-show-beacon-label';
export const LS_SHOW_DESK_INDEX = 'mda-show-desk-index';
export const LS_SHOW_DESK_OUTLINES = 'mda-show-desk-outlines';
export const LS_SHOW_CLIMATE_SENSORS = 'mda-show-climate-sensors';
export const LS_SHOW_UPCOMING_RESERVATIONS = 'show-upcoming-reservations';
export const LS_SHOW_PREVIOUS_RESERVATIONS = 'show-previous-reservations';

export const COLOR_LIST = [
  'blue',
  'indigo',
  'red',
  'pink',
  'green',
  'gray',
  'yellow',
  'orange',
  'purple',
].sort((a, b) => a.localeCompare(b));

// Twin Colors (600 & 300) from https://tailwindcss.com/docs/customizing-colors
export const TWIN_COLORS = [
  '#65A30D', // lime - 600
  '#bef264', // lime - 300
  '#DC2626', // red - 600
  '#FCA5A5', // red - 300
  '#D97706', // amber - 600
  '#fcd34d', // amber - 300
  '#059669', // emerald - 600
  '#6ee7b7', // emerald - 300
  '#0891B2', // cyan - 600
  '#67e8f9', // cyan - 300
  '#2563EB', // blue - 600
  '#93c5fd', // blue - 300
  '#7C3AED', // violet - 600
  '#c4b5fd', // violet - 300
  '#C026D3', // fuchsia - 600
  '#f0abfc', // fuchsia - 300
];

// Colors (600) from https://tailwindcss.com/docs/customizing-colors
export const RAINBOW = [
  '#DC2626',
  '#D97706',
  '#65A30D',
  '#059669',
  '#0891B2',
  '#2563EB',
  '#7C3AED',
  '#C026D3',
  '#E11D48',
  '#EA580C',
  '#CA8A04',
  '#16A34A',
  '#0D9488',
  '#0284C7',
  '#4F46E5',
  '#9333EA',
  '#DB2777',
  // Colors (300)
  '#FCA5A5',
  '#FDBA74',
  '#FCD34D',
  '#FDE047',
  '#BEF264',
  '#86EFAC',
  '#6EE7B7',
  '#5EEAD4',
  '#67E8F9',
  '#7DD3FC',
  '#93C5FD',
  '#A5B4FC',
  '#C4B5FD',
];

export const BASE_LAYER_NAME = 'base-layer';
export const INTERSECTED_PARAM = 'intersected';
