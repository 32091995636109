import Badge from '@/generic/components/Badge';
import Tab from '@/generic/components/Tab';
import { useNumberOfCurrentUserReservationsQuery } from '@/graphql/types';
import { HasuraPermissions } from '@/utils/graphql/useHasuraHeaders';
import usePolling from '@/utils/graphql/usePolling';
import useRoomDeskFilter from '@/utils/graphql/useRoomDeskFilter';
import { differenceInSeconds } from 'date-fns';
import { useMemo, useState } from 'react';
import { HiBars4, HiMap, HiTableCells } from 'react-icons/hi2';

export default function FindMyPlaceView(): React.JSX.Element {
  const [now, setNow] = useState(new Date());

  const [{ data, fetching }, reexecuteQuery] =
    useNumberOfCurrentUserReservationsQuery({
      variables: {
        Date: now,
        ...useRoomDeskFilter(),
      },
    });

  usePolling(fetching, reexecuteQuery, 1000 * 60);

  // Thanks React for making me do these ugly hacks in order to update the date without triggering a loop
  if (differenceInSeconds(new Date(), now) > 0) {
    setNow(new Date());
  }

  const numberOfEvents = useMemo(
    () =>
      (data?.DeskReservations_aggregate?.aggregate?.count ?? 0) +
      (data?.RoomReservations_aggregate?.aggregate?.count ?? 0),
    [
      data?.DeskReservations_aggregate?.aggregate?.count,
      data?.RoomReservations_aggregate?.aggregate?.count,
    ],
  );

  return (
    <Tab
      tabs={[
        {
          path: '',
          end: true, // avoid to be always active for all routes
          userRole: HasuraPermissions.VIEW_FINDMYPLACE,
          name: 'Live Map',
          renderIcon: ({ className }) => <HiMap className={className} />,
        },
        {
          path: 'reservations/new',
          userRole: HasuraPermissions.VIEW_RESERVATION,
          name: 'New Reservation',
          renderIcon: ({ className }) => <HiTableCells className={className} />,
        },
        {
          path: 'reservations/list',
          userRole: HasuraPermissions.VIEW_RESERVATION,
          badge: (
            <Badge
              className="absolute bottom-8 left-0 md:relative md:bottom-0 md:left-0"
              messageLength={numberOfEvents}
            />
          ),
          name: 'Reservations',
          renderIcon: ({ className }) => <HiBars4 className={className} />,
        },
      ]}
    />
  );
}
