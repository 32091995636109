import Card from '@/generic/components/Card';
import { HiOutlineLightBulb } from 'react-icons/hi2';

export default function NotFoundView(): React.JSX.Element {
  return (
    <div className="relative">
      <Card>
        <div className="text-lg pb-4">404: Page not found</div>
        <HiOutlineLightBulb className="size-6" />
        <div className="pt-4">Sorry, we can&apos;t find this page.</div>
      </Card>
    </div>
  );
}
