import { formattedDistance } from '@/utils/date';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'translations/Intl';

interface LastPolledProps {
  lastPoll: Date;
  small?: boolean;
}

export default function LastPolled({
  lastPoll,
  small = false,
}: LastPolledProps): React.JSX.Element {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div
      className={`text-neutral-700 ${
        small ? 'text-xxs' : 'text-xs'
      } dark:text-neutral-200`}
      data-test-id="last-updated"
    >
      <FormattedMessage id="Last polled" />{' '}
      {formattedDistance(lastPoll, { includeSeconds: true }, now)}
    </div>
  );
}
