import Checkbox from 'generic/components/Form/Checkbox';
import { type IntlMessageKeys, useIntl } from 'translations/Intl';

import type { ClientRoleInfos } from '@/graphql/types';

type UserRole = ClientRoleInfos;

interface RoleCheckboxProps {
  role: UserRole;
  groupRoles: UserRole[];
  handleOnChange: (roles: UserRole[]) => void;
}

export default function RoleCheckbox({
  role,
  groupRoles,
  handleOnChange,
}: RoleCheckboxProps): React.JSX.Element {
  const intl = useIntl();
  const roleKey = `${role.name}-description` as IntlMessageKeys;

  return (
    <div key={role.id} className="col-span-1">
      <Checkbox
        data-test-id="group-roles-checkboxes"
        checked={groupRoles.filter((r) => r.name === role.name).length > 0}
        setChecked={() => handleOnChange([role])}
        tooltip={intl.messages[roleKey] && intl.formatMessage({ id: roleKey })}
        label={intl.formatMessage({
          id: role.name as IntlMessageKeys,
        })}
      />
    </div>
  );
}
