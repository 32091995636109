import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import type { Coordinate } from 'ol/coordinate';
import { getCenter } from 'ol/extent';
import type Geometry from 'ol/geom/Geometry';
import type RenderFeature from 'ol/render/Feature';
import { useEffect, useState } from 'react';

interface PopupProps {
  map: OLMap;
  feature?: RenderFeature | Feature<Geometry>;
  popupCoordinate?: Coordinate | null;
  offset?: number[];
  renderTooltip?: (props: {
    top: number;
    left: number;
    className: string;
    'data-test-id': string;
  }) => React.JSX.Element | undefined;
}

export default function Popup({
  offset = [0, 0],
  map,
  feature,
  popupCoordinate,
  renderTooltip,
}: PopupProps) {
  const [top, setTop] = useState<number | undefined>(undefined);
  const [left, setLeft] = useState<number | undefined>(undefined);

  useEffect(() => {
    let coord = popupCoordinate;

    if (feature && !coord) {
      const extent = feature.getGeometry()?.getExtent();
      if (extent) {
        coord = getCenter(extent);
      }
    }

    if (coord) {
      const [x, y] = map.getPixelFromCoordinate(coord);
      if (
        typeof x === 'number' &&
        typeof y === 'number' &&
        offset[0] !== undefined &&
        offset[1] !== undefined
      ) {
        setLeft(x - offset[0]);
        setTop(y - offset[1]);
      }
    }
  }, [feature, map, offset?.[0], offset?.[1], popupCoordinate]);

  if (top && left && renderTooltip) {
    return renderTooltip({
      top,
      left,
      'data-test-id': 'popup',
      className:
        'absolute z-20 backdrop-blur bg-opacity-90 bg-white dark:!bg-neutral-800 p-1 shadow rounded border border-neutral-300 dark:border-neutral-700 break-words dark:!text-white text-neutral-700 max-w-[250px]',
    });
  }
  return null;
}
