import Accordion from 'generic/components/Accordion/Accordion';
import CloseButton from 'generic/components/Form/Button/CloseButton';
import { useIntl } from 'translations/Intl';

import { Action } from '@/common/types';
import Modal from '@/generic/components/Modal';
import {
  type OrganizationFirmwaresQuery,
  useModuleFirmwareQuery,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import { useMemo } from 'react';
import { compare, valid } from 'semver';

export type Firmwares = OrganizationFirmwaresQuery['FirmwarePackages'][number];

interface DownloadFirmwareModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function DownloadFirmwareModal({
  open,
  setOpen,
}: DownloadFirmwareModalProps) {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();

  const [{ data: moduleFirmares }] = useModuleFirmwareQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.READ_ALL, ['Modules']),
      [hasuraHeader],
    ),
  });

  return (
    <Modal
      action={Action.UPDATE}
      title={intl.formatMessage({
        id: 'Download module firmware',
      })}
      open={open}
      wrapperClassName="sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg"
      setShowModal={setOpen}
      footer={<CloseButton onClick={() => setOpen(false)} />}
    >
      {moduleFirmares?.Modules.map((module) => (
        <Accordion key={module.Name} title={module.Name} initialStateOpen>
          <div>
            {module.Firmwares.sort((a, b) =>
              valid(a.Version) && valid(b.Version)
                ? compare(a.Version, b.Version)
                : a.Version.localeCompare(b.Version),
            ).map(
              (f) =>
                f.Path && (
                  <div key={f.Version}>
                    <a href={f.Path} download>
                      {f.Version}
                    </a>
                  </div>
                ),
            )}
          </div>
        </Accordion>
      ))}
    </Modal>
  );
}
