import hiWarning from '@/img/heroicons_warning_outline.svg';
import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import { getCenter } from 'ol/extent';
import type Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import type RenderFeature from 'ol/render/Feature';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';

const getScale = (map?: OLMap) => {
  const scale = map ? Math.abs(map.getView().getZoom() ?? 1) : 1;
  return scale * 0.4;
};

const renderOfflineIcon = (
  feature: Feature<Geometry> | RenderFeature,
  isOffline: boolean,
  map?: OLMap,
): Style => {
  let extraStyle = new Style();
  if (isOffline) {
    const extent = feature.getGeometry()?.getExtent();
    if (extent) {
      const position = new Point(getCenter(extent));
      extraStyle = new Style({
        geometry: position,
        image: new Icon({
          src: hiWarning,
          scale: getScale(map),
          anchor: [0.5, 12.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
        }),
      });
    }
  }
  return extraStyle;
};

export default renderOfflineIcon;
