import useStore from '@/model/store';
import { useMemo } from 'react';
import useHasuraHeader, { HasuraPermissions } from './useHasuraHeaders';

export default function useReportFilter(additionalTypenames?: string[]) {
  const hasuraHeader = useHasuraHeader();
  const building = useStore((state) => state.userSettings.building);
  const reportDate = useStore((state) => state.userSettings.reportDate);

  return {
    // biome-ignore lint/correctness/useExhaustiveDependencies: Additional typenames should be stable
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_REPORTS, additionalTypenames),
      [hasuraHeader],
    ),
    variables: {
      Period: reportDate ?? '',
      Building: building?.Name ?? '',
    },
    pause: !reportDate || !building,
  };
}
